import { useGoogleLogin } from "@react-oauth/google";
import { Suspense, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchSignUpData } from "./../../data/sign-up/signUpDataSlice";
import { loginWithGoogle, register } from "../../data/sign-up/signUpSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { jwtDecode } from "jwt-decode";
import { gapi } from "gapi-script";
import GoogleLogin from "react-google-login";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
const clientId =
  "263736580748-di1finbitm46nff8hrbekfgbudb7iul8.apps.googleusercontent.com"; // Replace with your actual Client ID
function SignUp() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);

  const accessToken = useSelector((store) => store.register.token);
  const message = useSelector((store) => store.register.message);
  const loading = useSelector((store) => store.register.loading);
  const { signUpData } = useSelector((store) => store.signUpData);
  const [customerNationalityCode, setCustomerNationalityCode] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [repasswordType, setRepasswordType] = useState("password");
  const [rePass, setRePass] = useState("");
  useEffect(() => {
    dispatch(fetchSignUpData(language));
  }, [dispatch, language]);

  const onSuccess = async (response) => {
    const idToken = response.getAuthResponse().id_token;

    dispatch(loginWithGoogle(idToken));
    localStorage.setItem("firstName", response.wt.rV);
    localStorage.setItem("lastName", response.wt.uT);
    localStorage.setItem("email", response.wt.cu);
    localStorage.setItem("userImage", response.wt.hK);
    navigate("/");
  };

  const onFailure = (error) => {
    console.error("Login Failed:", error);
  };

  function submitRegister(values) {
    dispatch(register(values)).then((response) => {
      // Check if registration was successful and handle accordingly
      if (response.payload.accessToken) {
        // Store user token locally
        localStorage.setItem("accessToken", response.payload.accessToken);
        // Optionally store first name and last name if needed
        localStorage.setItem("firstName", values.firstName);
        localStorage.setItem("lastName", values.lastName);
        localStorage.setItem("email", values.email);
        // Redirect to home page
        navigate("/");
      }
    });
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(3, t("Name must be at least 3 characters"))
      .required(t("First Name is required")),
    lastName: Yup.string()
      .min(3, t("Name must be at least 3 characters"))
      .required(t("Last Name is required")),
    email: Yup.string()
      .email(t("Invalid email address"))
      .required(t("Email is required")),
    password: Yup.string()
      .min(8, t("Password must be at least 8 characters"))
      .required(t("Password is required")),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      nationalityId: "",
      countryId: "",
      customerMobile: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitRegister,
  });

  return (
    <>
      <Helmet>
        <title>{signUpData.pageDetails?.pageMetatagTitle}</title>
        <link rel="canonical" href={`https://travco.com/${language}/sign-up`} />
        <meta
          name="description"
          content={signUpData.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={signUpData.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={signUpData.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={signUpData.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={signUpData.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner mb-0"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      className="rlr-banner-splide__banner-img"
                      src={
                        isMobile
                          ? signUpData.pageDetails?.pageBannerMobile
                          : isTablet
                          ? signUpData.pageDetails?.pageBannerTablet
                          : signUpData.pageDetails?.pageBannerPc
                      }
                      alt={signUpData.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {signUpData.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <main id="rlr-main" className="rlr-main--fixed-top py-5 my-5">
        <div
          className="rlr-section__content--lg-top mt-0"
          style={{ marginTop: "0px !important" }}
        >
          <section
            className="rlr-section rlr-section__mt rlr-account mt-0"
            style={{ marginTop: "0px !important" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <div className="rlr-authforms">
                    <div className="rlr-authforms__header mb-5">
                      <h2>{t("Create an account")} </h2>
                      <p>{t("Helps to access all features of the site")}. </p>
                    </div>
                    <form onSubmit={formik.handleSubmit} id="my-form">
                      <div className="rlr-authforms__form row">
                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label className="rlr-form-label rlr-form-label--light required">
                            {t("First Name")}
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            autoComplete="off"
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            className="form-control form-control--light"
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <p className="text-danger">
                              {formik.errors.firstName}
                            </p>
                          ) : null}
                        </div>
                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label className="rlr-form-label rlr-form-label--light required">
                            {t("Last Name")}
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            autoComplete="off"
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            className="form-control form-control--light"
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <p className="text-danger">
                              {formik.errors.lastName}
                            </p>
                          ) : null}
                        </div>
                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label className="rlr-form-label rlr-form-label--light required">
                            {t("Email")}
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="off"
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            className="form-control form-control--light"
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <p className="text-danger">{formik.errors.email}</p>
                          ) : null}
                        </div>
                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label
                            htmlFor="customerMobile"
                            className="rlr-form-label rlr-form-label--light required"
                          >
                            {t("Mobile")}
                          </label>
                          <input
                            type="text"
                            name="customerMobile"
                            id="customerMobile"
                            autoComplete="off"
                            required
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.customerMobile}
                            className="form-control form-control--light"
                          />
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <p className="text-danger">
                              {formik.errors.mobile}
                            </p>
                          ) : null}
                        </div>
                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label
                            className="rlr-form-label rlr-form-label--light required"
                            htmlFor="nationalityId"
                          >
                            {t("Nationality")}
                          </label>

                          <select
                            id="nationalityId"
                            name="nationalityId"
                            class="form-control ps-2 form-control--light"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.nationalityId}
                            required
                          >
                            <option value="" hidden>
                              {t("Select")}
                            </option>
                            {signUpData.nationalities?.map((nationality) => (
                              <option
                                key={nationality.nationalityId}
                                value={nationality.nationalityId}
                              >
                                {" "}
                                {nationality.nationalityName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label
                            className="rlr-form-label rlr-form-label--light required"
                            htmlFor="countryId"
                          >
                            {t("Countries")}
                          </label>

                          <select
                            name="countryId"
                            id="countryId"
                            class="form-control ps-2 form-control--light"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.countryId}
                            required
                          >
                            <option value="" hidden>
                              {t("Select")}
                            </option>
                            {signUpData.countries?.map((country) => (
                              <option
                                key={country.countryId}
                                value={country.countryId}
                              >
                                {" "}
                                {country.countryName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label className="rlr-form-label rlr-form-label--light required">
                            {t("Password")}
                          </label>
                          <div className="d-flex align-items-center">
                            <input
                              type={passwordType}
                              name="password"
                              autoComplete="off"
                              required
                              minLength="8"
                              className="form-control form-control--light"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.password}
                            />
                            <i
                              className="fa fa-eye"
                              id="togglePassword"
                              onClick={() =>
                                passwordType === "password"
                                  ? setPasswordType("text")
                                  : setPasswordType("password")
                              }
                              style={{ marginLeft: "-30px", cursor: "pointer" }}
                            ></i>
                          </div>
                          {formik.touched.password && formik.errors.password ? (
                            <p className="text-danger">
                              {formik.errors.password}
                            </p>
                          ) : null}
                        </div>

                        <div className="rlr-authforms__inputgroup col-lg-6">
                          <label className="rlr-form-label rlr-form-label--light required">
                            {t("Confirm Password")}
                          </label>
                          <div className="d-flex align-items-center">
                            <input
                              type={repasswordType}
                              id="confirmpassword"
                              required
                              autoComplete="off"
                              value={rePass}
                              onChange={(e) => setRePass(e.target.value)}
                              minLength="8"
                              className="form-control form-control--light"
                            />
                            <i
                              className="fa fa-eye"
                              id="toggleconfirmpassword"
                              onClick={() =>
                                repasswordType === "password"
                                  ? setRepasswordType("text")
                                  : setRepasswordType("password")
                              }
                              style={{ marginLeft: "-30px", cursor: "pointer" }}
                            ></i>
                          </div>
                          {rePass !== formik.values.password && (
                            <p className="text-danger" hidden="" id="notMatch">
                              {t("Passwords do not match")}!
                            </p>
                          )}
                        </div>

                        {message !== null && (
                          <p className="help-text text-danger text-center fw-bold">
                            {message}
                          </p>
                        )}

                        <div className="col-lg-12 mx-auto">
                          <button
                            type="submit"
                            className="btn rlr-button rlr-button--fullwidth rlr-button--primary  "
                          >
                            {t("Sign Up")}
                          </button>

                          {/* <button
                          type="button"
                          id="googleSignInButton"
                          style={{ cursor: "pointer" }}
                          className="btn mb-3 rlr-button rlr-button--fullwidth rlr-button--google"
                        >
                          Sign in with Google
                        </button> */}
                          <GoogleLogin
                            clientId={clientId}
                            buttonText={t("Sign in with Google")}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            isSignedIn={false}
                            className="btn mb-3 rlr-button rlr-button--fullwidth text-dark fw-bold fs-5 google  "
                            id="googleSignInButton"
                          />
                        </div>
                      </div>
                    </form>

                    <div className="rlr-authforms__notes">
                      <p>
                        {t("Already have an account")}?{" "}
                        <Link to="/login">{t("Log In")} </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default SignUp;
