import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { fetchMiceDetails } from "../../data/mice/miceDetailsSlice";
import { useTranslation } from "react-i18next";
const MiceDetails = () => {
  const { t } = useTranslation();
  const { serviceUrl } = useParams();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { miceDetails, loading, error } = useSelector(
    (state) => state.miceDetails
  );

  useEffect(() => {
    dispatch(fetchMiceDetails({ serviceUrl, language }));
  }, [dispatch, serviceUrl, language]);
  return (
    <>
      <Helmet>
        <title>
          {miceDetails.pageDetails?.pageMetatagTitle ?? "mice service"}
        </title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/mice/${serviceUrl}`}
        />
        <meta
          name="description"
          content={miceDetails.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={miceDetails.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={miceDetails.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={miceDetails.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={miceDetails.pageDetails?.metatagDescriptionTwitter}
        />
        <style>{`
        .sidebar-widget-area .recent-post-widget .recent-post-list .post-thumbnail-content:not(:last-child) {
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(28, 35, 31, 0.1) !important;}
        `}</style>
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? miceDetails.pageDetails?.pageBannerMobile
                          : isTablet
                          ? miceDetails.pageDetails?.pageBannerTablet
                          : miceDetails.pageDetails?.pageBannerPc
                      }
                      alt={miceDetails.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {miceDetails.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/mice">{t("Mice")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {miceDetails.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="blog-details-section pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details-wrapper">
                <div className="blog-post mb-60 wow fadeInUp">
                  <div className="post-thumbnail"></div>

                  <div className="main-post" style={{ borderBottom: "0" }}>
                    <div className="entry-content">
                      <h3 className="title">
                        {miceDetails.miceServiceDetails?.miceservicesName}
                      </h3>
                      <div
                        className="SiteFontP"
                        dangerouslySetInnerHTML={{
                          __html:
                            miceDetails.miceServiceDetails?.miceservicesDetails,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar-widget-area pl-lg-40">
                <div className="sidebar-widget recent-post-widget mb-40 wow fadeInUp">
                  <h5 className="widget-title">{t("MICE Services")} </h5>
                  <ul className="recent-post-list">
                    {miceDetails.otherMiceServices?.map((mice) => (
                      <li
                        key={mice.miceservicesUrl}
                        className="post-thumbnail-content pb-0  "
                        style={{ borderBottom: "0" }}
                      >
                        <div className="post-title-date">
                          <h5 style={{ borderBottom: "0" }}>
                            <Link to={`/mice/${mice.miceservicesUrl}`}>
                              {mice.miceservicesName}
                            </Link>
                          </h5>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MiceDetails;
