import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import BookingStepThree from "../../components/booking-steps/cruises/BookingStepThree";
import { fetchBookingCabins } from "../../data/cruises/bookingCabinsSlice";
import { useTranslation } from "react-i18next";
const CabinsBooking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();

  // cruises Data
  const { opid } = useParams();
  const { cruiseUrl } = useParams();

  const cruises_adultsCruises = localStorage.getItem("cruises_adultsCruises");
  const cruises_childrenCruises = localStorage.getItem(
    "cruises_childrenCruises"
  );
  const currency = useSelector((state) => state.currency.currency);

  const { bookingCabins, loadingBookingCabins, errorBookingCabins } =
    useSelector((state) => state.bookingCabins);
  useEffect(() => {
    dispatch(
      fetchBookingCabins({
        opid,
        cruises_adultsCruises,
        cruises_childrenCruises,
        currency,
        language,
      })
    );
  }, [
    dispatch,
    opid,
    cruises_adultsCruises,
    cruises_childrenCruises,
    currency,
    language,
  ]);

  return (
    <>
      <Helmet>
        <title>{bookingCabins.pageDetails?.pageMetatagTitle}</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          data-react-helmet="true"
          href={`https://travco.com/${language}/${cruiseUrl}/cabinsbooking/${opid}`}
        />
        <meta
          name="description"
          content={bookingCabins.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={bookingCabins.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={bookingCabins.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={bookingCabins.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={bookingCabins.pageDetails?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/news.css" />
        <link
          href="TITSolutions/WS/assets/cruises-booking-left.css"
          rel="stylesheet"
        ></link>
        <link
          href="TITSolutions/WS/assets/BookingCruises.css"
          rel="stylesheet"
        />
        <style type="text/css">{`
        
        .react-datepicker-wrapper {
        flex: 1;
        }
        
        @media screen and  (max-width: 768px) {
    .rlr-product-filters__filters-wrapper .rlr-checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 10px;
    }
        
}
`}</style>
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      placeholder={
                        isMobile
                          ? bookingCabins.pageDetails?.pageBannerMobile
                          : isTablet
                          ? bookingCabins.pageDetails?.pageBannerTablet
                          : bookingCabins.pageDetails?.pageBannerPc
                      }
                      src={
                        isMobile
                          ? bookingCabins.pageDetails?.pageBannerMobile
                          : isTablet
                          ? bookingCabins.pageDetails?.pageBannerTablet
                          : bookingCabins.pageDetails?.pageBannerPc
                      }
                      alt={bookingCabins.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {bookingCabins.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <BookingStepThree />
      <section className="border-bottom container pt-5 pb-70" id="rooms">
        <div className="container">
          <div className="row rlr-search-results-page__product-details">
            {/* Cruise Details  */}
            <aside className="col-lg-3 col-xl-3 ">
              <div className="mb-4">
                <div className="border border-color-7 rounded px-4 pt-4 pb-3 mb-5">
                  <div className="px-2 pt-2">
                    <Link
                      to={bookingCabins.itineraryDetails?.cruisePhoto}
                      data-fancybox="cruise-view"
                      className="d-block border rounded mb-4"
                    >
                      <img
                        src={bookingCabins.itineraryDetails?.cruisePhoto}
                        alt={bookingCabins.itineraryDetails?.cruiseNameSys}
                        loading="lazy"
                        placeholder="Loading.."
                        className="img-fluid"
                      />
                    </Link>
                    <div className="flex-horizontal-center mb-4 d-flex ">
                      <div className="ml-2 text-lh-1">
                        <div className="ml-1">
                          <h4 className="text-primary font-size-17 font-weight-bold mb-0">
                            {bookingCabins.itineraryDetails?.cruiseNameSys}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <div
                        className="rlr-review-stars mx-auto"
                        itemprop="ratingValue"
                        itemscope=""
                        itemtype="https://schema.org/Product"
                      >
                        <i className="rlr-icon-font flaticon-star-1  fs-5"> </i>{" "}
                        <i className="rlr-icon-font flaticon-star-1 fs-5"> </i>{" "}
                        <i className="rlr-icon-font flaticon-star-1 fs-5"> </i>{" "}
                        <i className="rlr-icon-font flaticon-star-1 fs-5"> </i>{" "}
                        <i className="rlr-icon-font flaticon-star-1  fs-5"> </i>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="rlr-icon-font flaticon-luggage fs-4 text-primary mr-3 pr-1">
                        {" "}
                      </i>
                      <h6 className="mb-0 font-size-14 text-gray-1">
                        <Link to="#.">
                          <strong>{t("Duration")}:</strong> <br />
                          <small>
                            {bookingCabins.itineraryDetails?.itinararyDuration}
                          </small>
                        </Link>
                      </h6>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="rlr-icon-font flaticon-luggage fs-4 text-primary mr-3 pr-1">
                        {" "}
                      </i>
                      <h6 className="mb-0 font-size-14 text-gray-1">
                        <Link to="#.">
                          <strong>{t("Route")}: </strong> <br />
                          <small>
                            {
                              bookingCabins.itineraryDetails
                                ?.itineraryNameMarketing
                            }
                          </small>
                        </Link>
                      </h6>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="rlr-icon-font flaticon-calendar  fs-5 text-primary mr-3 pr-1"></i>
                      <h6 className="mb-0 font-size-14 text-gray-1">
                        <Link to="#.">
                          <strong>{t("Check-in Date")}: </strong> <br />
                          <small>
                            {" "}
                            {bookingCabins.itineraryDetails?.checkInDate}
                          </small>
                        </Link>
                      </h6>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <i className="rlr-icon-font flaticon-history  fs-4 text-primary mr-3 pr-1"></i>
                      <h6 className="mb-0 font-size-14 text-gray-1">
                        <Link to="#.">
                          <strong>{t("Check-out Date")}: </strong> <br />
                          <small>
                            {bookingCabins.itineraryDetails?.checkOutDate}
                          </small>
                        </Link>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
            <div className="rlr-search-results-page__product-list col-lg-9">
              <div className="row rlr-search-results-page__card-wrapper">
                {bookingCabins.cabins?.length > 0 ? (
                  <>
                    <Suspense fallback={<div>{t("Loading")}...</div>}>
                      {bookingCabins.cabins?.map((cabin) => (
                        <div id="cruisesList" key={cabin.cabinUrl}>
                          <div className="col-lg-12 col-xl-12 singleCruise">
                            <div className="card border-color-7 mb-5 overflow-hidden">
                              <div className="product-item__outer w-100">
                                <div className="row">
                                  <article
                                    className="rlr-product-card rlr-product-card--v3  py-3"
                                    itemscope=""
                                    itemtype="https://schema.org/Product"
                                  >
                                    <figure className="rlr-product-card__image-wrapper col-4">
                                      <div className="swiper rlr-js-product-multi-image-swiper swiper-initialized swiper-horizontal">
                                        <a
                                          href={cabin.cabinPhoto}
                                          data-fancybox="cabin-view"
                                          className="swiper-slide"
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            marginRight: "30px",
                                          }}
                                        >
                                          <img
                                            itemprop="image"
                                            alt={cabin.cabinNameSys}
                                            loading="lazy"
                                            src={cabin.cabinPhoto}
                                          />
                                        </a>
                                      </div>
                                    </figure>
                                    <div
                                      className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper col-7"
                                      style={{ borderLeft: "0" }}
                                    >
                                      <header className="rlr-product-card__header">
                                        <div>
                                          <Link
                                            className="rlr-product-card__anchor-title"
                                            to="#."
                                          >
                                            <h2
                                              className="rlr-product-card__title"
                                              itemprop="name"
                                            >
                                              {cabin.cabinNameSys} -{" "}
                                              <small>
                                                {cabin.itineraryNameSys}
                                              </small>
                                            </h2>
                                          </Link>
                                          <div>
                                            <span className="rlr-product-card__sub-title">
                                              <b> {cabin.cabinSize}</b> m
                                              <sup>2</sup>
                                            </span>
                                          </div>
                                        </div>
                                      </header>

                                      <div
                                        className="rlr-product-card__footer"
                                        dangerouslySetInnerHTML={{
                                          __html: cabin.cabinDetails,
                                        }}
                                      />
                                    </div>
                                    <div className="col-2 book-side">
                                      <div className="text-center  booking">
                                        <div className="mb-2 pb-1">
                                          <span className="font-size-13">
                                            {t("From")}{" "}
                                          </span>
                                          <br />

                                          <span className="font-weight-bold font-size-22 ml-1">
                                            {" "}
                                            {cabin.priceCurrency}{" "}
                                            {cabin.cabinPrice}
                                          </span>
                                        </div>
                                        <button
                                          id="Submit1"
                                          type="button"
                                          className="btn btn-outline-primary border-radius-3 border-width-2 px-3 font-weight-bold min-width-200 py-2 text-lh-lg"
                                          onClick={() =>
                                            navigate(
                                              `/${cruiseUrl}/booking/${cabin.oppriceId}`
                                            )
                                          }
                                        >
                                          {t("Book Now")}{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </article>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Suspense>
                  </>
                ) : (
                  <p className="SiteFontP text-travco">
                    {t("No Cabins Available Now! Please Adjust Your Search")}.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CabinsBooking;
