import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchVisaOrders } from "../../data/orders/visaOrdersSlice";
import { useTranslation } from "react-i18next";
const VisaOrders = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const { visaOrders, loading, error } = useSelector(
    (state) => state.visaOrders
  );

  useEffect(() => {
    dispatch(fetchVisaOrders({ orderId, language }));
  }, [orderId, dispatch, language]);

  return (
    <>
      <Helmet>
        <title>
          {visaOrders.pageDetails?.pageMetatagTitle ?? "Order Visa"}
        </title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/orderVisaDetails/${orderId}`}
        />
        <meta
          name="description"
          content={visaOrders.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={visaOrders.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={visaOrders.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={visaOrders.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={visaOrders.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">
          {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
        </style>
        <link
          href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/login-booking/css/style.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/line-awesome-3.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? visaOrders?.pageDetails?.pageBannerMobile
                          : isTablet
                          ? visaOrders?.pageDetails?.pageBannerTablet
                          : visaOrders?.pageDetails?.pageBannerPc
                      }
                      alt={visaOrders?.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="mb-5 shadow-soft bg-white rounded-sm">
                <div className="col-lg-8 mx-auto">
                  <div class="pt-4 pb-5 px-5 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      {t("Flight Details")}
                    </h5>

                    <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Arrival Flight Number")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.arrivalFlightNumber}{" "}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Arrival Airline")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.arrivalAirline}{" "}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {" "}
                          {t("Arrival Date")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.arrivalDate}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Arrival Time")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.arrivalTime}
                        </span>
                      </li>
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">{t("Origin")} </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.originName}
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div class="pt-4 pb-5 px-5 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      {t("Payment Information")}
                    </h5>

                    <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Transaction Reference")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.payTabTranRef}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Total Amount")}:{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {" "}
                          {visaOrders.orderDetails?.payTabCartAmount}{" "}
                          {visaOrders.orderDetails?.payTabCartCurrency}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">{t("Card")} </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.payTabPaymentInfoCardScheme}{" "}
                          (
                          {
                            visaOrders.orderDetails
                              ?.payTabPaymentInfoPaymentDescription
                          }
                          )
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div class="pt-4 pb-5 px-5 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      {t("Traveler Information")}
                    </h5>

                    <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Booking Number")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.payTabCartId}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Full Name")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.guestName}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("E-mail Address")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.email}
                        </span>
                      </li>
                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">{t("Mobile")} </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.mobile}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">
                          {t("Nationality")}{" "}
                        </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.nationalityName}
                        </span>
                      </li>

                      <li class="d-flex justify-content-between py-2">
                        <span class="font-weight-medium">{t("Language")} </span>
                        <span class="text-secondary text-right">
                          {visaOrders.orderDetails?.languageName}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div class="pt-4 pb-5 px-5 border-bottom">
                    <h5
                      id="scroll-description"
                      class="font-size-21 font-weight-bold text-dark mb-2"
                    >
                      {t("Passengers Information")}
                    </h5>

                    <div class="table-responsive">
                      <table class="table table-bordered text-nowrap border-bottom table-hover">
                        <thead
                          class="text-white bg-danger "
                          style={{ backgroundColor: "#e60000", opacity: 1 }}
                        >
                          <tr>
                            <th class="text-center"> {t("Name")} </th>
                            <th class="text-center"> {t("Nationality")} </th>
                            <th class="text-center">
                              {" "}
                              {t("Passport Number")}{" "}
                            </th>
                            <th class="text-center"> {t("Birth Date")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          {visaOrders.orderDetails?.passengers?.map(
                            (passenger, index) => (
                              <tr key={index}>
                                <td class="text-center">{passenger.name}</td>
                                <td class="text-center">
                                  {passenger.nationalityName}
                                </td>

                                <td class="text-center">
                                  {passenger.passportNumber}
                                </td>

                                <td class="text-center">
                                  {passenger.birthOfDate}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisaOrders;
