import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchProfile } from "../../data/profile/profileSlice";
import { Helmet } from "react-helmet";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import {
  resetState,
  updatePassword,
} from "../../data/profile/passwordUpdateSlice";
import {
  resetProfileUpdateState,
  updateProfile,
} from "../../data/profile/profileUpdateSlice";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);

  const { profile } = useSelector((state) => state.profile);
  const { loadingUpdateProfile, successUpdateProfile, errorUpdateProfile } =
    useSelector((state) => state.profileUpdate);
  const { loading, success, error } = useSelector(
    (state) => state.passwordUpdate
  );

  useEffect(
    function () {
      dispatch(fetchProfile(language));
    },
    [dispatch, successUpdateProfile, language]
  );
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openUpdated, setOpenUpdated] = useState(false);
  const [firstName, setFirstName] = useState(
    profile.userInfo?.customerFirstName
  );
  const [lastName, setLastName] = useState(profile.userInfo?.customerLastName);
  const [mobile, setMobile] = useState(profile.userInfo?.customerMobile);
  const [email, setEmail] = useState(profile.userInfo?.customerEmailAddress);
  const [nationality, setNationality] = useState(
    profile.userInfo?.customerNationalityId
  );

  useEffect(() => {
    if (successUpdateProfile) {
      setOpenUpdated(true);
      dispatch(resetProfileUpdateState());
    }
  }, [successUpdateProfile, dispatch]);
  const handleSubmitUpdateProfile = (e) => {
    e.preventDefault();
    const profileData = {
      customerFirstName: firstName,
      customerLastName: lastName,
      customerMobile: mobile,
      customerEmailAddress: email,
      customerNationalityId: nationality,
    };
    dispatch(updateProfile(profileData));
  };
  useEffect(() => {
    if (success) {
      setOpen(true);
      dispatch(resetState());
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    }
  }, [success, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      alert(t("New password and confirmation do not match"));
      return;
    }
    const passwordData = {
      oldpassword: oldPassword,
      newpassword: newPassword,
      confirmnewpassword: confirmNewPassword,
    };
    dispatch(updatePassword(passwordData));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleCloseUpdated = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenUpdated(false);
  };

  return (
    <>
      <Helmet>
        <title>{profile.pageDetails?.pageMetatagTitle}</title>
        <link rel="canonical" href="https://travco.com/en/login" />
        <meta
          name="description"
          content={profile.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={profile.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={profile.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={profile.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={profile.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner mb-0"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      className="rlr-banner-splide__banner-img"
                      src={
                        isMobile
                          ? profile.pageDetails?.pageBannerMobile
                          : isTablet
                          ? profile.pageDetails?.pageBannerTablet
                          : profile.pageDetails?.pageBannerPc
                      }
                      alt={profile.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className=" rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {profile.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <main id="rlr-main" className="rlr-main--fixed-top my-5 py-5">
        <div
          className="rlr-section__content--lg-top mt-0"
          style={{ marginTop: "0px !important" }}
        >
          <section
            className="rlr-section rlr-section__mt rlr-account mt-0"
            style={{ marginTop: "0px !important" }}
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 offset-lg-12 p-5"
                  style={{
                    backgroundColor: "#ffffff",
                    boxShadow: "0 0 20px #f5f5f5",
                    borderRadius: "20px",
                  }}
                >
                  <form
                    className="rlr-authforms"
                    id="access"
                    onSubmit={handleSubmitUpdateProfile}
                  >
                    <div className="rlr-authforms__header">
                      <h2>{t("Edit Profile")}</h2>
                    </div>
                    <div className="rlr-authforms__form row">
                      <div className="rlr-authforms__inputgroup col-lg-6">
                        <label
                          htmlFor="fname"
                          className="rlr-form-label rlr-form-label--light required"
                        >
                          {t("First Name")}
                        </label>
                        <input
                          type="text"
                          name="fname"
                          id="fname"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          autoComplete="off"
                          className="form-control form-control--light"
                          placeholder={firstName}
                        />
                      </div>
                      <div className="rlr-authforms__inputgroup col-lg-6">
                        <label
                          htmlFor="lname"
                          className="rlr-form-label rlr-form-label--light required"
                        >
                          {t("Last Name")}
                        </label>
                        <input
                          type="text"
                          name="lname"
                          id="lname"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          autoComplete="off"
                          className="form-control form-control--light"
                        />
                      </div>
                      <div className="rlr-authforms__inputgroup col-lg-6">
                        <label className="rlr-form-label rlr-form-label--light required">
                          {t("Mobile")}
                        </label>
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          autoComplete="off"
                          className="form-control form-control--light"
                        />
                      </div>
                      <div className="rlr-authforms__inputgroup col-lg-6">
                        <label className="rlr-form-label rlr-form-label--light required">
                          {t("Email")}
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete="off"
                          className="form-control form-control--light"
                        />
                      </div>
                      <div className="rlr-authforms__inputgroup col-lg-6">
                        <label className="rlr-form-label rlr-form-label--light required">
                          {t("Nationality")}
                        </label>
                        <select
                          name="nationality"
                          className="form-control form-control--light"
                          id="nationality"
                          value={nationality}
                          onChange={(e) => setNationality(e.target.value)}
                        >
                          {profile?.nationalities?.map((nationality) => (
                            <option
                              key={nationality.nationalityId}
                              value={nationality.nationalityId}
                            >
                              {nationality.nationalityName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-12 col-md-12 d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                          <button
                            type="submit"
                            className="btn mb-3 rlr-button rlr-button--fullwidth rlr-button--primary"
                            disabled={loading}
                          >
                            {loadingUpdateProfile
                              ? "Loading..."
                              : "Update Profile"}
                          </button>
                        </div>
                      </div>
                      {error && (
                        <p>
                          {" "}
                          {t("Error")}: {t("error.message")}
                        </p>
                      )}
                    </div>
                    <Snackbar
                      open={openUpdated}
                      autoHideDuration={6000}
                      onClose={handleCloseUpdated}
                    >
                      <Alert
                        onClose={handleCloseUpdated}
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {t("Profile updated successfully")}!
                      </Alert>
                    </Snackbar>
                  </form>
                  <form className="rlr-authforms" onSubmit={handleSubmit}>
                    <div className="rlr-authforms__header">
                      <h2>{t("Change Password")}</h2>
                    </div>
                    <div className="rlr-authforms__form row">
                      <div className="rlr-authforms__inputgroup col-lg-4">
                        <label
                          htmlFor="old-password"
                          className="rlr-form-label rlr-form-label--light required"
                        >
                          {t("Old Password")}
                        </label>
                        <input
                          type="password"
                          name="old-password"
                          id="old-password"
                          autoComplete="off"
                          className="form-control form-control--light"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                      <div className="rlr-authforms__inputgroup col-lg-4">
                        <label
                          htmlFor="new-password"
                          className="rlr-form-label rlr-form-label--light required"
                        >
                          {t("New Password")}
                        </label>
                        <input
                          type="password"
                          name="new-password"
                          id="new-password"
                          autoComplete="off"
                          className="form-control form-control--light"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                      <div className="rlr-authforms__inputgroup col-lg-4">
                        <label className="rlr-form-label rlr-form-label--light required">
                          {t("Confirm New Password")}
                        </label>
                        <input
                          type="password"
                          name="confirm-password"
                          id="confirm-password"
                          autoComplete="off"
                          className="form-control form-control--light"
                          value={confirmNewPassword}
                          onChange={(e) =>
                            setConfirmNewPassword(e.target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6">
                          <button
                            type="submit"
                            className="btn mb-3 rlr-button rlr-button--fullwidth rlr-button--primary"
                            disabled={loading}
                          >
                            {loading
                              ? `${t("Processing")}...`
                              : t("Change Password")}
                          </button>
                        </div>
                      </div>
                      {error && (
                        <p className="text-center text-travco">
                          {t("Error")}:{" "}
                          {error?.message ? t(error?.message) : t(error)}
                        </p>
                      )}
                    </div>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {t("Password changed successfully")}!
                      </Alert>
                    </Snackbar>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default Profile;
