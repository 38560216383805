import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { styled, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { setPageIndex } from "../../data/destinations/destinationsEgyptSlice";
import ScrollToTop from "../../ScrollToTop";
import { fetchDestinationsEgypt } from "../../data/destinations/destinationsEgyptSlice";
import { useTranslation } from "react-i18next";
const DestinationEgypt = () => {
  const { t } = useTranslation();
  const { DestinationURL } = useParams();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const language = useSelector((state) => state.language.language);
  // search
  const [searchTerm, setSearchTerm] = useState("");
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const currency = useSelector((state) => state.currency.currency);
  const { destinationsEgypt, pageIndex, loading, error } = useSelector(
    (state) => state.destinationsEgypt
  );

  const handleNextPage = () => {
    if (pageIndex < destinationsEgypt?.totalPages) {
      dispatch(setPageIndex(pageIndex + 1));
      setScroll(true);
    }
  };
  const handlePreviousPage = () => {
    if (pageIndex > 1) {
      dispatch(setPageIndex(pageIndex - 1));
      setScroll(true);
    }
  };
  const handlePageChange = (page) => {
    dispatch(setPageIndex(page));
    setScroll(true);
  };
  const [Sort, setSort] = useState(1);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [toursType, setToursType] = useState([]);
  const [groupSize, setGroupSize] = useState([]);
  const [durations, setDurations] = useState([]);
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedStyle((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedStyle((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };
  const handleToursTypesCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setToursType((prevSelected) => [...prevSelected, value]);
    } else {
      setToursType((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };

  const handleGroupSizesCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      if (durations.includes(0)) {
        durations.pop();
      }
      setGroupSize((prevSelected) => [...prevSelected, value]);
    } else {
      setGroupSize((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };
  const handleDurationCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      if (durations.includes(0)) {
        durations.pop();
      }
      setDurations((prevSelected) => [...prevSelected, value]);
    } else {
      setDurations((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };
  useEffect(() => {
    dispatch(
      fetchDestinationsEgypt({
        DestinationURL,
        pageIndex,
        searchTerm,
        Sort,
        currency,
        selectedStyle,
        toursType,
        groupSize,
        durations,
        language,
      })
    );

    setScroll(false);
  }, [
    dispatch,
    searchTerm,
    pageIndex,
    Sort,
    currency,
    selectedStyle,
    toursType,
    groupSize,
    durations,
    DestinationURL,
    language,
  ]);
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  return (
    <>
      <Helmet>
        <title>{destinationsEgypt.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/destination/egypt/${DestinationURL}`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={destinationsEgypt.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={destinationsEgypt.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={destinationsEgypt.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={destinationsEgypt.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={destinationsEgypt.pageDetails?.metatagDescriptionTwitter}
        />
        <link rel="stylesheet" href="css/news.css" />
        <style type="text/css">{`@media screen and  (max-width: 768px) {
    .rlr-product-filters__filters-wrapper .rlr-checkboxes {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 10px;
    }
}`}</style>
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      placeholder={
                        isMobile
                          ? destinationsEgypt.pageDetails?.pageBannerMobile
                          : isTablet
                          ? destinationsEgypt.pageDetails?.pageBannerTablet
                          : destinationsEgypt.pageDetails?.pageBannerPc
                      }
                      src={
                        isMobile
                          ? destinationsEgypt.pageDetails?.pageBannerMobile
                          : isTablet
                          ? destinationsEgypt.pageDetails?.pageBannerTablet
                          : destinationsEgypt.pageDetails?.pageBannerPc
                      }
                      alt={destinationsEgypt.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {destinationsEgypt.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")}</Link>
              </li>
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/destination/egypt">{t("Destinations")}</Link>
              </li>

              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {destinationsEgypt.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <aside>
        <div className="container">
          {" "}
          <div className="rlr-search-results-header rlr-search-results-header__wrapper flex-mobile">
            <h1 className="rlr-search-results-header__value">
              {t("Found")} <strong>{destinationsEgypt.totalCount}</strong>{" "}
              {t("Results For")}{" "}
              <strong>{destinationsEgypt.pageDetails?.pageTitle}</strong>
            </h1>
            <Link
              className="travel-guide pb-1"
              to={`/destination-guide/${DestinationURL}`}
              style={{ color: "#e60000" }}
            >
              <img
                alt="travel guide icon"
                src="../../../../images/uploads/map_13852246.png"
                width="27"
                style={{ paddingBottom: "4px" }}
              />
              {t("Destination Guide")}
            </Link>

            <div className="rlr-search-results-header__sorting-wrapper">
              <span className="rlr-search-results-header__label">
                {t("Sort by")}:{" "}
              </span>
              <div className="dropdown rlr-dropdown rlr-js-dropdown">
                <select
                  id="ddl_SortID"
                  name="ddl_SortID"
                  className="ddl_SortID form-control border-1"
                  onChange={(e) => {
                    setSort(e.target.value);
                  }}
                >
                  <option value="1">{t("Price (Low to High)")}</option>
                  <option value="2">{t("Price (High to Low)")}</option>
                  <option value="3">{t("Duration (Short to Long)")}</option>
                  <option value="4">{t("Duration (Long to Short)")}</option>
                  <option value="5">{t("Newest")}</option>
                  <option value="6">{t("Popularity")}</option>
                </select>
              </div>
            </div>

            <div
              className="rlr-search-results-header__sorting-wrapper "
              id="filter"
              onClick={() => setView(!view)}
            >
              <span className="rlr-search-results-header__label">
                {t("Filter By")}
              </span>{" "}
              <i
                className="rlr-icon-font flaticon-menu"
                style={{
                  color: "#e60000",
                  fontSize: "25px",
                  paddingTop: "6px,",
                }}
              >
                {" "}
              </i>
            </div>
          </div>
        </div>
      </aside>
      <section>
        <div className="container">
          <div className="row rlr-search-results-page__product-details">
            {" "}
            <aside
              className={`col-xl-3 rlr-search-results-page__sidebar ${
                isMobile && view && "view"
              }`}
              id="filter-mobile"
            >
              <div className="rlr-product-filters__filters-wrapper pb-3">
                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-0 ms-auto"
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
                {/* Product Type filter */}
                <div className="rlr-product-filters__filter ">
                  <div className=" mt-1 mb-4 packages col-lg-5 w-100">
                    <div
                      className="search-wrapper w-100"
                      style={{ position: "relative" }}
                    >
                      <SearchIconWrapper>
                        <SearchIcon sx={{ color: "#e60000" }} />
                      </SearchIconWrapper>
                      <input
                        className="form-control ps-5"
                        style={{
                          border: 0,
                          boxShadow: "0 0 1px 2px #dddddd94",
                        }}
                        placeholder={`${t("Search")}…`}
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center pb-2  ">
                    <i
                      className="rlr-icon-font rlr-icon-font flaticon-traveling fs-4"
                      style={{ color: "#ef0000" }}
                    ></i>
                    <label
                      className="rlr-form-label rlr-product-filters__label rlr-section__title--main"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      {t("What are you looking for")}
                      {language == "ar" ? "؟" : "?"}
                    </label>
                  </div>
                  <ul className="rlr-checkboxes">
                    {destinationsEgypt.styles?.map((style) => (
                      <li
                        className="form-check form-check-block"
                        key={style.productStyleId}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox  "
                          id={style.productStyleId}
                          type="checkbox"
                          name="StyleID"
                          value={style.productStyleId}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor={style.productStyleId}
                          style={{ fontSize: 14 }}
                        >
                          {style.productStyleNameSys}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Destination tags filter */}
                <div className="rlr-product-filters__filter">
                  <div className="d-flex align-items-center pb-2">
                    <i
                      className="rlr-icon-font flaticon-right"
                      style={{ color: "#ef0000" }}
                    ></i>
                    <label
                      className="rlr-form-label rlr-product-filters__label rlr-section__title--main"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 22,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Tour Types")}
                    </label>
                  </div>
                  <ul className="rlr-checkboxes" style={{ paddingTop: 10 }}>
                    {destinationsEgypt.types?.map((tour) => (
                      <li
                        className="form-check form-check-block"
                        key={tour.productTypeId}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox  "
                          id={tour.productTypeId}
                          type="checkbox"
                          value={tour.productTypeId}
                          onChange={handleToursTypesCheckboxChange}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor={tour.productTypeId}
                          style={{ fontSize: 14 }}
                        >
                          {tour.productTypeNameSys}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Group Size filter */}
                <div className="rlr-product-filters__filter">
                  <div className="d-flex align-items-center pb-2">
                    <i
                      className="rlr-icon-font flaticon-add-user"
                      style={{ color: "#ef0000" }}
                    ></i>
                    <label
                      className="rlr-form-label rlr-product-filters__label rlr-section__title--main"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 22,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Group Size")}
                    </label>
                  </div>
                  <ul className="rlr-checkboxes">
                    {destinationsEgypt.groupSizes?.map((gSize) => (
                      <li
                        className="form-check form-check-block"
                        key={gSize.productGroupSizeId}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox"
                          id={"Group" + gSize.productGroupSizeId}
                          type="checkbox"
                          name={"Group" + gSize.productGroupSizeId}
                          value={gSize.productGroupSizeId}
                          onChange={handleGroupSizesCheckboxChange}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor={"Group" + gSize.productGroupSizeId}
                          style={{ fontSize: 14 }}
                        >
                          {gSize.productGroupSizeNameSys}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Durations Filter */}
                <div className="rlr-product-filters__filter">
                  <div className="d-flex align-items-center pb-2">
                    <i
                      className="rlr-icon-font flaticon-three-o-clock-clock"
                      style={{ color: "#ef0000" }}
                    ></i>
                    <label
                      className="rlr-form-label rlr-product-filters__label rlr-section__title--main"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 22,
                        fontWeight: "bold",
                      }}
                    >
                      {t("Durations")}
                    </label>
                  </div>
                  <ul className="rlr-checkboxes">
                    {destinationsEgypt.durations?.map((duration) => (
                      <li
                        className="form-check form-check-block"
                        key={duration.productDurationId}
                      >
                        <input
                          className="form-check-input rlr-form-check-input rlr-product-filters__checkbox"
                          id={duration.productDurationNameSys}
                          type="checkbox"
                          name={duration.productDurationId}
                          value={duration.productDurationId}
                          onChange={handleDurationCheckboxChange}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-product-filters__checkbox-label"
                          htmlFor={duration.productDurationNameSys}
                          style={{ fontSize: 14 }}
                        >
                          {duration.productDurationNameSys}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                {isMobile && (
                  <Button
                    onClick={() => setView(false)}
                    className="btn btn-danger d-flex justify-content-end mt-5 mx-auto"
                  >
                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                  </Button>
                )}
              </div>
            </aside>
            <div className="rlr-search-results-page__product-list col-lg-9">
              <div className="row rlr-search-results-page__card-wrapper">
                {loading && scroll ? (
                  <>
                    <ScrollToTop />
                  </>
                ) : (
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    {destinationsEgypt.products?.length > 0 ? (
                      <>
                        {" "}
                        {destinationsEgypt.products?.map((item) => (
                          <div
                            className="col-md-6 col-lg-4"
                            key={item.productID}
                          >
                            <article
                              className="rlr-product-card rlr-product-card--v3"
                              itemScope=""
                              itemType="en/dive-in-colors"
                            >
                              <figure className="rlr-product-card__image-wrapper">
                                {item.isPopular ? (
                                  <span className="rlr-badge rlr-badge-- rlr-badge--accent-red rlr-product-card__badge">
                                    {t("Popular")}
                                  </span>
                                ) : (
                                  ""
                                )}

                                <div className="swiper rlr-js-product-multi-image-swiper">
                                  <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                      <Link
                                        to={`/${
                                          item.productType == "Excursions" ||
                                          item.productTypeID == 3
                                            ? "tour"
                                            : item.productType
                                        }/${item.productFullUrl}`}
                                      >
                                        <img
                                          itemProp="image"
                                          data-sizes="auto"
                                          src={item.productPhoto}
                                          data-srcset={item.productPhoto}
                                          placeholder={item.productPhoto}
                                          className="lazyload"
                                          alt={item.productName}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </figure>
                              <div className="rlr-product-card__detail-wrapper rlr-js-detail-wrapper">
                                <header className="rlr-product-card__header">
                                  <div>
                                    <Link
                                      className="rlr-product-card__anchor-title"
                                      to={`/${
                                        item.productType == "Excursions" ||
                                        item.productTypeID == 3
                                          ? "tour"
                                          : item.productType
                                      }/${item.productFullUrl}`}
                                    >
                                      <h2
                                        className="rlr-product-card__title"
                                        itemProp="name"
                                      >
                                        {item.productName}
                                      </h2>
                                    </Link>
                                    <div className="d-flex ">
                                      <Link
                                        className="rlr-product-card__anchor-cat d-flex"
                                        style={{
                                          color: "#000",
                                          paddingRight: "8px",
                                        }}
                                        to={`/${
                                          item.productType == "Excursions" ||
                                          item.productTypeID == 3
                                            ? "tour"
                                            : item.productType
                                        }/${item.productFullUrl}`}
                                      >
                                        <i className="rlr-icon-font flaticon-carbon-location me-1">
                                          {" "}
                                        </i>
                                        <span
                                          className="rlr-product-card__sub-title"
                                          style={{ color: "#000" }}
                                        >
                                          {" "}
                                          {item.destinationName}
                                        </span>
                                      </Link>

                                      <Link
                                        className="rlr-product-card__anchor-cat d-flex"
                                        style={{ color: "#000" }}
                                        to={`/${
                                          item.productType == "Excursions" ||
                                          item.productTypeID == 3
                                            ? "tour"
                                            : item.productType
                                        }/${item.productFullUrl}`}
                                      >
                                        <i className="rlr-icon-font flaticon-three-o-clock-clock me-1">
                                          {" "}
                                        </i>
                                        <span
                                          className="rlr-product-card__sub-title"
                                          style={{ color: "#000" }}
                                        >
                                          {item.productFullDuration}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </header>

                                <div className="rlr-product-card__details">
                                  <div
                                    className="rlr-product-card__prices"
                                    itemProp="offers"
                                    itemScope=""
                                    itemType="https://schema.org/Offer"
                                  >
                                    <span className="rlr-product-card__from">
                                      {t("From")}{" "}
                                    </span>

                                    <span className="rlr-product-card__price">
                                      {" "}
                                      <mark itemProp="priceCurrency"> </mark>
                                      <mark itemProp="price">
                                        {item.priceCurrency} {item.productPrice}{" "}
                                      </mark>{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        ))}
                        <div id="PaggingContainer" className="text-center">
                          <div className="pagination-container">
                            <ul className="pagination">
                              <li
                                className="PagedList-skipToPrevious"
                                onClick={handlePreviousPage}
                                style={{
                                  visibility:
                                    pageIndex <= destinationsEgypt.totalPages &&
                                    pageIndex > 1
                                      ? "visible"
                                      : "hidden",
                                }}
                              >
                                {" "}
                                <Link rel="prev">«</Link>
                              </li>
                              {Array.from(
                                { length: destinationsEgypt.totalPages },
                                (_, index) => (
                                  <li
                                    className={
                                      index + 1 === pageIndex ? "active" : ""
                                    }
                                    key={index}
                                    onClick={() => {
                                      handlePageChange(index + 1);
                                    }}
                                  >
                                    <Link>{index + 1}</Link>
                                  </li>
                                )
                              )}

                              <li
                                className="PagedList-skipToNext"
                                onClick={handleNextPage}
                                style={{
                                  visibility:
                                    pageIndex === destinationsEgypt.totalPages
                                      ? "hidden"
                                      : "visible",
                                }}
                              >
                                <Link rel="next">»</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="SiteFontP text-travco">
                        {t("No Products Matches Your Search")}!
                      </p>
                    )}
                  </Suspense>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DestinationEgypt;
