import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { fetchAffiliatedCompaniesDetails } from "./../../data/affiliated-companies/affiliatedCompaniesDetailsSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AffiliatedCompaniesDetails = () => {
  const { t, i18n } = useTranslation();
  const getRecaptchaLanguageCode = () => {
    switch (i18n.language) {
      case "es":
        return "es";
      case "ru":
        return "ru";
      case "pt":
        return "pt";
      case "ar":
        return "ar";
      default:
        return "en"; // Default to English if no match
    }
  };
  const { affiliatedCompaniesUrl } = useParams();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { affiliatedCompaniesDetails, loading, error } = useSelector(
    (state) => state.affiliatedCompaniesDetails
  );
  useEffect(() => {
    dispatch(
      fetchAffiliatedCompaniesDetails({ affiliatedCompaniesUrl, language })
    );
  }, [dispatch, affiliatedCompaniesUrl, language]);
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    customermessage: "",
    affiliatedCompaniesEmailAddress:
      affiliatedCompaniesDetails.affiliatedCompaniesDetails
        ?.affiliatedCompaniesEmailAddress,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleCaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      setErrorFormDate(t("Please complete the reCAPTCHA"));
      setOpen(true);
      return;
    }

    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);

    try {
      const response = await fetch(
        "https://api.travco.com/AffiliatedCompanyRequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerName: formData.customerName,
            customerEmail: formData.customerEmail,
            customerMobile: formData.customerPhone,
            customerMessage: formData.customermessage,
            affiliatedCompaniesEmailAddress:
              affiliatedCompaniesDetails.affiliatedCompaniesDetails
                ?.affiliatedCompaniesEmailAddress,
            namehoney: formData.namehoney, // Add if necessary
            emailhoney: formData.emailhoney, // Add if necessary
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // const responseText = await response.text();
      // let result;
      // try {
      //   result = JSON.parse(responseText);
      // } catch (e) {
      //   throw new Error("Failed to parse response as JSON");
      // }

      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorFormDate(error.message); // Ensure the error is a string
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        customermessage: "",
        namehoney: "", // Clear honey pot fields after submission
        emailhoney: "",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {affiliatedCompaniesDetails.pageDetails?.pageMetatagTitle ??
            "affiliated company"}
        </title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://travco.com/${language}/affiliated-companies/${affiliatedCompaniesUrl}`}
        />
        <meta
          name="description"
          content={
            affiliatedCompaniesDetails.pageDetails?.pageMetatagDescription
          }
        />
        <meta
          property="og:title"
          content={affiliatedCompaniesDetails.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={
            affiliatedCompaniesDetails.pageDetails?.metatagDescriptionFacebook
          }
        />
        <meta
          name="twitter:title"
          content={affiliatedCompaniesDetails.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={
            affiliatedCompaniesDetails.pageDetails?.metatagDescriptionTwitter
          }
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? affiliatedCompaniesDetails.pageDetails
                              ?.pageBannerMobile
                          : isTablet
                          ? affiliatedCompaniesDetails.pageDetails
                              ?.pageBannerTablet
                          : affiliatedCompaniesDetails.pageDetails?.pageBannerPc
                      }
                      alt={affiliatedCompaniesDetails.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {affiliatedCompaniesDetails.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/affiliated-companies">
                  {t("Affiliated Companies")}{" "}
                </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {affiliatedCompaniesDetails.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="blog-details-section pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details-wrapper">
                <div className="blog-post mb-60 wow fadeInUp">
                  <div className="post-thumbnail  pb-30">
                    <img
                      src={
                        affiliatedCompaniesDetails.affiliatedCompaniesDetails
                          ?.affiliatedCompaniesBanner
                      }
                      alt={
                        affiliatedCompaniesDetails.affiliatedCompaniesDetails
                          ?.affiliatedCompaniesName
                      }
                      loading="lazy"
                    />
                  </div>

                  <div className="main-post" style={{ borderBottom: "0px" }}>
                    <div className="entry-content">
                      <h3 className="rlr-section__title--main pb-30">
                        {
                          affiliatedCompaniesDetails.affiliatedCompaniesDetails
                            ?.affiliatedCompaniesName
                        }
                      </h3>
                      <div
                        className="SiteFontP"
                        dangerouslySetInnerHTML={{
                          __html:
                            affiliatedCompaniesDetails
                              .affiliatedCompaniesDetails
                              ?.affiliatedCompaniesDetails,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12">
                  <div className="contact-content-box mb-50">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="contact-icon-box mb-50 wow fadeInDown"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInDown",
                          }}
                        >
                          <div className="icon">
                            <i className="rlr-icon-font flaticon-carbon-location"></i>
                          </div>
                          <div className="text">
                            <h4 className="title">{t("Locations")}</h4>
                            <p>
                              {
                                affiliatedCompaniesDetails
                                  .affiliatedCompaniesDetails
                                  ?.affiliatedCompaniesAddress
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      {affiliatedCompaniesDetails.affiliatedCompaniesDetails
                        ?.affiliatedCompaniesEmailAddress && (
                        <div className="col-md-6">
                          <div
                            className="contact-icon-box mb-50 wow fadeInDown"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInDown",
                            }}
                          >
                            <div className="icon">
                              <i className="rlr-icon-font flaticon-email"></i>
                            </div>
                            <div className="text">
                              <h4 className="title">{t("Email Us")}</h4>
                              <p>
                                <Link
                                  to={`mailto:${affiliatedCompaniesDetails.affiliatedCompaniesDetails?.affiliatedCompaniesEmailAddress}`}
                                >
                                  {
                                    affiliatedCompaniesDetails
                                      .affiliatedCompaniesDetails
                                      ?.affiliatedCompaniesEmailAddress
                                  }
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {affiliatedCompaniesDetails.affiliatedCompaniesDetails
                        ?.affiliatedCompaniesPhone && (
                        <div className="col-md-6">
                          <div
                            className="contact-icon-box mb-50 wow fadeInDown"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInDown",
                            }}
                          >
                            <div className="icon">
                              <i className="rlr-icon-font flaticon-telephone"></i>
                            </div>
                            <div className="text">
                              <h4 className="title">{t("Phone")}</h4>
                              <p>
                                <Link
                                  to={`tel:${affiliatedCompaniesDetails.affiliatedCompaniesDetails?.affiliatedCompaniesPhone}`}
                                >
                                  {
                                    affiliatedCompaniesDetails
                                      .affiliatedCompaniesDetails
                                      ?.affiliatedCompaniesPhone
                                  }
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {affiliatedCompaniesDetails.affiliatedCompaniesDetails
                        ?.affiliatedCompaniesWebsite && (
                        <div className="col-md-6">
                          <div
                            className="contact-icon-box mb-50 wow fadeInDown"
                            style={{
                              visibility: "visible",
                              animationName: "fadeInDown",
                            }}
                          >
                            <div className="icon">
                              <i className="rlr-icon-font flaticon-globe"></i>
                            </div>
                            <div className="text">
                              <h4 className="title">{t("Website")}</h4>
                              <p>
                                <Link
                                  to={
                                    affiliatedCompaniesDetails
                                      .affiliatedCompaniesDetails
                                      ?.affiliatedCompaniesWebsite
                                  }
                                  target="_blank"
                                >
                                  {
                                    affiliatedCompaniesDetails
                                      .affiliatedCompaniesDetails
                                      ?.affiliatedCompaniesWebsite
                                  }
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {affiliatedCompaniesDetails.affiliatedCompaniesDetails
                  ?.affiliatedCompaniesGoogleLocation && (
                  <div className="col-xl-12 col-lg-12 pb-50">
                    <div className="map-box">
                      <iframe
                        src={
                          affiliatedCompaniesDetails.affiliatedCompaniesDetails
                            ?.affiliatedCompaniesGoogleLocation
                        }
                        title={
                          affiliatedCompaniesDetails.affiliatedCompaniesDetails
                            ?.affiliatedCompaniesName
                        }
                      ></iframe>
                    </div>
                  </div>
                )}
                <div className="col-xl-12 col-lg-12">
                  <div className="contact-form-wrapper mb-50">
                    <h3 className="title">{t("Send Us Message")}</h3>
                    <p>
                      {t(
                        "Do you have anything your mind to tell? Please don't hesitate to in touch to us our contact form"
                      )}
                      .
                    </p>
                    <form onSubmit={handleSubmit}>
                      {/* Honey Pot fields */}
                      <label className="ohnohoney" htmlFor="namehoney"></label>
                      <input
                        tabIndex="-1"
                        className="ohnohoney"
                        autoComplete="off"
                        type="text"
                        id="namehoney"
                        name="namehoney"
                        placeholder="Your name here"
                        value={formData.namehoney} // Bind value directly if needed
                        onChange={handleInputChange} // Handle changes if needed
                      />
                      <label className="ohnohoney" htmlFor="emailhoney"></label>
                      <input
                        tabIndex="-1"
                        className="ohnohoney"
                        autoComplete="off"
                        type="email"
                        id="emailhoney"
                        name="emailhoney"
                        placeholder="Your e-mail here"
                        value={formData.emailhoney} // Bind value directly if needed
                        onChange={handleInputChange} // Handle changes if needed
                      />
                      <div className="form_group">
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("Full Name")}
                          name="customerName"
                          required
                          value={formData.customerName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form_group">
                        <input
                          type="email"
                          className="form_control"
                          placeholder={t("Email Address")}
                          name="customerEmail"
                          required
                          value={formData.customerEmail}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form_group">
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("Mobile")}
                          name="customerPhone"
                          required
                          value={formData.customerPhone}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form_group">
                        <textarea
                          className="form_control"
                          placeholder={t("Write Message")}
                          name="customermessage"
                          value={formData.customermessage}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <div
                        id="divcaptcha"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          margin: "20px 0 20px 0",
                        }}
                      >
                        <ReCAPTCHA
                          sitekey="6LdVcwgqAAAAAJzoiaHWcn_dmaNoCCn3_7ActZXJ" // Use the correct site key here
                          onChange={handleCaptchaChange}
                          hl={getRecaptchaLanguageCode()}
                        />
                      </div>
                      <div className="form_group">
                        <button className="main-btn btn-green" type="submit">
                          {loadingFormData ? (
                            <>
                              <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                              />{" "}
                              {t("Loading")} ...
                            </>
                          ) : (
                            t("GET IN TOUCH")
                          )}
                        </button>
                      </div>
                    </form>
                    {!loadingFormData && success && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {t("Your Message Sent Successfully To")}{" "}
                          {
                            affiliatedCompaniesDetails
                              .affiliatedCompaniesDetails
                              ?.affiliatedCompaniesEmailAddress
                          }
                        </Alert>
                      </Snackbar>
                    )}{" "}
                    {errorFormDate && (
                      <Snackbar
                        open={open}
                        autoHideDuration={4000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="danger"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {t(errorFormDate)}
                        </Alert>
                      </Snackbar>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar-widget-area pl-lg-40">
                <div className="sidebar-widget category-widget mb-30 wow fadeInUp">
                  <h5 className="widget-title">
                    {t("Travco Affiliated Companies")}{" "}
                  </h5>
                  <ul className="category-nav">
                    {affiliatedCompaniesDetails.otherAFCompanies?.map(
                      (item) => (
                        <li key={item.affiliatedCompaniesUrl}>
                          <Link
                            to={`/affiliated-companies/${item.affiliatedCompaniesUrl}`}
                          >
                            {item.affiliatedCompaniesName}
                            <i className="rlr-icon-font flaticon-chevron"></i>
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AffiliatedCompaniesDetails;
