import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

import { Link, useNavigate, useParams } from "react-router-dom";

import BookingStepForProducts from "../../components/booking-steps/BookingStepForProducts";
import { Airports_Options as countries } from "../../components/airports";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { VariableSizeList } from "react-window";
import { useTheme } from "@mui/material/styles";
import { postCstData } from "../../data/transfers/postCstDataSlice";
import { fetchBookingTransfer } from "../../data/transfers/bookingTransferSlice";
import { useTranslation } from "react-i18next";
import { ru, ar, pt, es } from "date-fns/locale";
SwiperCore.use([Navigation, Pagination, Autoplay]);
const BookingTransfer = () => {
  const { t, i18n } = useTranslation();
  const getLocale = () => {
    switch (i18n.language) {
      case "ru":
        return ru;
      case "pt":
        return pt;
      case "es":
        return es;
      case "ar":
        return ar;
      default:
        return undefined; // Default to English if no match
    }
  };
  const { productUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [startArrivalDate, setStartArrivalDate] = useState(new Date());
  const language = useSelector((state) => state.language.language);
  const currency = useSelector((state) => state.currency.currency);
  const { bookingTransfer } = useSelector((state) => state.bookingTransfer);
  const productId = localStorage.getItem("transfer_productId");

  const numberOfAdults = localStorage.getItem("transfer_adultNumber");
  const startDate = localStorage.getItem("transfer_startDate");

  useEffect(() => {
    dispatch(
      fetchBookingTransfer({
        productId,
        numberOfAdults,
        currency,
        startDate,
        language,
      })
    );
  }, [dispatch, numberOfAdults, currency, productId, startDate, language]);

  const LISTBOX_PADDING = 8; // px

  function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
      },
    });
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const itemCount = itemData.length;
    const itemSize = isMobile || isTablet ? "100%" : 36;
    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.length * itemSize;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            style={{
              width: `${isMobile || isTablet ? `100%` : `30vw`}`,
              position: "absolute",
              backgroundColor: "#FFF",
              boxShadow: "0 0 1px 2px #eee",
              borderRadius: "0 0 10px 10px",
            }}
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  const { profile } = useSelector((state) => state.profile);
  const [customerFirstName, setCustomerFirstName] = useState(
    profile.userInfo?.customerFirstName || ""
  );
  const [customerLastName, setCustomerLastName] = useState(
    profile.userInfo?.customerLastName || ""
  );
  const [customerEmailAddress, setCustomerEmailAddress] = useState(
    profile.userInfo?.customerEmailAddress || ""
  );
  const [customerMobile, setCustomerMobile] = useState(
    profile.userInfo?.customerMobile || ""
  );

  const [customerNationalityCode, setCustomerNationalityCode] = useState("");
  const [customerSpecialRequest, setCustomerSpecialRequest] = useState("");
  const [arrivalFlightNumber, setArrivalFlightNumber] = useState("");
  const [arrivalAirline, setArrivalAirline] = useState("");
  const [accommodationName, setAccommodationName] = useState("");
  const [languageGuide, setLanguageGuide] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [originName, setOriginName] = useState("Select");
  const handleCountryChange = (event, newValue) => {
    setOriginName(newValue);
  };
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const { cstDataForTransfer, loadingCstData, errorCstData } = useSelector(
    (state) => state.cstDataForTransfer
  );
  const UTM = localStorage.getItem("utm_params");
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      productId: bookingTransfer.productDetails?.productId,
      numberOfParticipant: bookingTransfer.priceModel?.numberOfParticipant,
      orderTripDate: startDate,
      customerFirstName,
      customerLastName,
      customerEmailAddress,
      customerMobile,
      accomdationHotelName: accommodationName,
      customerNationalityCode,
      productLanguageGuide: languageGuide,
      orderSpecialRequest: customerSpecialRequest,
      arrivalFlightNumber,
      arrivalAirline,
      arrivalDate: formatDate(startArrivalDate),
      arrivalTime,
      originName: originName?.label,
      currency,
      utm: UTM,
    };

    dispatch(postCstData({ cstData: formData, language }));

    // After successful submission, reset form fields
    setCustomerFirstName("");
    setCustomerLastName("");
    setCustomerEmailAddress("");
    setCustomerMobile("");
    setCustomerNationalityCode("");
    setCustomerSpecialRequest("");
    setArrivalFlightNumber("");
    setArrivalAirline("");
    setStartArrivalDate("");
    setArrivalTime("");
    setOriginName("");
  };
  useEffect(() => {
    if (
      !loadingCstData &&
      cstDataForTransfer.paymentUrl &&
      cstDataForTransfer.success
    ) {
      window.location.href = cstDataForTransfer.paymentUrl;
    }
  }, [
    cstDataForTransfer.paymentUrl,
    cstDataForTransfer.success,
    loadingCstData,
  ]);
  return (
    <>
      <Helmet>
        <title>{bookingTransfer.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/transfers/${productUrl}/bookingTransfer`}
        />

        <meta
          name="description"
          content={bookingTransfer.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={bookingTransfer.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={bookingTransfer.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={bookingTransfer.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={bookingTransfer.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">
          {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }
   

          `}
        </style>
        <link
          href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/login-booking/css/style.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/line-awesome-3.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? bookingTransfer.pageDetails?.pageBannerMobile
                          : isTablet
                          ? bookingTransfer.pageDetails?.pageBannerTablet
                          : bookingTransfer.pageDetails?.pageBannerPc
                      }
                      alt={bookingTransfer.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <BookingStepForProducts />

      <section className="mt-lg-5 pt-lg-5">
        {" "}
        <div className="rlr-section__content--md-top mt-5 pt-lg-5">
          <div className="rlr-checkout">
            <article
              id="post-7"
              className="post-7 page type-page status-publish hentry"
            >
              <div className="container-xxl">
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="woocommerce-notices-wrapper"></div>
                    <form
                      className="checkout woocommerce-checkout d-flex justify-content-between flex-column-reverse flex-lg-row"
                      onSubmit={handleSubmit}
                    >
                      <div className="col2-set">
                        <div className="col-1">
                          <div className="woocommerce-billing-fields">
                            <div className="woocommerce-billing-fields__field-wrapper">
                              <h6 className="checkout__section-title">
                                <span> {t("Information")} </span>
                              </h6>
                              <div className="row checkout__personal-details">
                                <div className="form-row form-row-last   col-lg-6">
                                  <label htmlFor="country-select-demo">
                                    {" "}
                                    {t("Origin")}{" "}
                                  </label>
                                  <Autocomplete
                                    required
                                    onChange={handleCountryChange}
                                    id="country-select-demo"
                                    className="col-lg-12"
                                    options={countries}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": {
                                            mr: 2,
                                            flexShrink: 0,
                                          },
                                        }}
                                        {...props}
                                      >
                                        {option.label}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        required
                                        {...params}
                                        label="Select"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                    ListboxComponent={ListboxComponent}
                                  />
                                </div>
                                <div className="form-row form-row-last  ">
                                  <label htmlFor="airline" className="">
                                    {" "}
                                    {t("Airline")}{" "}
                                  </label>
                                  <select
                                    id="airline"
                                    className="form-control border-1"
                                    onChange={(e) =>
                                      setArrivalAirline(e.target.value)
                                    }
                                    value={arrivalAirline}
                                    required
                                  >
                                    <option hidden value="">
                                      {t("Select")}
                                    </option>
                                    {bookingTransfer.airlines?.map((item) => (
                                      <option
                                        value={item.airlineId}
                                        key={item.airlineId}
                                      >
                                        {item.airlineName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="form-row form-row-wide ">
                                  <label
                                    htmlFor="arrivalFlightNumber"
                                    className=""
                                  >
                                    {" "}
                                    {t("Arrival Flight No")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <input
                                      id="arrivalFlightNumber"
                                      name="ArrivalFlightNumber"
                                      type="text"
                                      className="form-control border-1"
                                      value={arrivalFlightNumber}
                                      onChange={(e) =>
                                        setArrivalFlightNumber(e.target.value)
                                      }
                                      required
                                    />
                                  </span>
                                </div>
                                <div
                                  className="form-row form-row-wide   validate-email"
                                  id="billing_email_field"
                                  data-priority="110"
                                >
                                  <label htmlFor="billing_email" className="">
                                    {" "}
                                    {t("Arrival Date")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <div
                                      id="datepickerWrapperFromThree"
                                      className="u-datepicker input-group"
                                    >
                                      <DatePicker
                                        selected={startArrivalDate}
                                        onChange={(date) =>
                                          setStartArrivalDate(date)
                                        }
                                        className="ps-4 border-1 form-control"
                                        name="DateValues"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        required
                                        locale={getLocale()}
                                      />{" "}
                                      <div className="input-group-prepend">
                                        <span className="d-flex align-items-center mr-2">
                                          <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                                <div className="form-row form-row-wide   validate-email">
                                  <label htmlFor="ArrivalTime" className="">
                                    {" "}
                                    {t("Arrival Time")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <input
                                      type="time"
                                      className="form-control border-1"
                                      placeholder="Arrival_Time"
                                      onChange={(e) =>
                                        setArrivalTime(e.target.value)
                                      }
                                      value={arrivalTime}
                                      required
                                      name="ArrivalTime"
                                      id="ArrivalTime"
                                    />
                                  </span>
                                </div>
                              </div>

                              <h6 className="checkout__section-title">
                                <span> {t("Personal Information")} </span>
                              </h6>
                              <div className="checkout__personal-details">
                                <div
                                  className="form-row form-row-first  "
                                  id="billing_first_name_field"
                                  data-priority="10"
                                >
                                  <label
                                    htmlFor="billing_first_name"
                                    className=""
                                  >
                                    {" "}
                                    {t("First Name")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <input
                                      type="text"
                                      className="form-control border-1"
                                      name="CustomerFirstName"
                                      id="billing_first_name"
                                      required
                                      onChange={(e) =>
                                        setCustomerFirstName(e.target.value)
                                      }
                                      value={customerFirstName}
                                    />
                                  </span>
                                </div>
                                <div
                                  className="form-row form-row-last  "
                                  id="billing_last_name_field"
                                  data-priority="20"
                                >
                                  <label
                                    htmlFor="billing_last_name"
                                    className=""
                                  >
                                    {" "}
                                    {t("Last Name")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <input
                                      type="text"
                                      className="form-control border-1"
                                      name="CustomerLastName"
                                      id="billing_last_name"
                                      onChange={(e) =>
                                        setCustomerLastName(e.target.value)
                                      }
                                      value={customerLastName}
                                      required
                                    />
                                  </span>
                                </div>
                                <div
                                  className="form-row form-row-wide   validate-phone"
                                  id="billing_phone_field"
                                  data-priority="100"
                                >
                                  <label htmlFor="billing_phone" className="">
                                    {" "}
                                    {t("Phone")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <input
                                      type="tel"
                                      className="form-control border-1"
                                      name="CustomerMobile"
                                      id="billing_phone"
                                      onChange={(e) =>
                                        setCustomerMobile(e.target.value)
                                      }
                                      value={customerMobile}
                                      required
                                    />
                                  </span>
                                </div>
                                <div
                                  className="form-row form-row-wide   validate-email"
                                  id="billing_email_field"
                                  data-priority="110"
                                >
                                  <label htmlFor="billing_email" className="">
                                    {" "}
                                    {t("Email")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <input
                                      type="email"
                                      className="form-control border-1"
                                      name="CustomerEmailAddress"
                                      id="billing_email"
                                      onChange={(e) =>
                                        setCustomerEmailAddress(e.target.value)
                                      }
                                      value={customerEmailAddress}
                                      required
                                    />
                                  </span>
                                </div>
                                <div
                                  className="form-row form-row-wide   validate-email"
                                  id="billing_email_field"
                                  data-priority="110"
                                >
                                  <label htmlFor="billing_email" className="">
                                    {" "}
                                    {t("Accommodation Hotel Name")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <input
                                      type="text"
                                      className="form-control border-1"
                                      name="CustomerEmailAddress"
                                      id="billing_email"
                                      onChange={(e) =>
                                        setAccommodationName(e.target.value)
                                      }
                                      value={accommodationName}
                                      required
                                    />
                                  </span>
                                </div>
                                <div className="form-row form-row-wide address-field update_totals_on_change  ">
                                  <label htmlFor="nationality">
                                    {" "}
                                    {t("Customer Nationality")}{" "}
                                  </label>
                                  <div className="woocommerce-input-wrapper">
                                    <select
                                      name="CustomerNationality"
                                      id="nationality"
                                      required
                                      className="form-control border-1"
                                      onChange={(e) =>
                                        setCustomerNationalityCode(
                                          e.target.value
                                        )
                                      }
                                      value={customerNationalityCode}
                                    >
                                      <option value="" hidden>
                                        {t("Select country")}
                                      </option>
                                      {bookingTransfer.nationalities?.map(
                                        (nationality) => (
                                          <option
                                            value={nationality.nationalityId}
                                            key={nationality.nationalityId}
                                          >
                                            {nationality.nationalityName}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>

                                <div className="form-row form-row-last  ">
                                  <label htmlFor="airline" className="">
                                    {" "}
                                    {t("Guide Language")}
                                  </label>
                                  <select
                                    id="airline"
                                    className="form-control border-1"
                                    onChange={(e) =>
                                      setLanguageGuide(e.target.value)
                                    }
                                    value={languageGuide}
                                    required
                                  >
                                    <option hidden value="">
                                      {t("Select")}
                                    </option>
                                    {bookingTransfer.languageGuides?.map(
                                      (item) => (
                                        <option
                                          value={item.languageSpokenId}
                                          key={item.languageSpokenId}
                                        >
                                          {item.languageSpokenName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>

                                <div
                                  className="form-row address-field street-address-1   form-row-wide"
                                  id="billing_address_1_field"
                                  data-priority="50"
                                >
                                  <label
                                    htmlFor="billing_address_1"
                                    className=""
                                  >
                                    {" "}
                                    {t("Special Requirements")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <textarea
                                      className="form-control border-1 pt-3"
                                      rows="4"
                                      id="billing_SpeciaOrder"
                                      name="OrderSpecialRequest"
                                      onChange={(e) =>
                                        setCustomerSpecialRequest(
                                          e.target.value
                                        )
                                      }
                                      value={customerSpecialRequest}
                                      style={{
                                        marginTop: "10px",
                                        height: "235px",
                                      }}
                                    ></textarea>
                                    <button
                                      type="submit"
                                      className="button alt mt-5"
                                      name="woocommerce_checkout_place_order"
                                      id="place_order"
                                      value="Place order"
                                      data-value="Place order"
                                    >
                                      {loadingCstData
                                        ? t("Loading")
                                        : t("Place Order")}
                                    </button>
                                  </span>
                                  <p className="text-danger text-center mt-3 fw-bold">
                                    {errorCstData && errorCstData}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="order_review"
                        className="woocommerce-checkout-review-order mb-5 mb-lg-0"
                      >
                        <table className="shop_table woocommerce-checkout-review-order-table">
                          <tbody>
                            <tr className="cart_item">
                              <td className="product-name">
                                <h6 className="cart-item-title">
                                  {" "}
                                  {t("Your Order")}
                                </h6>
                                <ul className="cart-item-card">
                                  <li className="cart-item-card__header">
                                    <div>
                                      <img
                                        className="cart-item-card__img"
                                        src={
                                          bookingTransfer.productDetails
                                            ?.productPhoto
                                        }
                                        alt={
                                          bookingTransfer.productDetails
                                            ?.productName
                                        }
                                      />
                                    </div>
                                    <div>
                                      <p className="cart-item-card__title">
                                        {
                                          bookingTransfer.productDetails
                                            ?.productName
                                        }
                                      </p>
                                      <p className="cart-item-card__date">
                                        <span> {t("Date")} </span>
                                        <span>
                                          {" "}
                                          {
                                            bookingTransfer.priceModel
                                              ?.dateValues
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="order-total">
                              <th>{t("Total payment")}</th>
                              <td>
                                <strong>
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol"></span>
                                      {
                                        bookingTransfer.priceModel
                                          ?.priceCurrency
                                      }{" "}
                                      {bookingTransfer.priceModel?.totalPrice}
                                    </bdi>
                                  </span>
                                </strong>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingTransfer;
