import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { fetchBrochures } from "../../data/brochures/brochuresSlice";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const Brochures = () => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { brochures, loading, error } = useSelector((state) => state.brochures);
  useEffect(() => {
    dispatch(fetchBrochures(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>{brochures.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/brochures`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={brochures.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={brochures.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={brochures.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={brochures.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={brochures.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? brochures.pageDetails?.pageBannerMobile
                          : isTablet
                          ? brochures.pageDetails?.pageBannerTablet
                          : brochures.pageDetails?.pageBannerPc
                      }
                      alt={brochures.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {brochures.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {brochures.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section>
        <div className="container-xxl">
          <article className="rlr-article rlr-article-single--v2">
            <div className="rlr-article__wrapper" style={{ maxWidth: "100%" }}>
              <div className="content">
                <div className="row d-flex justify-content-center align-items-center">
                  {brochures.brochures?.map((brochure, index) => (
                    <div className="col-12 col-lg-4  mb-5 px-3">
                      <div
                        key={index}
                        style={{ boxShadow: "0 0 5px 5px #efe8e8" }}
                      >
                        <img
                          alt={brochure.brochuresName}
                          src={brochure.brochuresPhoto}
                          className="img-fluid"
                          loading="lazy"
                        />
                        <a
                          target="_blank"
                          href={brochure.brochuresFile}
                          style={{ cursor: "pointer" }}
                          className="btn btn-primary w-100 text-light d-flex justify-content-center align-items-center"
                        >
                          <i className="rlr-icon-font flaticon-carbon-download pr-1"></i>
                          {t("Download")}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default Brochures;
