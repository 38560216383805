import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { fetchBeOurPartner } from "../../data/be-our-partner/beOurPartnerSlice";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const BeOurPartner = () => {
  const { t, i18n } = useTranslation();
  const getRecaptchaLanguageCode = () => {
    switch (i18n.language) {
      case "es":
        return "es";
      case "ru":
        return "ru";
      case "pt":
        return "pt";
      case "ar":
        return "ar";
      default:
        return "en"; // Default to English if no match
    }
  };
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { beOurPartner, loading, error } = useSelector(
    (state) => state.beOurPartner
  );
  const agencyNameInputRef = useRef(null);
  useEffect(() => {
    if (agencyNameInputRef.current) {
      agencyNameInputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    dispatch(fetchBeOurPartner(language));
  }, [dispatch, language]);
  const [formData, setFormData] = useState({
    agencyName: "",
    corporateName: "",
    registrationNo: "",
    contactPerson: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    stateProvince: "",
    phone: "",
    phone2: "",
    mobileNumber: "",
    fax: "",
    webPage: "",
    remarks: "",
    countryID: "",
    namehoney: "", // Clear honey pot fields after submission
    emailhoney: "",
  });

  const [captchaValue, setCaptchaValue] = useState(null);
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
      setErrorFormDate(t("Please complete the reCAPTCHA"));
      setOpen(true);
      return;
    }
    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);

    try {
      const response = await fetch(
        "https://api.travco.com/BeOurPartnerSubmit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorFormDate(error.message || "An unexpected error occurred.");
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        agencyName: "",
        corporateName: "",
        registrationNo: "",
        contactPerson: "",
        email: "",
        address: "",
        city: "",
        zipCode: "",
        stateProvince: "",
        phone: "",
        phone2: "",
        mobileNumber: "",
        fax: "",
        webPage: "",
        remarks: "",
        namehoney: "", // Clear honey pot fields after submission
        emailhoney: "",
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>{beOurPartner.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/be-our-partner`}
        />

        <meta
          name="description"
          content={beOurPartner.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={beOurPartner.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={beOurPartner.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={beOurPartner.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={beOurPartner.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? beOurPartner.pageDetails?.pageBannerMobile
                          : isTablet
                          ? beOurPartner.pageDetails?.pageBannerTablet
                          : beOurPartner.pageDetails?.pageBannerPc
                      }
                      alt={beOurPartner.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {beOurPartner.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {beOurPartner.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section>
        {" "}
        <div className="rlr-section__content--md-top my-5 py-5">
          <div className="rlr-section">
            <div className="rlr-checkout">
              <article
                id="post-7"
                className="post-7 page type-page status-publish hentry"
              >
                <div className="container-xxl">
                  <div className="entry-content">
                    <div className="woocommerce">
                      <form
                        name="checkout"
                        className="checkout woocommerce-checkout my-lg-5"
                        onSubmit={handleFormSubmit}
                      >
                        {/* Honey Pot fields */}
                        <label className="ohnohoney" htmlFor="name"></label>
                        <input
                          tabIndex="-1"
                          className="ohnohoney"
                          autoComplete="off"
                          type="text"
                          id="namehoney"
                          name="namehoney"
                          placeholder="Your name here"
                          value={formData.namehoney}
                          onChange={handleFormChange}
                        />
                        <label
                          className="ohnohoney"
                          htmlFor="emailhoney"
                        ></label>
                        <input
                          tabIndex="-1"
                          className="ohnohoney"
                          autoComplete="off"
                          type="email"
                          id="emailhoney"
                          name="emailhoney"
                          placeholder="Your e-mail here"
                          value={formData.emailhoney}
                          onChange={handleFormChange}
                        />
                        <div
                          className="col2-set"
                          style={{ margin: "auto" }}
                          id="customer_details2"
                        >
                          <div className="col-1">
                            <div className="woocommerce-billing-fields">
                              <div className="woocommerce-billing-fields__field-wrapper">
                                <h6 className="checkout__section-title">
                                  <span> {t("General Information")} </span>
                                </h6>
                                <div className="checkout__personal-details">
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    data-priority="100"
                                  >
                                    <label htmlFor="AgencyName">
                                      {" "}
                                      {t("Agency Name")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        id="AgencyName"
                                        name="agencyName"
                                        value={formData.agencyName}
                                        onChange={handleFormChange}
                                        required
                                        ref={agencyNameInputRef}
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    data-priority="100"
                                  >
                                    <label htmlFor="CorporateName">
                                      {" "}
                                      {t("Corporate Name")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        id="CorporateName"
                                        name="corporateName"
                                        type="text"
                                        className="input-text"
                                        value={formData.corporateName}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    data-priority="100"
                                  >
                                    <label htmlFor="RegistrationNo">
                                      {" "}
                                      {t("Registration No")}. *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        id="RegistrationNo"
                                        name="registrationNo"
                                        type="text"
                                        className="input-text"
                                        value={formData.registrationNo}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                </div>
                                <h6 className="checkout__section-title">
                                  <span> {t("Contact Information")} </span>
                                </h6>
                                <div className="checkout__personal-details">
                                  <p
                                    className="form-row form-row-first validate-required"
                                    data-priority="10"
                                  >
                                    <label htmlFor="ContactPerson">
                                      {" "}
                                      {t("Contact Person")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        id="ContactPerson"
                                        name="contactPerson"
                                        value={formData.contactPerson}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-last validate-required"
                                    id="billing_last_name_field"
                                    data-priority="20"
                                  >
                                    <label htmlFor="email-address">
                                      {" "}
                                      {t("E-Mail")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="email"
                                        className="input-text"
                                        name="email"
                                        id="email-address"
                                        value={formData.email}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    data-priority="100"
                                  >
                                    <label htmlFor="Address">
                                      {" "}
                                      {t("Address")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="address"
                                        id="Address"
                                        value={formData.address}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    id="City_field"
                                    data-priority="100"
                                  >
                                    <label htmlFor="City">
                                      {" "}
                                      {t("City")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="city"
                                        id="City"
                                        value={formData.city}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    id="ZipCode_field"
                                    data-priority="100"
                                  >
                                    <label htmlFor="ZipCode">
                                      {" "}
                                      {t("Zip Code")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="zipCode"
                                        id="ZipCode"
                                        value={formData.zipCode}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide validate-required validate-phone">
                                    <label htmlFor="StateProvince">
                                      {" "}
                                      {t("State / Province")}{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="stateProvince"
                                        id="StateProvince"
                                        value={formData.stateProvince}
                                        onChange={handleFormChange}
                                      />
                                    </span>
                                  </p>
                                  <div className="form-row form-row-wide address-field update_totals_on_change validate-required">
                                    <label htmlFor="CountryID">
                                      {" "}
                                      {t("Country")} *{" "}
                                    </label>
                                    <div className="woocommerce-input-wrapper">
                                      <select
                                        id="CountryID"
                                        name="countryID"
                                        value={formData.countryID}
                                        onChange={handleFormChange}
                                        required
                                        className="form-control"
                                        style={{ border: "1px solid #dbe4eb" }}
                                      >
                                        <option hidden>
                                          {t("Select country")}
                                        </option>
                                        {beOurPartner.countries?.map(
                                          (country) => (
                                            <option
                                              key={country.countryId}
                                              value={country.countryId}
                                            >
                                              {country.countryName}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <p className="form-row form-row-wide validate-required validate-phone">
                                    <label htmlFor="Phone">
                                      {" "}
                                      {t("Phone")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="phone"
                                        id="Phone"
                                        value={formData.phone}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide validate-required validate-phone">
                                    <label htmlFor="Phone2">
                                      {" "}
                                      {t("Phone")} 2{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="phone2"
                                        id="Phone2"
                                        value={formData.phone2}
                                        onChange={handleFormChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide validate-required validate-phone">
                                    <label htmlFor="MobileNumber">
                                      {" "}
                                      {t("Mobile Number")}{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="mobileNumber"
                                        id="MobileNumber"
                                        value={formData.mobileNumber}
                                        onChange={handleFormChange}
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    id="Fax_field"
                                    data-priority="100"
                                  >
                                    <label htmlFor="Fax"> {t("Fax")} </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="fax"
                                        id="Fax"
                                        value={formData.fax}
                                        onChange={handleFormChange}
                                      />
                                    </span>
                                  </p>
                                  <p className="form-row form-row-wide validate-required validate-phone">
                                    <label htmlFor="WebPage">
                                      {" "}
                                      {t("Web Page")} *{" "}
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text"
                                        name="webPage"
                                        id="WebPage"
                                        value={formData.webPage}
                                        onChange={handleFormChange}
                                        required
                                      />
                                    </span>
                                  </p>{" "}
                                </div>
                                <h6 class="checkout__section-title">
                                  <span> {t("Additional Information")} </span>
                                </h6>

                                <p className="form-row row form-row-wide validate-required  ">
                                  <label htmlFor="Remarks">
                                    {t("Remarks")}{" "}
                                  </label>
                                  <span className="woocommerce-input-wrapper">
                                    <textarea
                                      className="form-control"
                                      style={{
                                        marginTop: "10px",
                                        height: "235px",
                                      }}
                                      name="remarks"
                                      id="Remarks"
                                      value={formData.remarks}
                                      onChange={handleFormChange}
                                    />
                                  </span>
                                </p>

                                <div
                                  className="form-row form-row-wide mx-auto my-5 py-lg-2 d-flex justify-content-center w-50"
                                  id="divcaptcha"
                                >
                                  <ReCAPTCHA
                                    className="mx-auto text-center"
                                    sitekey="6LdVcwgqAAAAAJzoiaHWcn_dmaNoCCn3_7ActZXJ"
                                    onChange={handleCaptchaChange}
                                    hl={getRecaptchaLanguageCode()}
                                  />
                                </div>

                                {/* {errorMessage && (
                                  <div className="error-message">
                                    {errorMessage}
                                  </div>
                                )}
                                {successMessage && (
                                  <div className="success-message">
                                    {successMessage}
                                  </div>
                                )} */}
                                <div className="form-row form-row-wide">
                                  <button type="submit" className="button alt">
                                    {loadingFormData ? (
                                      <>
                                        <Spinner
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                        />{" "}
                                        {t("Loading")} ...
                                      </>
                                    ) : (
                                      t("Submit")
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      {!loadingFormData && success && (
                        <Snackbar
                          open={open}
                          autoHideDuration={6000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {t("Thank You , Your Message Sent Successfully")}!{" "}
                          </Alert>
                        </Snackbar>
                      )}{" "}
                      {errorFormDate && (
                        <Snackbar
                          open={open}
                          autoHideDuration={4000}
                          onClose={handleClose}
                        >
                          <Alert
                            onClose={handleClose}
                            severity="danger"
                            variant="filled"
                            sx={{ width: "100%" }}
                          >
                            {t(errorFormDate)}
                          </Alert>
                        </Snackbar>
                      )}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BeOurPartner;
