import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../data/login/loginSlice";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { register } from "../data/sign-up/signUpSlice";
import { useTranslation } from "react-i18next";
const LoginAndRegisterForm = ({ setShow }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("login");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [registerFirstName, setRegisterFirstName] = useState("");
  const [registerLastName, setRegisterLastName] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
  const openCity = (event, tabName) => {
    setActiveTab(tabName);
  };

  /*_____________________________________________________________________________________ */
  // Login

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector((store) => store.login.error);
  const message = useSelector((store) => store.login.message);
  const accessToken = useSelector((state) => state.login.token);
  const loading = useSelector((state) => state.login.loading);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(login(values)).then((response) => {
        if (response.payload && response.payload.error) {
          console.log("Login failed:", response.payload.error);
        } else if (response.payload && response.payload.accessToken) {
          localStorage.setItem("userLoginToken", response.payload.accessToken);
          localStorage.setItem("firstName", values.email);
          localStorage.setItem("lastName", "");
          setShow(true);
        }
      });
    },
  });

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      setShow(true);
    }
  }, [accessToken, navigate, setShow]);
  /*_____________________________________________________________________________________ */
  // Register
  const accessTokenRegister = useSelector((store) => store.register.token);
  const messageRegister = useSelector((store) => store.register.message);
  const loadingRegister = useSelector((store) => store.register.loading);
  const [passwordType, setPasswordType] = useState("password");
  const [repasswordType, setRepasswordType] = useState("password");
  const [rePass, setRePass] = useState("");
  //   useEffect(() => {
  //     if (accessToken) {
  //       localStorage.setItem("userToken", accessToken);
  //       setShow(true);
  //     }
  //   }, [accessToken, navigate, setShow]);
  function submitRegister(values) {
    dispatch(register(values)).then((response) => {
      // Check if registration was successful and handle accordingly
      if (response.payload.accessToken) {
        // Store user token locally
        localStorage.setItem("accessToken", response.payload.accessToken);
        // Optionally store first name and last name if needed
        localStorage.setItem("firstName", values.firstName);
        localStorage.setItem("lastName", values.lastName);
        localStorage.setItem("email", values.email);
        // Redirect to home page
        setShow(true);
      }
    });
  }
  const validationSchemaRegister = Yup.object({
    firstName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(7, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const formikRegister = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchemaRegister,
    onSubmit: submitRegister,
  });
  return (
    <section className="payment-area pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-box payment-received-wrap mb-0">
              <div className="page-content" id="LoginModule">
                <div className="form-v8-content" style={{ width: "50%" }}>
                  <div className="form-right">
                    <div className="tab">
                      <div className="tab-inner">
                        <button
                          className={`tablinks ${
                            activeTab === "login" ? "active" : ""
                          }`}
                          onClick={(e) => openCity(e, "login")}
                          id="defaultOpen"
                        >
                          {t("Login")}
                        </button>
                      </div>
                      <div className="tab-inner">
                        <button
                          className={`tablinks ${
                            activeTab === "register" ? "active" : ""
                          }`}
                          onClick={(e) => openCity(e, "register")}
                        >
                          {t("Register")}
                        </button>
                      </div>
                    </div>

                    {/* Login Form */}
                    {activeTab === "login" && (
                      <form onSubmit={formik.handleSubmit}>
                        <div className="form-detail">
                          <div className="tabcontent" id="login">
                            <div className="mx-auto mb-5">
                              <span
                                style={{
                                  color: "#737171",
                                  textAlign: "center",
                                  display: "block",
                                }}
                              >
                                {t("Identification and registration")}
                              </span>
                              <small
                                style={{
                                  color: "#737171",
                                  textAlign: "center",
                                  display: "block",
                                  marginTop: 0,
                                }}
                              >
                                {t("Login to book quickly")} .
                              </small>
                            </div>

                            <div className="form-row">
                              <label className="form-row-inner">
                                <span className="label">{t("E-Mail")}</span>
                              </label>
                              <input
                                type="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                autoComplete="off"
                                name="email"
                                id="email"
                                className="form-control border-1 text-dark pt-3 mb-0 "
                                style={{
                                  height: "45px",
                                  marginBottom: 30,
                                }}
                                required
                              />

                              {formik.touched.email && formik.errors.email ? (
                                <p className="help-text text-danger">
                                  {t(formik.errors.email)}
                                </p>
                              ) : null}
                            </div>
                            <div className="form-row mt-3">
                              <label className="form-row-inner">
                                {" "}
                                <span className="label">
                                  {t("Password")}
                                </span>{" "}
                              </label>
                              <input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control text-dark pt-3	border-1 mb-0"
                                style={{
                                  height: "45px",
                                  marginBottom: 30,
                                }}
                                required
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                autoComplete="off"
                              />

                              {formik.touched.password &&
                              formik.errors.password ? (
                                <p className="help-text text-danger mt-0">
                                  {t(formik.errors.password)}
                                </p>
                              ) : null}
                            </div>

                            <div
                              className="form-row-last mt-3"
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                textAlign: "center",
                              }}
                            >
                              <input
                                type="submit"
                                name="register"
                                className="register"
                                value={t("Login")}
                              />
                              {error && (
                                <p className="help-text text-danger mt-0">
                                  {t(error)}
                                </p>
                              )}

                              <Link
                                style={{
                                  display: "block",
                                  color: "#000",
                                  textDecoration: "underline",
                                }}
                                to="#."
                                onClick={() => setShow(true)}
                              >
                                {t("Continue as a Guest")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}

                    {/* Register Form */}
                    {activeTab === "register" && (
                      <form onSubmit={formikRegister.handleSubmit}>
                        <div className="form-detail">
                          <div className="tabcontent" id="register">
                            <div className="form-row w-45">
                              <label className="form-row-inner">
                                {" "}
                                <span className="label">
                                  {t("First Name")}{" "}
                                </span>
                              </label>
                              <input
                                className="form-control border-1 pt-3 text-dark mb-0"
                                style={{
                                  height: "45px",
                                  marginBottom: 30,
                                }}
                                type="text"
                                name="firstName"
                                id="firstName"
                                autoComplete="off"
                                required
                                onBlur={formikRegister.handleBlur}
                                onChange={formikRegister.handleChange}
                                value={formikRegister.values.firstName}
                              />
                              {formikRegister.touched.firstName &&
                              formikRegister.errors.firstName ? (
                                <p className="help-text text-danger">
                                  {t(formikRegister.errors.firstName)}
                                </p>
                              ) : null}
                            </div>
                            <div className="form-row w-45">
                              <label className="form-row-inner">
                                <span className="label">{t("Last Name")}</span>
                              </label>
                              <input
                                className="form-control border-1 pt-3 text-dark mb-0"
                                type="text"
                                name="lastName"
                                id="lastName"
                                autoComplete="off"
                                required
                                onBlur={formikRegister.handleBlur}
                                onChange={formikRegister.handleChange}
                                value={formikRegister.values.lastName}
                              />
                              {formikRegister.touched.lastName &&
                              formikRegister.errors.lastName ? (
                                <p className="help-text text-danger">
                                  {t(formikRegister.errors.lastName)}
                                </p>
                              ) : null}
                            </div>
                            <div className="form-row mt-3">
                              <label className="form-row-inner">
                                <span className="label">{t("E-Mail")} </span>
                              </label>
                              <input
                                className="form-control border-1 pt-3 text-dark mb-0"
                                type="email"
                                name="email"
                                id="emailRegister"
                                autoComplete="off"
                                required
                                onBlur={formikRegister.handleBlur}
                                onChange={formikRegister.handleChange}
                                value={formikRegister.values.email}
                              />{" "}
                              {formikRegister.touched.email &&
                              formikRegister.errors.email ? (
                                <p className="help-text text-danger">
                                  {t(formikRegister.errors.email)}
                                </p>
                              ) : null}
                            </div>
                            <div className="form-row w-45 mt-5">
                              <label className="form-row-inner">
                                {" "}
                                <span className="label">{t("Password")} </span>
                              </label>
                              <div className="d-flex align-items-center">
                                <input
                                  type={passwordType}
                                  name="password"
                                  autoComplete="off"
                                  required
                                  minLength="8"
                                  className="form-control border-1 pt-3 text-dark mb-0"
                                  onBlur={formikRegister.handleBlur}
                                  onChange={formikRegister.handleChange}
                                  value={formikRegister.values.password}
                                />
                                <i
                                  className="fa fa-eye"
                                  id="togglePassword"
                                  onClick={() =>
                                    passwordType === "password"
                                      ? setPasswordType("text")
                                      : setPasswordType("password")
                                  }
                                  style={{
                                    marginLeft: "-30px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                              <p className="help-text text-danger">
                                {formikRegister.touched.email &&
                                  formikRegister.errors.email &&
                                  t(formikRegister.errors.password)}
                              </p>
                            </div>
                            <div className="form-row w-45 mt-5">
                              <label className="form-row-inner">
                                <span className="label">
                                  {t("Confirm Password")}{" "}
                                </span>
                              </label>{" "}
                              <div className="d-flex align-items-center">
                                <input
                                  className="form-control border-1 pt-3 text-dark mb-0"
                                  type={repasswordType}
                                  id="confirmpassword"
                                  required
                                  autoComplete="off"
                                  value={rePass}
                                  onChange={(e) => setRePass(e.target.value)}
                                  minLength="8"
                                />
                                <i
                                  className="fa fa-eye"
                                  id="toggleconfirmpassword"
                                  onClick={() =>
                                    repasswordType === "password"
                                      ? setRepasswordType("text")
                                      : setRepasswordType("password")
                                  }
                                  style={{
                                    marginLeft: "-30px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>{" "}
                              {rePass !== formikRegister.values.password && (
                                <p
                                  className="text-danger "
                                  hidden=""
                                  id="notMatch"
                                >
                                  {t("Passwords do not match")}!
                                </p>
                              )}
                            </div>
                            <div
                              className="form-row-last mt-3"
                              style={{ textAlign: "center", margin: "auto" }}
                            >
                              {messageRegister !== null && (
                                <p className="help-text text-danger text-center fw-bold">
                                  {t(messageRegister)}
                                </p>
                              )}
                              <input
                                type="submit"
                                name="register"
                                className="register"
                                value={t("Sign Up")}
                              />
                              <Link
                                style={{
                                  display: "block",
                                  color: "#000",
                                  textDecoration: "underline",
                                }}
                                to="#."
                                onClick={() => setShow(true)}
                              >
                                {t("Continue as a Guest")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginAndRegisterForm;
