import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { fetchOrderVisaDetails } from "../../data/visa/orderVisaDetailsSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

const ThanksVisa = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const { orderVisaDetails, loading, error } = useSelector(
    (state) => state.orderVisaDetails
  );
  useEffect(() => {
    const queryParams = window.location.search;

    if (queryParams) {
      localStorage.removeItem("utm_params");
    }
  }, []);
  useEffect(() => {
    dispatch(fetchOrderVisaDetails({ orderId, language }));
  }, [orderId, dispatch, language]);
  useEffect(() => {
    // Add Google Tag Manager script to the head
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-RS6ZVQS9YL";
    document.head.appendChild(script1);

    // Add the gtag initialization script
    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-RS6ZVQS9YL', { 'debug_mode': true });
    `;
    document.head.appendChild(script2);

    // Add the purchase event script
    const script3 = document.createElement("script");
    script3.innerHTML = `
      gtag('event', 'purchase', {
        'transaction_id': '${orderVisaDetails.gtm?.transaction_id}',  
                    'affiliation': '${orderVisaDetails.gtm?.affiliation}',  
                    'value':  ${orderVisaDetails.gtm?.value},  
                    'currency': '${orderVisaDetails.gtm?.currency}',  
                    'tax': ${orderVisaDetails.gtm?.tax}, 
                    'shipping':  ${orderVisaDetails.gtm?.shipping},  
                    'items': [
                        {
                            'item_id': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_id
                            )}',  
                            'item_name':' ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_name
                            )}',  
                            'item_brand': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_brand
                            )}', 
                            'item_category':'${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_category
                            )}',  
                            'item_variant': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_variant
                            )}',  
                            'affiliation': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.affiliation
                            )}', 
                            'discount': ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.discount
                            )}, 
                            'quantity': ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.quantity
                            )},  
                            'price': ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.price
                            )}  
                        }
                    ]
      });
    `;
    document.body.appendChild(script3);

    // Cleanup the scripts when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
      document.body.removeChild(script3);
    };
  }, [loading]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#e60000", padding: "30vh 0" }}
          className="d-flex flex-column justify-content-center align-items-center  mx-auto"
        >
          <h1 className="text-center text-light">{error}</h1>
          <Link to="/" className="btn btn-light text-center  mt-4 text-travco">
            {t("Back To Home")}
          </Link>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{orderVisaDetails.pageDetails?.pageMetatagTitle}</title>

            <link
              rel="canonical"
              href={`https://travco.com/${language}/Visaconfirmation/${orderId}`}
            />
            <meta
              name="description"
              content={orderVisaDetails.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={orderVisaDetails.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={orderVisaDetails.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={orderVisaDetails.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={orderVisaDetails.pageDetails?.metatagDescriptionTwitter}
            />
            <style type="text/css">
              {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
            </style>
            <link
              href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
              rel="stylesheet"
            />
            <link
              href="TITSolutions/WS/assets/login-booking/css/style.css"
              rel="stylesheet"
            />
            <link
              href="TITSolutions/WS/assets/line-awesome-3.css"
              rel="stylesheet"
            />
            {/* <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-RS6ZVQS9YLxxxxxxxxxxxxxxxxxxxxxxxxxx"
            ></script>
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'G-RS6ZVQS9YLxxxxxxxxxxxxxxxxxxxxxxxxxx');

            window.addEventListener('load', function () {
                gtag('event', 'purchase', {
                    'transaction_id': '${
                      orderVisaDetails.gtm?.transaction_id
                    }',  
                    'affiliation': '${orderVisaDetails.gtm?.affiliation}',  
                    'value':  ${orderVisaDetails.gtm?.value},  
                    'currency': '${orderVisaDetails.gtm?.currency}',  
                    'tax': ${orderVisaDetails.gtm?.tax}, 
                    'shipping':  ${orderVisaDetails.gtm?.shipping},  
                    'items': [
                        {
                            'item_id': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_id
                            )}',  
                            'item_name':' ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_name
                            )}',  
                            'item_brand': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_brand
                            )}', 
                            'item_category':'${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_category
                            )}',  
                            'item_variant': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.item_variant
                            )}',  
                            'affiliation': '${orderVisaDetails.gtm?.items?.map(
                              (i) => i.affiliation
                            )}', 
                            'discount': ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.discount
                            )}, 
                            'quantity': ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.quantity
                            )},  
                            'price': ${orderVisaDetails.gtm?.items?.map(
                              (i) => i.price
                            )}  
                        }
                    ]
                });
            });
          `}
            </script> */}
          </Helmet>
          <section className="outter">
            <div
              id="rlr_banner_slider"
              className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
            >
              <div className="splide__track rlr-banner-splide__track">
                <ul className="splide__list height-internal-pages">
                  <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                    <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                      <div className="overlay-banner"></div>
                      <Suspense fallback={<div>{t("Loading")}...</div>}>
                        <img
                          src={
                            isMobile
                              ? orderVisaDetails?.pageDetails?.pageBannerMobile
                              : isTablet
                              ? orderVisaDetails?.pageDetails?.pageBannerTablet
                              : orderVisaDetails?.pageDetails?.pageBannerPc
                          }
                          alt={orderVisaDetails?.pageDetails?.pageTitle}
                          loading="lazy"
                        />
                      </Suspense>
                    </div>
                    <article className="rlr-banner-splide__content-wrapper container">
                      <div className="row justify-content-start">
                        <div className="col-xl-9">
                          <div className="rlr-banner-splide__header">
                            <h2
                              className="rlr-banner-splide__slogan rlr-section__title--main"
                              style={{ textShadow: "1px 1px 2px #000" }}
                            >
                              {t("Thank You")}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </article>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="mb-5 shadow-soft bg-white rounded-sm">
                    <div class="py-6 px-5 border-bottom">
                      <div class="flex-horizontal-center text-center">
                        <div class="ml-3">
                          <h3 class="font-size-18 font-weight-bold text-dark mb-0 text-lh-sm">
                            {t(
                              `Thank You. Your Booking Order is Confirmed Now`
                            )}
                            .
                          </h3>
                          <p class="mb-0">
                            {t(
                              "A confirmation email has been sent to your provided email address"
                            )}{" "}
                            .
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 mx-auto">
                      <div class="pt-4 pb-5 px-5 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          {t("Flight Details")}
                        </h5>

                        <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Origin Name")}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.originName}
                            </span>
                          </li>{" "}
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Arrival Flight Number")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {
                                orderVisaDetails.orderDetails
                                  ?.arrivalFlightNumber
                              }{" "}
                            </span>
                          </li>
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Arrival Airline")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.arrivalAirline}{" "}
                            </span>
                          </li>
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Arrival Date")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.arrivalDate}
                            </span>
                          </li>
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Arrival Time")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.arrivalTime}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div class="pt-4 pb-5 px-5 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          {t("Payment Information")}
                        </h5>

                        <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Payment Reference")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.payTabTranRef}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Total Amount")}:{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {" "}
                              {
                                orderVisaDetails.orderDetails?.payTabCartAmount
                              }{" "}
                              {
                                orderVisaDetails.orderDetails
                                  ?.payTabCartCurrency
                              }
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">{t("Card")} </span>
                            <span class="text-secondary text-right">
                              {
                                orderVisaDetails.orderDetails
                                  ?.payTabPaymentInfoCardScheme
                              }{" "}
                              {
                                orderVisaDetails.orderDetails
                                  ?.payTabPaymentInfoCardType
                              }{" "}
                              (
                              {
                                orderVisaDetails.orderDetails
                                  ?.payTabPaymentInfoPaymentDescription
                              }
                              )
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div class="pt-4 pb-5 px-5 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          {t("Traveler Information")}
                        </h5>

                        <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Confirmation number")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.payTabCartId}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Full Name")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.guestName}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("E-mail Address")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.email}
                            </span>
                          </li>
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Mobile")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.mobile}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Nationality")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.nationalityName}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              {t("Language")}{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderVisaDetails.orderDetails?.languageName}{" "}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div class="pt-4 pb-5 px-5 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          {t("Passengers Information")}
                        </h5>

                        <div class="table-responsive">
                          <table class="table table-bordered text-nowrap border-bottom table-hover">
                            <thead
                              class="text-white bg-danger "
                              style={{ backgroundColor: "#E60000" }}
                            >
                              <tr>
                                <th class="text-center"> {t("Name")} </th>
                                <th class="text-center">
                                  {" "}
                                  {t("Nationailty")}{" "}
                                </th>
                                <th class="text-center">
                                  {t("Passport Number")}{" "}
                                </th>
                                <th class="text-center">{t("Birth Date")} </th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderVisaDetails.orderDetails?.passengers?.map(
                                (passenger, index) => (
                                  <tr key={index}>
                                    <td class="text-center">
                                      {passenger.name}
                                    </td>
                                    <td class="text-center">
                                      {passenger.nationalityName}
                                    </td>

                                    <td class="text-center">
                                      {passenger.passportNumber}
                                    </td>

                                    <td class="text-center">
                                      {passenger.birthOfDate}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ThanksVisa;
