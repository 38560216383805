import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchOrders } from "../../data/orders/ordersSlice";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "./../../Loader";
function Orders() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector((state) => state.orders);
  const language = useSelector((state) => state.language.language);
  useEffect(
    function () {
      dispatch(fetchOrders(language));
    },
    [dispatch, language]
  );
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        error.message
      ) : (
        <>
          {" "}
          <Helmet>
            <title>{orders.pageDetails?.pageMetatagTitle ?? "Orders"}</title>

            <link
              rel="canonical"
              href={`https://travco.com/${language}/myorders`}
            />
            <meta
              name="description"
              content={orders.pageDetails?.pageMetatagDescription}
            />
            <meta
              property="og:title"
              content={orders.pageDetails?.metatagTitleFacebook}
            />
            <meta
              property="og:description"
              content={orders.pageDetails?.metatagDescriptionFacebook}
            />
            <meta
              name="twitter:title"
              content={orders.pageDetails?.metatagTitleTwitter}
            />
            <meta
              name="twitter:description"
              content={orders.pageDetails?.metatagDescriptionTwitter}
            />
            <style>{`
        .divider {
        border-bottom: 4px solid #e60000;
        opacity: 0.8;
        width: 70px;
        margin: 20px 0 40px 0;
    }
.SiteFontP {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
}
    ul {
        padding-left: 23px;
    }

      .about-section  ul li {
            margin-bottom: 15px;
            font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
        }

    li::marker {
        color: #e60000;
        background-color: #e60000;
    }

    .about-section a {
        color: #e60000 !important;
    }
        `}</style>
          </Helmet>{" "}
          <section className="outter">
            <div
              id="rlr_banner_slider"
              className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
            >
              <div className="splide__track rlr-banner-splide__track">
                <ul className="splide__list height-internal-pages">
                  <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                    <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                      <div className="overlay-banner"></div>
                      <Suspense fallback={<div>{t("Loading")}...</div>}>
                        {" "}
                        <img
                          src={
                            isMobile
                              ? orders.pageDetails?.pageBannerMobile
                              : isTablet
                              ? orders.pageDetails?.pageBannerTablet
                              : orders.pageDetails?.pageBannerPc
                          }
                          alt={orders.pageDetails?.altBanner}
                          loading="lazy"
                        />
                      </Suspense>
                    </div>
                    <article className="rlr-banner-splide__content-wrapper container">
                      <div className="row justify-content-start">
                        <div className="col-xl-9">
                          <div className="rlr-banner-splide__header">
                            <h2
                              className="rlr-banner-splide__slogan rlr-section__title--main"
                              style={{ textShadow: "1px 1px 2px #000" }}
                            >
                              {orders.pageDetails?.pageTitle}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </article>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <main id="rlr-main" className="rlr-main--fixed-top my-5 py-5">
            <div
              className="rlr-section__content--lg-top mt-0"
              style={{ marginTop: "0px !important" }}
            >
              <section
                className="rlr-section rlr-section__mt rlr-account mt-0"
                style={{ marginTop: "0px !important" }}
              >
                <div className="container">
                  {orders.packagesOrders?.length <= 0 &&
                  orders.cruiseOrders?.length <= 0 &&
                  orders.toursOrders?.length <= 0 &&
                  orders.transfersOrders?.length <= 0 &&
                  orders.hotelOrders?.length <= 0 &&
                  orders.visaOrders?.length <= 0 ? (
                    <div className="row">
                      <p className="siteFontP">{t("No Orders")}!</p>{" "}
                    </div>
                  ) : (
                    <>
                      {/* Packages  */}
                      {orders.packagesOrders?.length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card" style={{ border: "none" }}>
                              <div
                                className="card-header custom-header text-center align-middle"
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  marginBottom: "-20px",
                                }}
                              >
                                <h3 className="card-title mb-0">
                                  {t("Packages Orders")}{" "}
                                </h3>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table
                                    id="example2"
                                    className="table table-bordered   border-bottom table-hover"
                                  >
                                    <thead
                                      className="text-white bg-travco"
                                      style={{ backgroundColor: "#e60000" }}
                                    >
                                      <tr>
                                        <th className="border-bottom-0 text-center">
                                          {t("Package")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Package")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Total")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Details")}
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody style={{ borderTop: "none" }}>
                                      {orders.packagesOrders?.map(
                                        (packages) => (
                                          <tr key={packages.orderId}>
                                            <td>
                                              {packages.productName}
                                              <br />
                                              <small>
                                                <strong>
                                                  {t("Trip Date")}:
                                                </strong>{" "}
                                                {packages.orderTripDate}
                                              </small>
                                              <br />
                                            </td>
                                            <td className="text-center align-middle">
                                              {packages.orderInsertDate}
                                            </td>
                                            <td className="text-center align-middle">
                                              {packages.orderInsertDate}{" "}
                                              {packages.payTabCartCurrency}
                                            </td>

                                            <td className="text-center align-middle">
                                              <div className="btn-group align-top br-7">
                                                <Link
                                                  to={`/orderPackageDetails/${packages.orderId}`}
                                                  className="btn btn-sm  btn-primary badge"
                                                >
                                                  <i className="fa fa-list-ul"></i>{" "}
                                                  {t("Details")}
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Cruises  */}
                      {orders.cruiseOrders?.length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card" style={{ border: "none" }}>
                              <div
                                className="card-header custom-header text-center align-middle"
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  marginBottom: "-20px",
                                }}
                              >
                                <h3 className="card-title mb-0">
                                  {t("Nile Cruises Orders")}{" "}
                                </h3>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table
                                    id="example2"
                                    className="table table-bordered   border-bottom table-hover"
                                  >
                                    <thead
                                      className="text-white bg-travco"
                                      style={{ backgroundColor: "#e60000" }}
                                    >
                                      <tr>
                                        <th>{t("Nile Cruise")} </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Order Date")}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Total")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Details")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "none" }}>
                                      {orders.cruiseOrders?.map((cruise) => (
                                        <tr key={cruise.orderId}>
                                          <td>
                                            <span> {cruise.cruiseName}</span>
                                            <br />
                                            <small>
                                              <strong>{t("Itinarary")}:</strong>{" "}
                                              {cruise.itinararyRoute} -{" "}
                                              {cruise.itinararyDuration}
                                            </small>
                                            <br />
                                            <small>
                                              <strong>{t("Cabin")}:</strong>{" "}
                                              {cruise.cabinName}
                                            </small>
                                            <br />
                                            <small>
                                              <strong>{t("Trip Date")}:</strong>{" "}
                                              {cruise.orderTripDate}
                                            </small>

                                            <br />
                                          </td>
                                          <td className="text-center align-middle">
                                            {cruise.orderInsertDate}
                                          </td>
                                          <td className="text-center align-middle">
                                            {cruise.orderTotalAmount}{" "}
                                            {cruise.payTabCartCurrency}
                                          </td>

                                          <td className="text-center align-middle">
                                            <div className="btn-group align-top br-7">
                                              <Link
                                                to={`/orderNileCruisesDetails/${cruise.orderId}`}
                                                className="btn btn-sm  btn-primary badge"
                                              >
                                                <i className="fa fa-list-ul"></i>{" "}
                                                {t("Details")}
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Tours  */}
                      {orders.toursOrders?.length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card" style={{ border: "none" }}>
                              <div
                                className="card-header custom-header text-center align-middle"
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  marginBottom: "-20px",
                                }}
                              >
                                <h3 className="card-title mb-0">
                                  {t("Tours Orders")}{" "}
                                </h3>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table
                                    id="example2"
                                    className="table table-bordered   border-bottom table-hover"
                                  >
                                    <thead
                                      className="text-white bg-travco"
                                      style={{ backgroundColor: "#e60000" }}
                                    >
                                      <tr>
                                        <th>{t("Tour")} </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Order Date")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Total")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Details")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "none" }}>
                                      {orders.toursOrders?.map((tour) => (
                                        <tr key={tour.orderId}>
                                          <td>
                                            {tour.productName}
                                            <br />
                                            <small>
                                              <strong>{t("Trip Date")}:</strong>{" "}
                                              {tour.orderTripDate}
                                            </small>
                                            <br />
                                          </td>
                                          <td className="text-center align-middle">
                                            {tour.orderInsertDate}
                                          </td>
                                          <td className="text-center align-middle">
                                            {tour.orderTotalAmount}{" "}
                                            {tour.payTabCartCurrency}
                                          </td>

                                          <td className="text-center align-middle">
                                            <div className="btn-group align-top br-7">
                                              <Link
                                                to={`/orderTourDetails/${tour.orderId}`}
                                                className="btn btn-sm  btn-primary badge"
                                              >
                                                <i className="fa fa-list-ul"></i>{" "}
                                                {t("Details")}
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Transfers  */}
                      {orders.transfersOrders?.length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card" style={{ border: "none" }}>
                              <div
                                className="card-header custom-header text-center align-middle"
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  marginBottom: "-20px",
                                }}
                              >
                                <h3 className="card-title mb-0">
                                  {t("Transfers Orders")}{" "}
                                </h3>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table
                                    id="example2"
                                    className="table table-bordered   border-bottom table-hover"
                                  >
                                    <thead
                                      className="text-white bg-travco"
                                      style={{ backgroundColor: "#e60000" }}
                                    >
                                      <tr>
                                        <th>{t("Transfer")} </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Order Date")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Total")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Details")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "none" }}>
                                      {orders.transfersOrders?.map(
                                        (transfer) => (
                                          <tr key={transfer.orderId}>
                                            <td>
                                              {transfer.productName}
                                              <br />
                                              <br />
                                            </td>

                                            <td className="text-center align-middle">
                                              {transfer.orderInsertDate}
                                            </td>

                                            <td className="text-center align-middle">
                                              {transfer.orderTotalAmount}{" "}
                                              {transfer.payTabCartCurrency}
                                            </td>

                                            <td className="text-center align-middle">
                                              <div className="btn-group align-top br-7">
                                                <Link
                                                  to={`/orderTransferDetails/${transfer.orderId}`}
                                                  className="btn btn-sm  btn-primary badge"
                                                >
                                                  <i className="fa fa-list-ul"></i>{" "}
                                                  {t("Details")}
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Hotels  */}
                      {orders.hotelOrders?.length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card" style={{ border: "none" }}>
                              <div
                                className="card-header custom-header text-center align-middle"
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  marginBottom: "-20px",
                                }}
                              >
                                <h3 className="card-title mb-0">
                                  {t("Hotels Orders")}{" "}
                                </h3>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table
                                    id="example2"
                                    className="table table-bordered   border-bottom table-hover"
                                  >
                                    <thead
                                      className="text-white bg-travco"
                                      style={{ backgroundColor: "#e60000" }}
                                    >
                                      <tr>
                                        <th className="border-bottom-0 text-center">
                                          {t("Hotel")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Order Date")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Total")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Details")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ borderTop: "none" }}>
                                      {orders.hotelOrders?.map((hotel) => (
                                        <tr key={hotel.bookingId}>
                                          <td>{hotel.hotelName}</td>

                                          <td className="text-center align-middle">
                                            {hotel.bookingDateTime}
                                          </td>

                                          <td className="text-center align-middle">
                                            {hotel.totalBookingPayment}{" "}
                                            {hotel.payTabCartCurrency}
                                          </td>

                                          <td className="text-center align-middle">
                                            <div className="btn-group align-top br-7">
                                              <Link
                                                to={`/orderHotelDetails/${hotel.bookingId}`}
                                                className="btn btn-sm  btn-primary badge"
                                              >
                                                <i className="fa fa-list-ul"></i>{" "}
                                                {t("Details")}
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Visa  */}
                      {orders.visaOrders?.length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card" style={{ border: "none" }}>
                              <div
                                className="card-header custom-header text-center align-middle"
                                style={{
                                  border: "none",
                                  backgroundColor: "inherit",
                                  marginBottom: "-20px",
                                }}
                              >
                                <h3 className="card-title mb-0">
                                  {t("Visa Orders")}{" "}
                                </h3>
                              </div>
                              <div className="card-body">
                                <div className="table-responsive">
                                  <table
                                    id="example2"
                                    className="table table-bordered   border-bottom table-hover"
                                  >
                                    <thead
                                      className="text-white bg-travco"
                                      style={{ backgroundColor: "#e60000" }}
                                    >
                                      <tr>
                                        <th>{t("Origin Name")} </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Order Date")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Total")}{" "}
                                        </th>
                                        <th className="border-bottom-0 text-center">
                                          {t("Details")}
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody style={{ borderTop: "none" }}>
                                      {orders.visaOrders?.map((visa) => (
                                        <tr key={visa.visaRequestId}>
                                          <td> {visa.originName}</td>
                                          <td className="text-center align-middle">
                                            {visa.requestDate}
                                          </td>
                                          <td className="text-center align-middle">
                                            {visa.amountTotal}{" "}
                                            {visa.payTabCartCurrency}
                                          </td>

                                          <td className="text-center align-middle">
                                            <div className="btn-group align-top br-7">
                                              <Link
                                                to={`/orderVisaDetails/${visa.visaRequestId}`}
                                                className="btn btn-sm  btn-primary badge"
                                              >
                                                <i className="fa fa-list-ul"></i>{" "}
                                                {t("Details")}
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </section>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default Orders;
