import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { fetchDestinations } from "../../data/destinations/destinationsSlice";
import { useTranslation } from "react-i18next";

const Destinations = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { destinations, loading, error } = useSelector(
    (state) => state.destinations
  );
  useEffect(() => {
    dispatch(fetchDestinations(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>{destinations.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/destination/egypt`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={destinations.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={destinations.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={destinations.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={destinations.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={destinations.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? destinations.pageDetails?.pageBannerMobile
                          : isTablet
                          ? destinations.pageDetails?.pageBannerTablet
                          : destinations.pageDetails?.pageBannerPc
                      }
                      alt={destinations.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {destinations.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {destinations.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              <Link to="/">
                {t("Questions")}{" "}
                {language == "ar" ? (
                  <>
                    ؟{" "}
                    <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                  </>
                ) : (
                  "? (+202) 3854 1010"
                )}
              </Link>{" "}
            </Link>
          </div>
        </div>
      </div>
      <div className="destinations">
        <div className="container">
          <div className="wrap_float">
            <div className="title_wrap" style={{ paddingBottom: "4rem" }}>
              <h2 className="rlr-section__title--main ">
                {t("Top Destinations")}{" "}
              </h2>
              <p className="subtitle"></p>
            </div>
            <div className="section_content">
              <div className="scroll">
                {destinations.list_vw_CountDestinationsHaveProducts?.map(
                  (destination) => (
                    <Link
                      key={destination.destinationUrl}
                      to={`/destination/egypt/${destination.destinationUrl}`}
                      className="destinations_item"
                      style={{
                        backgroundImage: `url(${destination.destinationMainPhoto})`,
                      }}
                    >
                      <div className="sq_parent">
                        <div className="sq_wrap">
                          <div className="sq_content">
                            <div className="_content">
                              <h3 className="_title">
                                {destination.destinationNameSys}{" "}
                              </h3>
                              <p className="_info">
                                {destination.productCount} {t("Tours")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="shadow js-shadow"
                        style={{
                          backgroundImage: `url(${destination.destinationMainPhoto})`,
                        }}
                      ></div>
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Destinations;
