import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchPackagesOrders } from "../../data/orders/packagesOrdersSlice";
import { useTranslation } from "react-i18next";

const PackagesOrders = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  // const currency = useSelector((state) => state.currency.currency);
  const language = useSelector((state) => state.language.language);
  const { packagesOrders, loading, error } = useSelector(
    (state) => state.packagesOrders
  );

  useEffect(() => {
    dispatch(fetchPackagesOrders({ orderId, language }));
  }, [orderId, dispatch, language]);

  return (
    <>
      <Helmet>
        <title>
          {packagesOrders.pageDetails?.pageTitle ?? "Package Order"}
        </title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/orderPackageDetails/${orderId}`}
        />
        <meta
          name="description"
          content={packagesOrders.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={packagesOrders.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={packagesOrders.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={packagesOrders.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={packagesOrders.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">
          {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
        </style>
        <link
          href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/login-booking/css/style.css"
          rel="stylesheet"
        />
        <link
          href="TITSolutions/WS/assets/line-awesome-3.css"
          rel="stylesheet"
        ></link>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? packagesOrders?.pageDetails?.pageBannerMobile
                          : isTablet
                          ? packagesOrders?.pageDetails?.pageBannerTablet
                          : packagesOrders?.pageDetails?.pageBannerPc
                      }
                      alt={packagesOrders?.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="mt-5 pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="mb-5 shadow-soft bg-white rounded-sm">
                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    {t("Flight Information")}
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">{t("Origin")} </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.originName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Arrival Airline")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.airlineName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Arrival Flight No")}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.arrivalFlightNumber}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Arrival Date")}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.arrivalDate}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Arrival Time")}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.arrivalTime}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    {t("Traveler Information")}
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Booking Number")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.payTabCartId}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("First Name")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.customerFirstName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Last Name")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.customerLastName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("E-mail Address")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.customerEmailAddress}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">{t("Mobile")} </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.customerMobile}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Nationality")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.nationalityName}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Child")} (2-5.99){" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.childNo2}
                      </span>
                    </li>
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Child")} (6-11.99){" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.childNo6}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    {t("Package Information")}
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Package")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.productName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Destination")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.destinationName}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Package Date")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.orderTripDate}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="pt-4 pb-5 px-5 border-bottom">
                  <h5
                    id="scroll-description"
                    className="font-size-21 font-weight-bold text-dark mb-2"
                  >
                    {t("Payment Information")}
                  </h5>

                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Transaction Reference")}{" "}
                      </span>
                      <span className="text-secondary text-right">
                        {packagesOrders.packageOrder?.payTabTranRef}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">
                        {t("Total Amount")}:{" "}
                      </span>

                      <span className="text-secondary text-right">
                        {" "}
                        {packagesOrders.packageOrder?.payTabCartAmount}{" "}
                        {packagesOrders.packageOrder?.payTabCartCurrency}
                      </span>
                    </li>

                    <li className="d-flex justify-content-between py-2">
                      <span className="font-weight-medium">{t("Card")} </span>
                      <span className="text-secondary text-right">
                        {
                          packagesOrders.packageOrder
                            ?.payTabPaymentInfoCardScheme
                        }{" "}
                        (
                        {
                          packagesOrders.packageOrder
                            ?.payTabPaymentInfoPaymentDescription
                        }
                        )
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PackagesOrders;
