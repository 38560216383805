import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { Container, ProgressBar } from "react-bootstrap";

const LoaderHotels = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="loader d-flex justify-content-center align-items-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ zIndex: "9", position: "relative", top: "0vh" }}>
          {" "}
          <img
            alt="loader"
            src="loading.gif"

            // style={{ filter: "brightness(0.7)" }}
          />
        </div>
      </div>
      <p className="fs-5 mt-3 text-center text-secondary">
        {t("We are searching for the best price for Hotels")}
      </p>
    </>
  );
};

export default LoaderHotels;
