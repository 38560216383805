import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchTestimonials } from "../../data/testimonials/testimonialsSlice";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const language = useSelector((state) => state.language.language);

  const dispatch = useDispatch();

  const { testimonials, loading, error } = useSelector(
    (state) => state.testimonials
  );

  useEffect(() => {
    dispatch(fetchTestimonials(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>
          {testimonials.pageDetails?.pageMetatagTitle ?? "testimonials"}
        </title>

        <link
          rel="canonical"
          href={`https://travco.com/${language}/testimonials`}
        />
        <meta
          name="description"
          content={testimonials.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={testimonials.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={testimonials.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={testimonials.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={testimonials.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? testimonials.pageDetails?.pageBannerMobile
                          : isTablet
                          ? testimonials.pageDetails?.pageBannerTablet
                          : testimonials.pageDetails?.pageBannerPc
                      }
                      alt={testimonials.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {testimonials.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {testimonials.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section>
        <div className="container-xxl">
          <article className="rlr-article rlr-article-single--v2">
            <div className="rlr-article__wrapper" style={{ maxWidth: "100%" }}>
              <div className="content">
                <div
                  className="content__highlight text-center"
                  dangerouslySetInnerHTML={{
                    __html: testimonials.pageDetails?.pageText,
                  }}
                />

                <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-between mt-5 pt-5">
                  {testimonials.testImonialList?.map((item, index) => (
                    <iframe
                      key={index}
                      className="d-block col-12 col-lg-4 px-2 py-3 mx-auto "
                      height="270"
                      src={item.testimonialsUrl}
                      title={`${t("Testimonial")} ${index}`}
                      loading="lazy"
                    ></iframe>
                  ))}
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
