import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";

import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { fetchOrderHotelThanks } from "../../data/juniper-hotels/orderHotelThanksSlice";
import Loader from "../../Loader";

const ThanksHotel = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const currency = useSelector((state) => state.currency.currency);
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const { orderHotelThanks, loading, error } = useSelector(
    (state) => state.orderHotelThanks
  );
  useEffect(() => {
    const queryParams = window.location.search;

    if (queryParams) {
      localStorage.removeItem("utm_params");
    }
  }, []);
  useEffect(() => {
    dispatch(fetchOrderHotelThanks(orderId));
  }, [orderId, dispatch]);
  useEffect(() => {
    // Add Google Tag Manager script to the head
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-RS6ZVQS9YL";
    document.head.appendChild(script1);

    // Add the gtag initialization script
    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-RS6ZVQS9YL', { 'debug_mode': true });
    `;
    document.head.appendChild(script2);

    // Add the purchase event script
    const script3 = document.createElement("script");
    script3.innerHTML = `
      gtag('event', 'purchase', {
         'transaction_id': '${orderHotelThanks.gtm?.transaction_id}',  
                    'affiliation': '${orderHotelThanks.gtm?.affiliation}',  
                    'value':  ${orderHotelThanks.gtm?.value},  
                    'currency': '${orderHotelThanks.gtm?.currency}',  
                    'tax': ${orderHotelThanks.gtm?.tax}, 
                    'shipping':  ${orderHotelThanks.gtm?.shipping},  
                    'items': [
                        {
                            'item_id': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_id
                            )}',  
                            'item_name':' ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_name
                            )}',  
                            'item_brand': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_brand
                            )}', 
                            'item_category':'${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_category
                            )}',  
                            'item_variant': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_variant
                            )}',  
                            'affiliation': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.affiliation
                            )}', 
                            'discount': ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.discount
                            )}, 
                            'quantity': ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.quantity
                            )},  
                            'price': ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.price
                            )}  
                        }
                    ]
      });
    `;
    document.body.appendChild(script3);

    // Cleanup the scripts when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
      document.body.removeChild(script3);
    };
  }, [loading]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#e60000", padding: "30vh 0" }}
          className="d-flex flex-column justify-content-center align-items-center  mx-auto"
        >
          <h1 className="text-center text-light">{error}</h1>
          <Link to="/" className="btn btn-light text-center  mt-4 text-travco">
            Back To Home
          </Link>
        </section>
      ) : (
        <>
          <Helmet>
            <title>{orderHotelThanks?.pageDetails?.pageTitle}</title>
            <meta
              name="title"
              content={orderHotelThanks?.pageDetails?.pageMetatagTitle}
            />
            <meta
              name="description"
              content={orderHotelThanks?.pageDetails?.pageMetatagDescription}
            />
            <style type="text/css">
              {`

          .form-control{
          height:50px;
          border-color:#0000003b;
          }
             .form-v8-content .form-row .form-row-inner .label {
        position: absolute;
        top: -25px;
      
    }
    .form-v8-content .form-detail .form-row .input-text:focus + .label, .form-v8-content .form-detail .form-row .input-text:valid + .label {
        -webkit-transform: translateY(-10px) scale(1);
    }
    #register, #login {
        display: flex;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
    }
        .react-datepicker-wrapper{
        position:relative;
        width:100%;
        }
        .input-group-prepend span{
        position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
        }
    .react-datepicker-popper{
    z-index: 100}
   @media screen and (min-width:767px){
    .w-45 {
        width: 45% !important;
    }}
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color:#e60000 !important;
        }

   

          `}
            </style>
            <link
              href="TITSolutions/WS/assets/login-booking/css/sourcesanspro-font.css"
              rel="stylesheet"
            />
            <link
              href="TITSolutions/WS/assets/login-booking/css/style.css"
              rel="stylesheet"
            />
            <link
              href="TITSolutions/WS/assets/line-awesome-3.css"
              rel="stylesheet"
            />
            {/* <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-RS6ZVQS9YLxxxxxxxxxxxxxxxxxxxxxxxxxx"
            ></script>
            <script>
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'G-RS6ZVQS9YLxxxxxxxxxxxxxxxxxxxxxxxxxx');

            window.addEventListener('load', function () {
                gtag('event', 'purchase', {
                    'transaction_id': '${
                      orderHotelThanks.gtm?.transaction_id
                    }',  
                    'affiliation': '${orderHotelThanks.gtm?.affiliation}',  
                    'value':  ${orderHotelThanks.gtm?.value},  
                    'currency': '${orderHotelThanks.gtm?.currency}',  
                    'tax': ${orderHotelThanks.gtm?.tax}, 
                    'shipping':  ${orderHotelThanks.gtm?.shipping},  
                    'items': [
                        {
                            'item_id': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_id
                            )}',  
                            'item_name':' ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_name
                            )}',  
                            'item_brand': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_brand
                            )}', 
                            'item_category':'${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_category
                            )}',  
                            'item_variant': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.item_variant
                            )}',  
                            'affiliation': '${orderHotelThanks.gtm?.items?.map(
                              (i) => i.affiliation
                            )}', 
                            'discount': ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.discount
                            )}, 
                            'quantity': ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.quantity
                            )},  
                            'price': ${orderHotelThanks.gtm?.items?.map(
                              (i) => i.price
                            )}  
                        }
                    ]
                });
            });
          `}
            </script> */}
          </Helmet>
          <section className="outter">
            <div
              id="rlr_banner_slider"
              className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
            >
              <div className="splide__track rlr-banner-splide__track">
                <ul className="splide__list height-internal-pages">
                  <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                    <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                      <div className="overlay-banner"></div>
                      <Suspense fallback={<div>Loading...</div>}>
                        <img
                          src={
                            isMobile
                              ? orderHotelThanks?.pageDetails?.pageBannerMobile
                              : isTablet
                              ? orderHotelThanks?.pageDetails?.pageBannerTablet
                              : orderHotelThanks?.pageDetails?.pageBannerPc
                          }
                          alt={orderHotelThanks?.pageDetails?.pageTitle}
                          loading="lazy"
                        />
                      </Suspense>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section class="payment-area pt-100">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-box payment-received-wrap mb-0">
                    <div class="form-title-wrap">
                      <div class="step-bar-wrap text-center">
                        <ul
                          class="step-bar-list d-flex align-items-center justify-content-around"
                          style={{ listStyle: "none" }}
                        >
                          <li class="step-bar flex-grow-1 step-bar-active ">
                            <span class="icon-element">
                              {" "}
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <p class="pt-2 color-text-2">Choose Dates </p>
                          </li>
                          <li class="step-bar flex-grow-1 step-bar-active ">
                            <span class="icon-element">
                              {" "}
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <p class="pt-2 color-text-2">Choose Rooms </p>
                          </li>
                          <li class="step-bar flex-grow-1 step-bar-active ">
                            <span class="icon-element">
                              {" "}
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <p class="pt-2 color-text-2">
                              Personal Information{" "}
                            </p>
                          </li>
                          <li class="step-bar flex-grow-1  step-bar-active ">
                            <span class="icon-element">
                              {" "}
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <p class="pt-2 color-text-2">Review </p>
                          </li>
                          <li class="step-bar flex-grow-1  step-bar-active">
                            <span class="icon-element">
                              {" "}
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <p class="pt-2 color-text-2">Booking Completed! </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <main id="rlr-main" class="rlr-main">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="mb-5 shadow-soft bg-white rounded-sm ">
                    <div class="py-6 px-0 border-bottom">
                      <div class="flex-horizontal-center text-center">
                        <div class="ml-3 ">
                          <h3 class="font-size-18 font-weight-bold text-dark mb-0 text-lh-sm">
                            Thank You. Your Booking Order is Confirmed Now.
                          </h3>
                          <p class="mb-0">
                            A confirmation email has been sent to your provided
                            email address.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9 mx-auto">
                      {" "}
                      <div class="pt-4 pb-5 px-0 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          Traveler Information
                        </h5>

                        <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">First name </span>
                            <span class="text-secondary text-right">
                              {
                                orderHotelThanks?.orderDetails
                                  ?.customerFirstName
                              }{" "}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">Last name </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.customerLastName}{" "}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              E-mail address{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {
                                orderHotelThanks?.orderDetails
                                  ?.customerEmailAddress
                              }
                            </span>
                          </li>
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">Mobile </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.customerMobile}{" "}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">Nationality </span>
                            <span class="text-secondary text-right">
                              {
                                orderHotelThanks?.orderDetails
                                  ?.customerNationality
                              }
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pt-4 pb-5 px-0 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          Booking Information
                        </h5>

                        <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              Confirmation number
                            </span>
                            <span class="text-secondary text-right">
                              {
                                orderHotelThanks?.orderDetails
                                  ?.bookingTitrefrence
                              }
                            </span>
                          </li>
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">Hotel Name </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.hotelDetails?.hotelName}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              Hotel Address{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.hotelDetails?.hotelAddress}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">Check In </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.bookingStartDate}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">Check Out </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.bookingEndDate}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              Number of Rooms{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {" "}
                              {orderHotelThanks?.orderDetails?.numberofRooms}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              Number of Adults{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.numberOfAdults}
                            </span>
                          </li>
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              Number of Child{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.numberofChild}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="pt-4 pb-5 px-0 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          Room(s) Details
                        </h5>
                        <table class="table table-bordered">
                          <tr>
                            <td>Room</td>
                            <td align="center">Adults No</td>
                            <td align="center">Child No</td>
                          </tr>
                          {orderHotelThanks?.orderJuniperRooms?.map(
                            (room, index) => (
                              <tr key={index}>
                                <td>{room.roomName}</td>
                                <td align="center">{room.adultNo}</td>
                                <td align="center">{room.childNo}</td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                      <div class="pt-4 pb-5 px-0 border-bottom">
                        <h5
                          id="scroll-description"
                          class="font-size-21 font-weight-bold text-dark mb-2"
                        >
                          Payment Information
                        </h5>

                        <ul class="list-unstyled font-size-1 mb-0 font-size-16">
                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              Payment Reference{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.payTabTranRef}
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">
                              Total Amount:{" "}
                            </span>
                            <span class="text-secondary text-right">
                              {orderHotelThanks?.orderDetails?.payTabCartAmount}{" "}
                              {
                                orderHotelThanks?.orderDetails
                                  ?.payTabCartCurrency
                              }
                            </span>
                          </li>

                          <li class="d-flex justify-content-between py-2">
                            <span class="font-weight-medium">Card </span>
                            <span class="text-secondary text-right">
                              {
                                orderHotelThanks?.orderDetails
                                  ?.payTabPaymentInfoCardScheme
                              }{" "}
                              {
                                orderHotelThanks?.orderDetails
                                  ?.payTabPaymentInfoCardType
                              }{" "}
                              (
                              {
                                orderHotelThanks?.orderDetails
                                  ?.payTabPaymentInfoPaymentDescription
                              }
                              )
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="alert alert-danger"
                        style={{ padding: "50px" }}
                      >
                        <h2>Cancellation Policy</h2>
                        <strong>Last Day for Cancelation :</strong>{" "}
                        {orderHotelThanks?.orderDetails?.bookingLastDateTime}
                        <br />
                        <strong>Cancelation Policy</strong>
                        <span
                          className="d-inline"
                          dangerouslySetInnerHTML={{
                            __html:
                              orderHotelThanks.orderDetails
                                ?.cancelationPolicyDescription,
                          }}
                        />
                        <ul>
                          {orderHotelThanks.orderCancellationPolicies?.map(
                            (item, index) => (
                              <li key={index}>
                                Cancelling from <b> {item.fromDate}</b> at{" "}
                                {item.fromHour} up to <b>{item.toDate}</b>{" "}
                                {`${item.toHour && `at   ${item.toHour}`}`} :{" "}
                                {item.deductionPercent}% of expenses
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default ThanksHotel;
