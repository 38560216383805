import React, { Suspense, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { login, loginWithGoogle } from "../../data/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { jwtDecode } from "jwt-decode";
import GoogleLogin from "react-google-login";
import { fetchData } from "../../data/login/loginDataSlice";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
const clientId =
  "263736580748-di1finbitm46nff8hrbekfgbudb7iul8.apps.googleusercontent.com";
function Login() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const storedToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.language);
  useEffect(() => {
    dispatch(fetchData(language));
  }, [dispatch, language]);

  const { loginData } = useSelector((store) => store.loginData);
  const error = useSelector((store) => store.login.error);
  const message = useSelector((store) => store.login.message);
  const accessToken = useSelector((state) => state.login.token);
  const loading = useSelector((state) => state.login.loading);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("Invalid email address"))
      .required(t("Email is required")),
    password: Yup.string()
      .min(8, t("Password must be at least 8 characters"))
      .required(t("Password is required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(login(values)).then((response) => {
        if (response.payload && response.payload.error) {
          console.log("Login failed:", response.payload.error);
        } else if (response.payload && response.payload.accessToken) {
          localStorage.setItem("accessToken", response.payload.accessToken);
          localStorage.setItem("firstName", values.email);
          localStorage.setItem("lastName", "");
          navigate("/");
        }
      });
    },
  });
  const onSuccessGoogle = async (response) => {
    const idToken = response.getAuthResponse().id_token;
    dispatch(loginWithGoogle(idToken));

    localStorage.setItem("firstName", response.wt.rV);
    localStorage.setItem("lastName", response.wt.uT);
    localStorage.setItem("email", response.wt.cu);
    localStorage.setItem("userImage", response.wt.hK);
    navigate("/");
  };

  const onFailureGoogle = (error) => {
    console.error("Google login failed:", error);
  };

  // useEffect(() => {
  //   if (accessToken) {
  //     localStorage.setItem("accessToken", accessToken);
  //     navigate("/");
  //   }
  // }, [accessToken, navigate]);

  return (
    <>
      <Helmet>
        <title>{loginData?.pageMetatagTitle ?? "Login"}</title>
        <link rel="canonical" href={`https://travco.com/${language}/login`} />
        <meta name="description" content={loginData?.pageMetatagDescription} />
        <meta property="og:title" content={loginData?.metatagTitleFacebook} />
        <meta
          property="og:description"
          content={loginData?.metatagDescriptionFacebook}
        />
        <meta name="twitter:title" content={loginData?.metatagTitleTwitter} />
        <meta
          name="twitter:description"
          content={loginData?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner mb-0"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      className="rlr-banner-splide__banner-img"
                      src={
                        isMobile
                          ? loginData.pageBannerMobile
                          : isTablet
                          ? loginData.pageBannerTablet
                          : loginData.pageBannerPc
                      }
                      alt={loginData.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {loginData.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div
        className="rlr-section__content--lg-top py-5 mt-5"
        style={{ marginTop: "0px !important" }}
      >
        <section
          className="rlr-section rlr-section__mt rlr-account mt-0"
          style={{ marginTop: "0px !important" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 offset-lg-4">
                <form onSubmit={formik.handleSubmit} className="rlr-authforms">
                  <div className="rlr-authforms__header">
                    <h2>{t("Log in to your Travco")}</h2>
                    <p>{t("Welcome back! Please enter login details")}.</p>
                  </div>
                  <div className="rlr-authforms__form">
                    <div className="rlr-authforms__inputgroup">
                      <label
                        htmlFor="Email"
                        className="rlr-form-label rlr-form-label--light required"
                      >
                        {t("Email")}
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="Email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        autoComplete="off"
                        className="form-control form-control--light"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p className="help-text text-danger">
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </div>
                    <div className="rlr-authforms__inputgroup">
                      <label className="rlr-form-label rlr-form-label--light required">
                        {t("Password")}
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        autoComplete="off"
                        className="form-control form-control--light"
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <p className="help-text text-travco">
                          {formik.errors.password}
                        </p>
                      ) : null}
                    </div>
                    <div className="rlr-authforms__forgotpassword">
                      <div className="form-check-inline">
                        <input
                          className="form-check-input rlr-form-check-input"
                          id="rlr-checkbox-1"
                          type="checkbox"
                          name="rememberMe"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          checked={formik.values.rememberMe}
                        />
                        <label
                          className="rlr-form-label rlr-form-label--checkbox rlr-form-label--font-inherit rlr-form-label--bold"
                          htmlFor="rlr-checkbox-1"
                        >
                          {t("Remember me on this device")}
                        </label>
                      </div>
                      <Link to="/reset-password">{t("Forgot password")}</Link>
                    </div>
                    <button
                      type="submit"
                      className="btn mb-3 rlr-button rlr-button--fullwidth rlr-button--primary"
                    >
                      {t("Sign In")}
                    </button>
                    <GoogleLogin
                      clientId={clientId}
                      buttonText={t("Login with Google")}
                      onSuccess={onSuccessGoogle}
                      onFailure={onFailureGoogle}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      className="btn mb-3 rlr-button rlr-button--fullwidth text-dark fw-bold fs-4 google"
                    />
                  </div>
                  {!storedToken && (
                    <div className="rlr-authforms__notes">
                      <p>
                        {t("Don’t have an account")}?{" "}
                        <Link to="/sign-up">{t("Sign Up")}</Link>
                      </p>
                    </div>
                  )}
                </form>
                {message !== null && (
                  <p className="help-text text-danger text-center">
                    {t(message)}
                  </p>
                )}
                {error && (
                  <p className="help-text text-danger text-center">
                    {t(error)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
