import React, { Suspense, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Alert, Snackbar, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchService } from "../../data/about-us/serviceSlice";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Service = () => {
  const { sevicesUrl } = useParams();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { service } = useSelector((state) => state.service);
  useEffect(() => {
    dispatch(fetchService({ sevicesUrl, language }));
  }, [dispatch, sevicesUrl, language]);
  const [formData, setFormData] = useState({
    customerName: "",
    customerEmail: "",
    customermessage: "",
    namehoney: "",
    emailhoney: "",
  });
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [errorFormDate, setErrorFormDate] = useState("");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingFormData(true);
    setErrorFormDate("");
    setSuccess(false);

    try {
      const response = await fetch(
        "https://api.travco.com/ServiceRequestSubmit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerName: formData.customerName,
            emailAddress: formData.customerEmail,
            message: formData.customermessage,
            serviceId: service.serviceDetails?.servicesId,
            namehoney: formData.namehoney,
            emailhoney: formData.emailhoney,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSuccess(true);
      setOpen(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorFormDate(error.message); // Ensure the error is a string
      setOpen(true);
      setSuccess(false);
    } finally {
      setLoadingFormData(false);
      setFormData({
        customerName: "",
        customerEmail: "",
        serviceId: 0,
        customermessage: "",
        namehoney: "", // Clear honey pot fields after submission
        emailhoney: "",
      });
    }
  };
  return (
    <>
      <Helmet>
        {" "}
        <title>{service.pageDetails?.pageMetatagTitle || "Services"}</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://travco.com/en/services/${sevicesUrl}`}
          data-react-helmet="true"
        />
        <meta
          name="description"
          content={service.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={service.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={service.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={service.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={service.pageDetails?.metatagDescriptionTwitter}
        />
        <style type="text/css">{`.entry-content p  {
font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
        }`}</style>
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? service.pageDetails?.pageBannerMobile
                          : isTablet
                          ? service.pageDetails?.pageBannerTablet
                          : service.pageDetails?.pageBannerPc
                      }
                      alt={service.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {service.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/about-travco-travel">{t("About Us")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {service.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link className="rlr-search-results-page__phone">
              {t("Questions")}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}
            </Link>
          </div>
        </div>
      </div>
      <section className="blog-details-section py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details-wrapper">
                <div className="blog-post mb-2 wow fadeInUp">
                  <div className="main-post" style={{ borderBottom: "0" }}>
                    <div className="entry-content">
                      <h3 className="title">
                        {service.serviceDetails?.servicesName}
                      </h3>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: service.serviceDetails?.servicesDetails,
                        }}
                      />
                      <div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12">
                  <div className="contact-form-wrapper">
                    <h3 className="title mb-5">{t("Send Us Message")}</h3>
                    {/* <p>
                      Adipiscing magna varius imperdiet scelerisque suspendisse
                      amet sed ridiculus turpis.
                    </p> */}
                    <form onSubmit={handleSubmit}>
                      <label className="ohnohoney" htmlFor="namehoney"></label>
                      <input
                        tabIndex="-1"
                        className="ohnohoney"
                        autoComplete="off"
                        type="text"
                        id="namehoney"
                        name="namehoney"
                        placeholder="Your name here"
                        value={formData.namehoney}
                        onChange={handleInputChange}
                      />
                      <label className="ohnohoney" htmlFor="emailhoney"></label>
                      <input
                        tabIndex="-1"
                        className="ohnohoney"
                        autoComplete="off"
                        type="email"
                        id="emailhoney"
                        name="emailhoney"
                        placeholder={t("Enter Your Email")}
                        value={formData.emailhoney}
                        onChange={handleInputChange}
                      />
                      <div className="form_group">
                        <input
                          type="text"
                          className="form_control"
                          placeholder={t("Full Name")}
                          name="customerName"
                          required
                          value={formData.customerName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form_group">
                        <input
                          type="email"
                          className="form_control"
                          placeholder={t("Email Address")}
                          name="customerEmail"
                          required
                          value={formData.customerEmail}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="form_group">
                        <textarea
                          className="form_control"
                          placeholder={t("Write Message")}
                          name="customermessage"
                          value={formData.customermessage}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>

                      <div className="form_group">
                        <button className="main-btn btn-green" type="submit">
                          {loadingFormData ? (
                            <>
                              <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                              />{" "}
                              {t("Loading")} ...
                            </>
                          ) : (
                            t("GET IN TOUCH")
                          )}
                        </button>
                      </div>
                    </form>
                    {!loadingFormData && success && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {t("Your Request Sent Successfully")}
                        </Alert>
                      </Snackbar>
                    )}{" "}
                    {errorFormDate && (
                      <Snackbar
                        open={open}
                        autoHideDuration={4000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="danger"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {errorFormDate}
                        </Alert>
                      </Snackbar>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar-widget-area pl-lg-40">
                <div className="sidebar-widget recent-post-widget mb-40 wow fadeInUp">
                  <h5 className="widget-title">
                    {t("Travco Travel Services")}{" "}
                  </h5>
                  <ul className="recent-post-list">
                    {service.otherServices?.map((service) => (
                      <li
                        className="post-thumbnail-content"
                        key={service.sevicesUrl}
                      >
                        <img
                          src={service.sevicesPhoto}
                          alt={service.servicesName}
                        />
                        <div className="post-title-date">
                          <h5>
                            <Link to={`/services/${service.sevicesUrl}`}>
                              {service.servicesName}
                            </Link>
                          </h5>
                          <span className="posted-on">
                            <Link to={`/services/${service.sevicesUrl}`}>
                              {service.servicesNameIntro}
                            </Link>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
