import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { fetchDestinationHandbook } from "../../data/destination-handbook/destinationHandbookSlice";
import { useTranslation } from "react-i18next";

const DestinationHandbook = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { destinationHandbook } = useSelector(
    (state) => state.destinationHandbook
  );

  useEffect(() => {
    dispatch(fetchDestinationHandbook(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>{destinationHandbook.pageDetails?.pageMetatagTitle}</title>
        <link
          rel="canonical"
          href={`https://travco.com/${language}/destination-handbook`}
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={destinationHandbook.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={destinationHandbook.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={destinationHandbook.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={destinationHandbook.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={destinationHandbook.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? destinationHandbook.pageDetails?.pageBannerMobile
                          : isTablet
                          ? destinationHandbook.pageDetails?.pageBannerTablet
                          : destinationHandbook.pageDetails?.pageBannerPc
                      }
                      alt={destinationHandbook.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {destinationHandbook.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {destinationHandbook.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="blog-standard-section pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-standard-wrapper mb-30">
                {destinationHandbook.blogs?.map((blog) => (
                  <div
                    className="blog-standard-post-item mb-50 wow fadeInUp"
                    key={blog.blogUrl}
                  >
                    <div className="post-thumbnail">
                      <img src={blog.blogPhoto} alt={blog.blogTitle} />
                    </div>
                    <div className="entry-content">
                      <div className="post-meta">
                        <ul>
                          <li>
                            <span>
                              <Link
                                to={`/destination-handbook/${blog.blogUrl}`}
                              >
                                <i className="rlr-icon-font flaticon-calendar me-2"></i>
                                {blog.blogDateTime}
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <h3 className="title">
                        <Link to={`/destination-handbook/${blog.blogUrl}`}>
                          {blog.blogTitle}
                        </Link>
                      </h3>
                      <p> {blog.blogShortDescription}</p>
                      <Link to={`/destination-handbook/${blog.blogUrl}`}>
                        {t("Read More")}{" "}
                        <i className="fa fa-angle-double-right"></i>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar-widget-area pl-lg-40">
                <div className="sidebar-widget tag-cloud-widget mb-40 wow fadeInUp">
                  <h5 className="widget-title">{t("Where to go in Egypt")} </h5>
                  {destinationHandbook.destinations?.map((destination) => (
                    <Link
                      key={destination.destinationUrl}
                      to={`/destination/egypt/${destination.destinationUrl}`}
                    >
                      {destination.destinationNameSys}{" "}
                    </Link>
                  ))}
                </div>
                <div className="sidebar-widget category-widget mb-30 wow fadeInUp">
                  <h5 className="widget-title">{t("Our Services")} </h5>
                  <ul className="category-nav">
                    <li>
                      <Link to="/packages">
                        {t("Travel Packages")}{" "}
                        <i className="rlr-icon-font flaticon-chevron"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/tours-activities">
                        {t("Tours & Activities")}{" "}
                        <i className="rlr-icon-font flaticon-chevron"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/nile-cruises">
                        {t("Nile Cruises")}{" "}
                        <i className="rlr-icon-font flaticon-chevron"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/transfers-and-airport-services">
                        {t("Transfers and Airport Services")}
                        <i className="rlr-icon-font flaticon-chevron"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/visa">
                        {t("Meet & Assist + Visa")}{" "}
                        <i className="rlr-icon-font flaticon-chevron"></i>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sidebar-widget recent-post-widget mb-40 wow fadeInUp">
                  <h5 className="widget-title">{t("Recent News")} </h5>
                  <ul className="recent-post-list">
                    {destinationHandbook.news?.map((news) => (
                      <li className="post-thumbnail-content" key={news.newsUrl}>
                        <img
                          src={news.newsPhoto}
                          alt={news.newsTitleSys}
                          loading="lazy"
                        />
                        <div className="post-title-date">
                          <h5>
                            <Link to={`/travco-news/${news.newsUrl}`}>
                              {news.newsTitleSys}
                            </Link>
                          </h5>
                          <span className="posted-on">
                            <i className="rlr-icon-font flaticon-calendar"></i>
                            <Link to={`/travco-news/${news.newsUrl}`}>
                              {news.newsDateTime}
                            </Link>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DestinationHandbook;
