import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { fetchVisa } from "../../data/visa/visaSlice";
import { useTranslation } from "react-i18next";

const Visa = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);

  const { visa, loading, error } = useSelector((state) => state.visa);
  useEffect(() => {
    dispatch(fetchVisa(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>{visa?.pageMetatagTitle || "Visa"}</title>
        <link rel="canonical" href={`https://travco.com/${language}/visa`} />

        <meta name="description" content={visa?.pageMetatagDescription} />
        <meta property="og:title" content={visa?.metatagTitleFacebook} />
        <meta
          property="og:description"
          content={visa?.metatagDescriptionFacebook}
        />
        <meta name="twitter:title" content={visa?.metatagTitleTwitter} />
        <meta
          name="twitter:description"
          content={visa?.metatagDescriptionTwitter}
        />
        <style>{`
        .divider {
        border-bottom: 4px solid #e60000;
        opacity: 0.8;
        width: 70px;
        margin: 20px 0 40px 0;
    }
.SiteFontP {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
}
    ul {
        padding-left: 23px;
    }

      .about-section  ul li {
            margin-bottom: 15px;
            font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.5;
        }

    li::marker {
        color: #e60000;
        background-color: #e60000;
    }

    .about-section a {
        color: #e60000 !important;
    }
        `}</style>
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    {" "}
                    <img
                      src={
                        isMobile
                          ? visa.pageBannerMobile
                          : isTablet
                          ? visa.pageBannerTablet
                          : visa.pageBannerPc
                      }
                      alt={visa.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {visa.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header align-items-start">
                        <Link
                          className="main-btn btn-green  w-auto"
                          to="/entry-visa"
                        >
                          {t("Book Now")}{" "}
                          <i className="fa fa-angle-double-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {visa.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section className="about-section pt-10  container">
        <div className="">
          <div className="row align-items-center">
            <div className="col-12">
              <div
                className="about-content-box mb-7 wow fadeInLeft"
                dangerouslySetInnerHTML={{
                  __html: visa.pageText,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="form_group col-12 text-center m-auto  pt-0 pt-lg-5">
        <Link className="main-btn btn-green" to="/entry-visa">
          {t("Book Now")} <i className="fa fa-angle-double-right"></i>
        </Link>
      </div>
    </>
  );
};

export default Visa;
