import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchTransfers } from "../data/transfers/transfersSlice";
import { fetchTransfersSearch } from "../data/transfers/transfersSearchSlice";
import { useTranslation } from "react-i18next";
import { ru, ar, pt, es } from "date-fns/locale";
import { Helmet } from "react-helmet";
const BookingWidget = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { t, i18n } = useTranslation();
  const getLocale = () => {
    switch (i18n.language) {
      case "ru":
        return ru;
      case "pt":
        return pt;
      case "es":
        return es;
      case "ar":
        return ar;
      default:
        return undefined; // Default to English if no match
    }
  };
  const currency = useSelector((state) => state.currency.currency);
  const { data } = useSelector((state) => state.home);
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const { cstDataForTransfer, loadingCstData, errorCstData } = useSelector(
    (state) => state.cstDataForTransfer
  );
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  /* ____________________________________________________________________________________*/
  //Tours
  const [startTourDate, setStartTourDate] = useState(addDays(new Date(), 4));
  const [tourDestinationId, setTourDestinationId] = useState(0);
  const [adultsTour, setAdultsTour] = useState(2);
  const [childrenTour, setChildrenTour] = useState(0);
  const handleSubmitTours = (e) => {
    e.preventDefault();
    localStorage.setItem("Tours_startTourDate", formatDate(startTourDate));
    localStorage.setItem("Tours_tourDestinationId", tourDestinationId);
    localStorage.setItem("Tours_adultsTour", adultsTour);
    localStorage.setItem("Tours_childrenTour", childrenTour);

    navigate(`search/tours-activities`);
  };
  /* ____________________________________________________________________________________*/
  //Packages
  const [packageStartDate, setPackageStartDate] = useState(
    addDays(new Date(), 14)
  );
  const [packageDestinationId, setPackageDestinationId] = useState("0");
  const [roomType, setRoomType] = useState("1");
  const [roomNumber, setRoomNumber] = useState("1");
  const handleSubmitPackages = (e) => {
    e.preventDefault();
    localStorage.setItem("Packages_packageDestinationId", packageDestinationId);
    localStorage.setItem("Packages_dateValues", formatDate(packageStartDate));
    localStorage.setItem("Packages_roomNumber", roomNumber);
    localStorage.setItem("Packages_roomType", roomType);

    navigate(`search/packages`);
  };
  /* ____________________________________________________________________________________*/
  // Transfers
  const [destinationValue, setDestinationValue] = useState(0);
  const [DateValues, setDateValues] = useState(addDays(new Date(), 4));
  const [participantNumber, setParticipantNumber] = useState(1);

  const handleSubmitTransfers = (e) => {
    e.preventDefault();
    localStorage.setItem("Transfers_DateValues", formatDate(DateValues));
    localStorage.setItem("Transfers_participantNumber", participantNumber);
    localStorage.setItem("Transfers_destinationValue", destinationValue);

    navigate(`search/transfers-and-airport-services`);
  };
  // form hotels
  const [destinationHotels, setDestinationHotels] = useState("");

  const [startDateHotels, setStartDateHotels] = useState(
    addDays(new Date(), 0)
  );
  const [endDateHotels, setEndDateHotels] = useState(
    addDays(new Date().setDate(new Date().getDate() + 1), 0)
  );

  useEffect(() => {
    if (startDateHotels) {
      const nextDay = new Date(startDateHotels);
      nextDay.setDate(nextDay.getDate() + 1);
      setEndDateHotels(nextDay);
    }
  }, [startDateHotels]);

  const [destinationId, setDestinationId] = useState("");
  const [nationality, setNationality] = useState("");
  const [roomsHotels, setRoomsHotels] = useState(1);
  const [adultsHotels1R, setAdultsHotels1R] = useState(2);
  const [adultsHotels2R, setAdultsHotels2R] = useState(2);
  const [adultsHotels3R, setAdultsHotels3R] = useState(2);
  const [childrenHotels1R, setChildrenHotels1R] = useState(0);
  const [childrenHotels2R, setChildrenHotels2R] = useState(0);
  const [childrenHotels3R, setChildrenHotels3R] = useState(0);
  const [childrenAge1R1, setChildrenAge1R1] = useState(0);
  const [childrenAge1R2, setChildrenAge1R2] = useState(0);
  const [childrenAge2R1, setChildrenAge2R1] = useState(0);
  const [childrenAge2R2, setChildrenAge2R2] = useState(0);
  const [childrenAge3R1, setChildrenAge3R1] = useState(0);
  const [childrenAge3R2, setChildrenAge3R2] = useState(0);
  useEffect(() => {
    if (roomsHotels == 1) {
      setAdultsHotels2R(0);
      setAdultsHotels3R(0);
      setChildrenHotels2R(0);
      setChildrenHotels3R(0);
      if (childrenHotels1R == 1) {
        setChildrenAge1R1(childrenAge1R1 || "2");
      } else if (childrenHotels1R == 2) {
        setChildrenAge1R1(childrenAge1R1 || "2");
        setChildrenAge1R2(childrenAge1R2 || "2");
      }
    } else if (roomsHotels == 2) {
      setAdultsHotels2R(adultsHotels2R || "2");
      setAdultsHotels3R(0);
      setChildrenHotels2R(childrenHotels2R || "0");
      setChildrenHotels3R(0);
      if (childrenHotels2R != 0 || childrenHotels1R != 0) {
        if (childrenHotels1R == 1) {
          setChildrenAge1R1(childrenAge1R1 || "2");
        } else if (childrenHotels1R == 2) {
          setChildrenAge1R1(childrenAge1R1 || "2");
          setChildrenAge1R2(childrenAge1R2 || "2");
        }
        if (childrenHotels2R == 1) {
          setChildrenAge2R1(childrenAge2R1 || "2");
        } else if (childrenHotels2R == 2) {
          setChildrenAge2R1(childrenAge2R1 || "2");
          setChildrenAge2R2(childrenAge2R2 || "2");
        }
      }
    } else {
      setAdultsHotels2R(adultsHotels2R || "2");
      setAdultsHotels3R(adultsHotels3R || "2");
      setChildrenHotels2R(childrenHotels2R || "0");
      setChildrenHotels3R(childrenHotels3R || "0");
      if (
        childrenHotels3R != 0 ||
        childrenHotels2R != 0 ||
        childrenHotels1R != 0
      ) {
        if (childrenHotels1R == 1) {
          setChildrenAge1R1(childrenAge1R1 || "2");
        } else if (childrenHotels1R == 2) {
          setChildrenAge1R1(childrenAge1R1 || "2");
          setChildrenAge1R2(childrenAge1R2 || "2");
        }
        if (childrenHotels2R == 1) {
          setChildrenAge2R1(childrenAge2R1 || "2");
        } else if (childrenHotels2R == 2) {
          setChildrenAge2R1(childrenAge2R1 || "2");
          setChildrenAge2R2(childrenAge2R2 || "2");
        }
      }
      if (childrenHotels3R == 1) {
        setChildrenAge3R1(childrenAge3R1 || "2");
      } else if (childrenHotels3R == 2) {
        setChildrenAge3R1(childrenAge3R1 || "2");
        setChildrenAge3R2(childrenAge3R2 || "2");
      }
    }
  }, [
    adultsHotels2R,
    adultsHotels3R,
    childrenAge1R1,
    childrenAge1R2,
    childrenAge2R1,
    childrenAge2R2,
    childrenAge3R1,
    childrenAge3R2,
    childrenHotels1R,
    childrenHotels2R,
    childrenHotels3R,
    roomsHotels,
  ]);

  const { hotelsAvailability, loading, error, isError } = useSelector(
    (state) => state.hotelsAvailability
  );
  const [validated, setValidated] = useState(false);
  const handleHotelSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    const hotelData = {
      AllInclusive: "",
      BedAndBreakfast: "",
      fullboard: "",
      halfboard: "",
      RoomOnly: "",
      NoMeal: "",
      chk_3Stars: "",
      chk_4Stars: "",
      chk_5Stars: "",
      DestinationId: destinationId,
      StartDate: startDateHotels.toISOString().split("T")[0],
      EndDate: endDateHotels.toISOString().split("T")[0],
      nationality: nationality,
      ddl_RoomsNo: roomsHotels,
      ddl_R1ANO: adultsHotels1R,
      ddl_R1CNO: childrenHotels1R,
      ddl_R1CA1: childrenAge1R1,
      ddl_R1CA2: childrenAge1R2,
      ddl_R2ANO: adultsHotels2R,
      ddl_R2CNO: childrenHotels2R,
      ddl_R2CA1: childrenAge2R1,
      ddl_R2CA2: childrenAge2R2,
      ddl_R3ANO: adultsHotels3R,
      ddl_R3CNO: childrenHotels3R,
      ddl_R3CA1: childrenAge3R1,
      ddl_R3CA2: childrenAge3R2,
      currency: currency,
    };
    // dispatch(fetchHotelsAvailability(hotelData));
    if (!loading && !isError) {
      localStorage.setItem("destinationId", destinationId);
      localStorage.setItem(
        "startDateHotels",
        startDateHotels.toISOString().split("T")[0]
      );
      localStorage.setItem(
        "endDateHotels",
        endDateHotels.toISOString().split("T")[0]
      );
      localStorage.setItem("nationality", nationality);
      localStorage.setItem("roomsHotels", roomsHotels);
      localStorage.setItem("adultsHotels1R", adultsHotels1R);
      localStorage.setItem("adultsHotels2R", adultsHotels2R);
      localStorage.setItem("adultsHotels3R", adultsHotels3R);
      localStorage.setItem("childrenHotels1R", childrenHotels1R);
      localStorage.setItem("childrenAge1R1", childrenAge1R1);
      localStorage.setItem("childrenAge1R2", childrenAge1R2);
      localStorage.setItem("childrenHotels2R", childrenHotels2R);
      localStorage.setItem("childrenAge2R1", childrenAge2R1);
      localStorage.setItem("childrenAge2R2", childrenAge2R2);
      localStorage.setItem("childrenHotels3R", childrenHotels3R);
      localStorage.setItem("childrenAge3R1", childrenAge3R1);
      localStorage.setItem("childrenAge3R2", childrenAge3R2);
      navigate("/hotels-availability");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Your form submission logic here
  };

  // cruises
  const [destinationCruises, setDestinationCruises] = useState("");
  const [availableDates, setAvailableDates] = useState([]);

  const [ItineraryId, setItineraryId] = useState("");
  const [startDateCruises, setStartDateCruises] = useState("");
  const [startDateCruisesMenu, setStartDateCruisesMenu] = useState("");
  const [endDateCruisesMenu, setEndDateCruisesMenu] = useState("");
  const [adultsCruises, setAdultsCruises] = useState(2);
  const [childrenCruises, setChildrenCruises] = useState(0);
  const handleItineraryChange = async (e) => {
    const itineraryTypeId = e.target.value;
    setItineraryId(itineraryTypeId);

    try {
      const response = await axios.get(
        `https://api.travco.com/apiTITSol/TTG24_NileCruise/GetOperationDatesByItineraryType?ItineraryTypeId=${itineraryTypeId}`
      );
      const dates = response.data.formatedDate.map((date) => new Date(date));
      setAvailableDates(dates);
      setStartDateCruises(addDays(dates[0], 7) || null);
      setStartDateCruisesMenu(addDays(dates[0], 7));
      setEndDateCruisesMenu(dates[dates.length - 1]);
    } catch (error) {
      console.error("Error fetching dates: ", error);
    }
  };

  const isDateAvailable = (date) => {
    return availableDates.some((d) => d.toDateString() === date.toDateString());
  };

  const handleSubmitCruises = (e) => {
    e.preventDefault();
    // dispatch(
    //   fetchBookingPackage({
    //     productId,
    //     numberOfchildrens1,
    //     currency,
    //     numberOfchildrens2,
    //     roomType,
    //     roomNumber,
    //     startDate: formatDate(startDate),
    //   })
    // );
    localStorage.setItem("cruises_ItineraryId", ItineraryId);
    localStorage.setItem(
      "cruises_startDateCruises",
      formatDate(startDateCruises)
    );
    localStorage.setItem("cruises_adultsCruises", adultsCruises);
    localStorage.setItem("cruises_childrenCruises", childrenCruises);

    navigate(`/cruisesbooking`);
  };
  return (
    <>
      <Helmet>
        <style>
          {`
            .search-form select{
            text-wrap: wrap;
            }
            `}
        </style>
      </Helmet>
      <section className="search-form container">
        <div className="form-widget rlr-section__my">
          <div className="row">
            <ul
              className="nav tab-nav-rounded d-flex pb-0 tab-nav align-items-center justify-content-center w-100"
              role="tablist"
            >
              <li className="nav-item" style={{ width: "auto" }}>
                <a
                  className="nav-link active font-weight-medium pl-md-5 pl-3"
                  id="pills-three-example2-tab"
                  data-toggle="pill"
                  href="#pills-three-example2"
                  role="tab"
                  aria-controls="pills-three-example2"
                  aria-selected="true"
                >
                  <div className="d-flex flex-column flex-md-row position-relative text-white align-items-center">
                    <figure className="ie-height-40 d-md-block mr-md-3">
                      <i className="icon rlr-icon-font flaticon-parachute font-size-3"></i>
                    </figure>
                    <span
                      className="tabtext mt-2 mt-md-0 font-weight-semi-bold"
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Tours & Activities")}
                    </span>
                  </div>
                </a>
              </li>
              <li className="nav-item" style={{ width: "auto" }}>
                <a
                  className="nav-link font-weight-medium"
                  id="pills-one-example2-tab"
                  data-toggle="pill"
                  href="#pills-one-example2"
                  role="tab"
                  aria-controls="pills-one-example2"
                  aria-selected="true"
                >
                  <div className="d-flex flex-column flex-md-row position-relative text-white align-items-center">
                    <figure className="ie-height-40 d-md-block mr-md-3">
                      <i className="icon font-size-3 rlr-icon-font flaticon-destination-1"></i>
                    </figure>
                    <span
                      className="tabtext mt-2 mt-md-0 font-weight-semi-bold"
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Packages")}
                    </span>
                  </div>
                </a>
              </li>
              <li className="nav-item" style={{ width: "auto" }}>
                <a
                  className="nav-link font-weight-medium"
                  id="pills-two-example2-tab"
                  data-toggle="pill"
                  href="#pills-two-example2"
                  role="tab"
                  aria-controls="pills-two-example2"
                  aria-selected="true"
                >
                  <div className="d-flex flex-column flex-md-row position-relative text-white align-items-center">
                    <figure className="ie-height-40 d-md-block mr-md-3">
                      <i
                        className="icon rlr-icon-font flaticon-car"
                        style={{ fontSize: "50px", marginBottom: "-10px" }}
                      ></i>
                    </figure>
                    <span
                      className="tabtext mt-2 mt-md-0 font-weight-semi-bold"
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Transfers")}
                    </span>
                  </div>
                </a>
              </li>
              <li className="nav-item" style={{ width: "auto" }}>
                <a
                  className="nav-link  font-weight-medium"
                  id="pills-four-example2-tab"
                  data-toggle="pill"
                  href="#pills-four-example2"
                  role="tab"
                  aria-controls="pills-four-example2"
                  aria-selected="true"
                >
                  <div className="d-flex flex-column flex-md-row position-relative text-white align-items-center">
                    <figure className="ie-height-40 d-md-block mr-md-3">
                      <i className="icon fa-mytravel-hotel font-size-3"></i>
                    </figure>
                    <span
                      className="tabtext mt-2 mt-md-0 font-weight-semi-bold"
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Hotels")}
                    </span>
                  </div>
                </a>
              </li>
              <li className="nav-item" style={{ width: "auto" }}>
                <a
                  className="nav-link font-weight-medium"
                  id="pills-five-example5-tab"
                  data-toggle="pill"
                  href="#pills-five-example5"
                  role="tab"
                  aria-controls="pills-four-example5"
                  aria-selected="true"
                >
                  <div className="d-flex flex-column flex-md-row position-relative text-white align-items-center">
                    <figure className="ie-height-40 d-md-block mr-md-3">
                      <i className="icon rlr-icon-font flaticon-inclusions font-size-3"></i>
                    </figure>
                    <span
                      className="tabtext mt-2 mt-md-0 font-weight-semi-bold"
                      style={{ textShadow: "1px 1px 2px #000" }}
                    >
                      {t("Nile Cruises")}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
            <div className="tab-content hero-tab-pane">
              {/* Tours  */}
              <div
                className="tab-pane fade px-lg-5 mx-lg-5 active show"
                id="pills-three-example2"
                role="tabpanel"
                aria-labelledby="pills-three-example2-tab"
              >
                <div className="card border-0 tab-shadow">
                  <div className="card-body">
                    <form className="js-validate" onSubmit={handleSubmitTours}>
                      <div className="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end">
                        <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 destinationss">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Destinations")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i
                              className="icon flaticon-map-marker"
                              style={{ color: "#e60000" }}
                            ></i>
                            <select
                              name="tourDestinationValue"
                              id="tourDestinationValue"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              value={tourDestinationId}
                              onChange={(e) =>
                                setTourDestinationId(e.target.value)
                              }
                              title="Where are you going?"
                              required
                            >
                              <option value="0">{t("All")}</option>
                              {data.tourDestinations?.map((destination) => (
                                <option
                                  ket={destination.destinationId}
                                  value={destination.destinationId}
                                >
                                  {destination.destinationNameSys}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-3dot7 col-xl-3gdot5 mb-4 mb-lg-0 dates">
                          <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                            {t("Date")}
                          </span>
                          <div className="border-bottom border-width-2 border-color-1">
                            <div
                              id="datepickerWrapperFromThree"
                              className="u-datepicker input-group"
                            >
                              <div className="input-group-prepend">
                                <span className="d-flex align-items-center mr-2">
                                  <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                </span>
                              </div>
                              <DatePicker
                                selected={startTourDate}
                                onChange={(date) => setStartTourDate(date)}
                                className="ps-4 border-0 form-control"
                                name="DateValues"
                                id="TourDateID"
                                dateFormat="dd/MM/yyyy"
                                minDate={addDays(new Date(), 4)}
                                locale={getLocale()} // This will set the locale based on the selected language
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 adults">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Adults")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i className="rlr-icon-font flaticon-add-user text-primary"></i>
                            <select
                              id="AdultNo"
                              name="NumberOfAdults"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              value={adultsTour}
                              onChange={(e) => setAdultsTour(e.target.value)}
                              title={t("Adult")}
                              required
                            >
                              <option value="1">1 {t("Adult")}</option>
                              <option value="2">2 {t("Adults")}</option>
                              <option value="3">3 {t("Adults")}</option>
                              <option value="4">4 {t("Adults")}</option>
                              <option value="5">5 {t("Adults")}</option>
                              <option value="6">6 {t("Adults")}</option>
                              <option value="7">7 {t("Adults")}</option>
                              <option value="8">8 {t("Adults")}</option>
                              <option value="9">9 {t("Adults")}</option>
                              <option value="10">10 {t("Adults")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 child">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Child")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i className="rlr-icon-font flaticon-add-user text-primary"></i>
                            <select
                              id="ChildNo"
                              name="NumberOfchildrens"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              value={childrenTour}
                              onChange={(e) => setChildrenTour(e.target.value)}
                              title="Child"
                              required
                            >
                              <option value="0">0</option>
                              <option value="1">1 {t("Child")}</option>
                              <option value="2">2 {t("Child")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-xl-2 align-self-lg-end text-md-right">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className="mr-2"
                            />
                            {t("Search")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* Packages  */}
              <div
                className="tab-pane fade px-lg-5 mx-lg-5"
                id="pills-one-example2"
                role="tabpanel"
                aria-labelledby="pills-one-example2-tab"
              >
                <div className="card border-0 tab-shadow">
                  <div className="card-body">
                    <form
                      className="js-validate"
                      onSubmit={handleSubmitPackages}
                    >
                      <input type="hidden" value="en" name="LangCode" />
                      <input type="hidden" value="Packages" name="Type" />
                      <div className="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end">
                        <div className="col-sm-12 col-lg-2 col-xl-2 mb-4 mb-lg-0 destinationss">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Destinations")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i
                              className="icon flaticon-map-marker"
                              style={{ color: "#e60000" }}
                            ></i>
                            <select
                              name="packagesDestinationValue"
                              id="packagesDestinationValue"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              value={packageDestinationId}
                              onChange={(e) =>
                                setPackageDestinationId(e.target.value)
                              }
                              title="Where are you going?"
                              required
                            >
                              <option value="0"> {t("All")}</option>
                              {data.packageDestinations?.map((packages) => (
                                <option
                                  key={packages.destinationId}
                                  value={packages.destinationId}
                                >
                                  {packages.destinationNameSys}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-2 col-xl-2 mb-4 mb-lg-0 dates">
                          <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                            {t("Date")}
                          </span>
                          <div className="border-bottom border-width-2 border-color-1">
                            <div
                              id="datepickerWrapperFromOne"
                              className="u-datepicker input-group"
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "stretch",
                                width: "103%",
                              }}
                            >
                              <div className="input-group-prepend">
                                <span className="d-flex align-items-center mr-2">
                                  <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                </span>
                              </div>
                              <DatePicker
                                selected={packageStartDate}
                                onChange={(date) => setPackageStartDate(date)}
                                className="ps-4 border-0 form-control"
                                name="DateValues"
                                minDate={addDays(new Date(), 14)}
                                dateFormat="dd/MM/yyyy"
                                locale={getLocale()} // This will set the locale based on the selected language
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-6 mb-4 mb-lg-0 adults">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Room Type")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i className="rlr-icon-font flaticon-hotel-bed text-primary"></i>
                            <select
                              id="RoomType"
                              name="RoomType"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              value={roomType}
                              onChange={(e) => setRoomType(e.target.value)}
                              title="Room"
                              required
                            >
                              <option value="1">{t("Single Room")}</option>
                              <option value="2">{t("Double Room")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-6 mb-4 mb-lg-0 child">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Number Of Rooms")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i className="rlr-icon-font flaticon-room font-size-25 text-primary"></i>
                            <select
                              id="RoomNumber"
                              name="RoomNumber"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              value={roomNumber}
                              onChange={(e) => setRoomNumber(e.target.value)}
                              required
                            >
                              <option value="1">1 {t("Room")}</option>
                              <option value="2">2 {t("Rooms")}</option>
                              <option value="3">3 {t("Rooms")}</option>
                              <option value="4">4 {t("Rooms")}</option>
                              <option value="5">5 {t("Rooms")}</option>
                              <option value="6">6 {t("Rooms")}</option>
                              <option value="7">7 {t("Rooms")}</option>
                              <option value="8">8 {t("Rooms")}</option>
                              <option value="9">9 {t("Rooms")}</option>
                              <option value="10">10 {t("Rooms")}</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-xl-2 align-self-lg-end text-md-right">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className="mr-2"
                            />
                            {t("Search")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* Transfers  */}
              <div
                className="tab-pane fade px-lg-5 mx-lg-5"
                id="pills-two-example2"
                role="tabpanel"
                aria-labelledby="pills-two-example2-tab"
              >
                <div className="card border-0 tab-shadow">
                  <div className="card-body">
                    <form
                      className="js-validate"
                      onSubmit={handleSubmitTransfers}
                    >
                      <div className="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end">
                        <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 ">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Destinations")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i
                              className="icon flaticon-map-marker"
                              style={{ color: "#e60000" }}
                            ></i>
                            <select
                              value={destinationValue}
                              onChange={(e) =>
                                setDestinationValue(e.target.value)
                              }
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              title={t("Where are you going?")}
                              required
                            >
                              <option value="0">{t("All")}</option>
                              {data.transferDestinations?.map((transfer) => (
                                <option
                                  key={transfer.destinationId}
                                  value={transfer.destinationId}
                                >
                                  {transfer.destinationNameSys}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-3dot7 col-xl-3gdot5 mb-4 mb-lg-0 ">
                          <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                            {t("Date")}
                          </span>

                          <div className="border-bottom border-width-2 border-color-1">
                            <div
                              id="datepickerWrapperFromOne"
                              className="u-datepicker input-group"
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "stretch",
                                width: "103%",
                              }}
                            >
                              <div className="input-group-prepend">
                                <span className="d-flex align-items-center mr-2">
                                  <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                </span>
                              </div>
                              <DatePicker
                                selected={DateValues}
                                onChange={(date) => setDateValues(date)}
                                className="ps-4 border-0 form-control"
                                minDate={addDays(new Date(), 4)}
                                dateFormat="dd/MM/yyyy"
                                locale={getLocale()} // This will set the locale based on the selected language
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-xl-3 mb-4 mb-lg-0 dropdown-custom">
                          <span className="d-block text-gray-1 text-left font-weight-normal mb-0 fw-bold">
                            {t("Participant")}
                          </span>
                          <select
                            value={participantNumber}
                            onChange={(e) =>
                              setParticipantNumber(e.target.value)
                            }
                            className="form_control"
                            style={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                              borderTop: "0px",
                              borderRight: "0px",
                              borderLeft: "0px",
                            }}
                          >
                            {[...Array(10)].map((_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-12 col-xl-2 align-self-lg-end text-md-right">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className="mr-2"
                            />
                            {t("Search")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* Hotels  */}
              <div
                className="tab-pane   fade px-lg-5 mx-lg-5 w-100 h-100 "
                id="pills-four-example2"
                role="tabpanel"
                aria-labelledby="pills-four-example2-tab"
              >
                <div className="card border-0 tab-shadow">
                  <div className="card-body">
                    <Form
                      validated={validated}
                      onSubmit={handleHotelSubmit}
                      className="js-validate"
                    >
                      <div className="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-xl-10">
                          <div className="row searcher-row">
                            <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 destinationsss dat">
                              <label className="d-block text-gray-1 font-weight-normal text-left mb-0">
                                {t("Destinations")}
                              </label>
                              <div className="d-flex align-items-center">
                                <i
                                  className="icon  flaticon-map-marker"
                                  style={{ color: "#e60000" }}
                                ></i>
                                <Form.Select
                                  // id="DestinationValue"
                                  // name="ddl_DestinationID"
                                  value={destinationId}
                                  onChange={(e) =>
                                    setDestinationId(e.target.value)
                                  }
                                  className="form_control "
                                  style={{
                                    borderBottom:
                                      "1px solid rgba(0, 0, 0, 0.1)",
                                    borderTop: "0px",
                                    borderRight: "0px",
                                    borderLeft: "0px",
                                  }}
                                  title="Where are you going?"
                                  required
                                >
                                  <option value="" hidden>
                                    {t("Select Destination")}
                                  </option>
                                  {data.hotelDestinations?.map((hotel) => (
                                    <option
                                      key={hotel.destinationWsid}
                                      value={hotel.destinationWsid}
                                    >
                                      {hotel.destinationName}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-3dot7 col-xl-3gdot5 mb-4 mb-lg-0 dat">
                              <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                                {t("Start Date")}
                              </span>

                              <div className="border-bottom border-width-2 border-color-1">
                                <div
                                  id="datepickerWrapperFromOne"
                                  className="u-datepicker input-group"
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "stretch",
                                    width: "130%",
                                  }}
                                >
                                  <div className="input-group-prepend">
                                    <span className="d-flex align-items-center mr-2">
                                      <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                    </span>
                                  </div>
                                  <DatePicker
                                    selected={startDateHotels}
                                    onChange={(date) => {
                                      setStartDateHotels(date);
                                      localStorage.setItem(
                                        "startDateHotels",
                                        startDateHotels
                                      );
                                    }}
                                    className="ps-4  border-0 form-control"
                                    id="HotelDateID"
                                    required
                                    minDate={addDays(new Date(), 0)}
                                    locale={getLocale()} // This will set the locale based on the selected language
                                    dateFormat="dd/MM/yyyy" // Default format for the selected locale
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-3dot7 col-xl-3gdot5 mb-4 mb-lg-0 dat">
                              <span className="d-block text-gray-1 text-left font-weight-normal mb-0">
                                {t("End Date")}
                              </span>
                              <div className="border-bottom border-width-2 border-color-1">
                                <div
                                  id="datepickerWrapperFromOne"
                                  className="u-datepicker input-group"
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "stretch",
                                    width: "130%",
                                  }}
                                >
                                  <div className="input-group-prepend">
                                    <span className="d-flex align-items-center mr-2">
                                      <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                    </span>
                                  </div>
                                  <DatePicker
                                    selected={endDateHotels}
                                    onChange={(date) => {
                                      setEndDateHotels(date);
                                      localStorage.setItem(
                                        "endDateHotels",
                                        endDateHotels
                                      );
                                    }}
                                    className="ps-4  border-0 form-control"
                                    dateFormat="dd/MM/yyyy"
                                    id="HotelDateID2"
                                    required
                                    minDate={
                                      startDateHotels
                                        ? new Date(
                                            new Date(startDateHotels).setDate(
                                              startDateHotels.getDate() + 1
                                            )
                                          )
                                        : new Date()
                                    }
                                    locale={getLocale()} // This will set the locale based on the selected language
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-xs-12 col-sm-3 col-md-12 col-xl-2 mt-3 mt-xl-0"
                              style={{ marginTop: "0px" }}
                            >
                              <div className="room-selector js-auto-form-param">
                                <span
                                  className="d-block text-gray-1 text-left font-weight-normal mb-0"
                                  style={{
                                    marginTop: "-5px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  {t("Rooms")}
                                </span>
                                <button
                                  type="button"
                                  className="btn btn-secondary room-selector-button"
                                  data-toggle="popover"
                                  data-placement="bottom"
                                  data-content-element="#hotel-searcher-_ctl1__ctl1__ctl1_pageBody_pageBody_searcher_ctlMultiSearcher__ctl1_ctlRoomSelector-room-selector-box"
                                  data-title={t("Rooms")}
                                  onClick={() =>
                                    document
                                      .getElementById("view")
                                      .classList.remove("hidden")
                                  }
                                >
                                  <span className="counter room-counter">
                                    <i className="fa fa-mytravel-home text-primary fs-5 "></i>
                                    <span
                                      className="amount text-dark me-2"
                                      id="roomNum"
                                    >
                                      {roomsHotels}
                                    </span>
                                  </span>
                                  <span className="counter pax-counter">
                                    <i className="rlr-icon-font flaticon-add-user  text-primary fs-5"></i>
                                    <span
                                      className="amount text-dark"
                                      id="adultAndChildNum"
                                    >
                                      {roomsHotels == 1
                                        ? Number(adultsHotels1R) +
                                          Number(childrenHotels1R)
                                        : roomsHotels == 2
                                        ? Number(adultsHotels1R) +
                                          Number(adultsHotels2R) +
                                          Number(childrenHotels1R) +
                                          Number(childrenHotels2R)
                                        : Number(adultsHotels1R) +
                                          Number(adultsHotels2R) +
                                          Number(adultsHotels3R) +
                                          Number(childrenHotels1R) +
                                          Number(childrenHotels2R) +
                                          Number(childrenHotels3R)}
                                    </span>
                                  </span>
                                </button>
                                <div
                                  id="hotel-searcher-_ctl1__ctl1__ctl1_pageBody_pageBody_searcher_ctlMultiSearcher__ctl1_ctlRoomSelector-room-selector-box"
                                  className="room-selector-box "
                                >
                                  <div id="view" className="hidden bottom in">
                                    <div className="rooms-container w-100 d-block">
                                      {roomsHotels == 1 ? (
                                        <>
                                          <div className="room d-flex align-items-center flex-wrap">
                                            <div className="rooms-container ">
                                              <label className="form-label">
                                                {t("Rooms")}
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setRoomsHotels(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "roomsHotels",
                                                    roomsHotels
                                                  );
                                                }}
                                                value={roomsHotels}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option defaultValue={1}>
                                                  1
                                                </option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                              </select>
                                            </div>
                                            <div className="adults-container">
                                              <label className="form-label">
                                                {t("Adults")} (12+)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setAdultsHotels1R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "adultsHotels1R",
                                                    adultsHotels1R
                                                  );
                                                }}
                                                value={adultsHotels1R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="1">1</option>
                                                <option defaultValue="2">
                                                  2
                                                </option>
                                                <option value="3">3</option>
                                              </select>
                                            </div>
                                            <div className="children-container">
                                              <label className="form-label">
                                                {t("Children")} (2-11.99)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setChildrenHotels1R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "childrenHotels1R",
                                                    childrenHotels1R
                                                  );
                                                }}
                                                value={childrenHotels1R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                              </select>
                                            </div>
                                            {childrenHotels1R > 1 ? (
                                              <div className="ages children-option-group">
                                                <label className="form-label">
                                                  {t("Ages")}
                                                </label>
                                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                                  {" "}
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge1R1(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge1R1",
                                                        childrenAge1R1
                                                      );
                                                    }}
                                                    value={childrenAge1R1}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge1R2(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge1R2",
                                                        childrenAge1R2
                                                      );
                                                    }}
                                                    value={childrenAge1R2}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            ) : childrenHotels1R >= 1 ? (
                                              <>
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    {t("Ages")}
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R1",
                                                          childrenAge1R1
                                                        );
                                                      }}
                                                      value={childrenAge1R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      ) : roomsHotels == 2 ? (
                                        <>
                                          <div className="room d-flex align-items-center flex-wrap">
                                            <div className="rooms-container">
                                              <label className="form-label">
                                                {t("Rooms")}
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setRoomsHotels(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "roomsHotels",
                                                    roomsHotels
                                                  );
                                                }}
                                                value={roomsHotels}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                              </select>
                                            </div>
                                            <div className="adults-container">
                                              <label className="form-label">
                                                {t("Adults")} (12+)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setAdultsHotels1R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "adultsHotels1R",
                                                    adultsHotels1R
                                                  );
                                                }}
                                                value={adultsHotels1R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="1">1</option>
                                                <option defaultValue="2">
                                                  2
                                                </option>
                                                <option value="3">3</option>
                                              </select>
                                            </div>
                                            <div className="children-container">
                                              <label className="form-label">
                                                {t("Children")} (2-11.99)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setChildrenHotels1R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "childrenHotels1R",
                                                    childrenHotels1R
                                                  );
                                                }}
                                                value={childrenHotels1R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                              </select>
                                            </div>
                                            {childrenHotels1R > 1 ? (
                                              <div className="ages children-option-group">
                                                <label className="form-label">
                                                  {t("Ages")}
                                                </label>
                                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                                  {" "}
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge1R1(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge1R1",
                                                        childrenAge1R1
                                                      );
                                                    }}
                                                    value={childrenAge1R1}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge1R2(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge1R2",
                                                        childrenAge1R2
                                                      );
                                                    }}
                                                    value={childrenAge1R2}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            ) : childrenHotels1R >= 1 ? (
                                              <>
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    {t("Ages")}
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R1",
                                                          childrenAge1R1
                                                        );
                                                      }}
                                                      value={childrenAge1R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <hr />
                                          <div className="room d-flex align-items-center flex-wrap">
                                            <div
                                              className="rooms-container"
                                              style={{
                                                visibility: "hidden",
                                              }}
                                            >
                                              <label className="form-label">
                                                {t("Rooms")}
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setRoomsHotels(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "roomsHotels",
                                                    roomsHotels
                                                  );
                                                }}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                              </select>
                                            </div>
                                            <div className="adults-container">
                                              <label className="form-label">
                                                {t("Adults")} (12+)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setAdultsHotels2R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "adultsHotels2R",
                                                    adultsHotels2R
                                                  );
                                                }}
                                                value={adultsHotels2R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="1">1</option>
                                                <option defaultValue="2">
                                                  2
                                                </option>
                                                <option value="3">3</option>
                                              </select>
                                            </div>
                                            <div className="children-container">
                                              <label className="form-label">
                                                {t("Children")} (2-11.99)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setChildrenHotels2R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "childrenHotelsR",
                                                    childrenHotels2R
                                                  );
                                                }}
                                                value={childrenHotels2R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                              </select>
                                            </div>
                                            {childrenHotels2R > 1 ? (
                                              <div className="ages children-option-group">
                                                <label className="form-label">
                                                  {t("Ages")}
                                                </label>
                                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                                  {" "}
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge2R1(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge2R1",
                                                        childrenAge2R1
                                                      );
                                                    }}
                                                    value={childrenAge2R1}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge2R2(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge2R2",
                                                        childrenAge2R2
                                                      );
                                                    }}
                                                    value={childrenAge2R2}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            ) : childrenHotels2R >= 1 ? (
                                              <>
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    {t("Ages")}
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge2R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge2R1",
                                                          childrenAge2R1
                                                        );
                                                      }}
                                                      value={childrenAge2R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="room d-flex align-items-center flex-wrap">
                                            <div className="rooms-container">
                                              <label className="form-label">
                                                {t("Rooms")}
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setRoomsHotels(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "roomsHotels",
                                                    roomsHotels
                                                  );
                                                }}
                                                value={roomsHotels}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                              </select>
                                            </div>
                                            <div className="adults-container">
                                              <label className="form-label">
                                                {t("Adults")} (12+)
                                              </label>
                                              <select
                                                onChange={(e) =>
                                                  setAdultsHotels1R(
                                                    e.target.value
                                                  )
                                                }
                                                value={adultsHotels1R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="1">1</option>
                                                <option defaultValue="2">
                                                  2
                                                </option>
                                                <option value="3">3</option>
                                              </select>
                                            </div>
                                            <div className="children-container">
                                              <label className="form-label">
                                                {t("Children")} (2-11.99)
                                              </label>
                                              <select
                                                onChange={(e) =>
                                                  setChildrenHotels1R(
                                                    e.target.value
                                                  )
                                                }
                                                value={childrenHotels1R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                              </select>
                                            </div>
                                            {childrenHotels1R > 1 ? (
                                              <div className="ages children-option-group">
                                                <label className="form-label">
                                                  {t("Ages")}
                                                </label>
                                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                                  {" "}
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge1R1(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge1R1",
                                                        childrenAge1R1
                                                      );
                                                    }}
                                                    value={childrenAge1R1}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge1R2(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge1R2",
                                                        childrenAge1R2
                                                      );
                                                    }}
                                                    value={childrenAge1R2}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            ) : childrenHotels1R >= 1 ? (
                                              <>
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    {t("Ages")}
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge1R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge1R1",
                                                          childrenAge1R1
                                                        );
                                                      }}
                                                      value={childrenAge1R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <hr />
                                          <div className="room d-flex align-items-center flex-wrap">
                                            <div
                                              className="rooms-container"
                                              style={{
                                                visibility: "hidden",
                                              }}
                                            >
                                              <label className="form-label">
                                                {t("Rooms")}
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setRoomsHotels(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "roomsHotels",
                                                    roomsHotels
                                                  );
                                                }}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                              </select>
                                            </div>
                                            <div className="adults-container">
                                              <label className="form-label">
                                                {t("Adults")} (12+)
                                              </label>
                                              <select
                                                onChange={(e) =>
                                                  setAdultsHotels2R(
                                                    e.target.value
                                                  )
                                                }
                                                value={adultsHotels2R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="1">1</option>
                                                <option defaultValue="2">
                                                  2
                                                </option>
                                                <option value="3">3</option>
                                              </select>
                                            </div>
                                            <div className="children-container">
                                              <label className="form-label">
                                                {t("Children")} (2-11.99)
                                              </label>
                                              <select
                                                onChange={(e) =>
                                                  setChildrenHotels2R(
                                                    e.target.value
                                                  )
                                                }
                                                value={childrenHotels2R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                              </select>
                                            </div>
                                            {childrenHotels2R > 1 ? (
                                              <div className="ages children-option-group">
                                                <label className="form-label">
                                                  {t("Ages")}
                                                </label>
                                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                                  {" "}
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge2R1(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge2R1",
                                                        childrenAge2R1
                                                      );
                                                    }}
                                                    value={childrenAge2R1}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge2R2(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge2R2",
                                                        childrenAge2R2
                                                      );
                                                    }}
                                                    value={childrenAge2R2}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            ) : childrenHotels2R >= 1 ? (
                                              <>
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    {t("Ages")}
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge2R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge2R1",
                                                          childrenAge2R1
                                                        );
                                                      }}
                                                      value={childrenAge2R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <hr />
                                          <div className="room d-flex align-items-center flex-wrap">
                                            <div
                                              className="rooms-container"
                                              style={{
                                                visibility: "hidden",
                                              }}
                                            >
                                              <label className="form-label">
                                                {t("Rooms")}
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setRoomsHotels(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "roomsHotels",
                                                    roomsHotels
                                                  );
                                                }}
                                                value={roomsHotels}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                              </select>
                                            </div>
                                            <div className="adults-container">
                                              <label className="form-label">
                                                {t("Adults")} (12+)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setAdultsHotels3R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "adultsHotels3R",
                                                    adultsHotels3R
                                                  );
                                                }}
                                                value={adultsHotels3R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="1">1</option>
                                                <option defaultValue="2">
                                                  2
                                                </option>
                                                <option value="3">3</option>
                                              </select>
                                            </div>
                                            <div className="children-container">
                                              <label className="form-label">
                                                {t("Children")} (2-11.99)
                                              </label>
                                              <select
                                                onChange={(e) => {
                                                  setChildrenHotels3R(
                                                    e.target.value
                                                  );
                                                  localStorage.setItem(
                                                    "childrenHotels3R",
                                                    childrenHotels3R
                                                  );
                                                }}
                                                value={childrenHotels3R}
                                                className="form-control mx-1 border-1"
                                              >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                              </select>
                                            </div>
                                            {childrenHotels3R > 1 ? (
                                              <div className="ages children-option-group">
                                                <label className="form-label">
                                                  {t("Ages")}
                                                </label>
                                                <div className="d-flex w-100 justify-content-between align-items-center ">
                                                  {" "}
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) => {
                                                      setChildrenAge3R1(
                                                        e.target.value
                                                      );
                                                      localStorage.setItem(
                                                        "childrenAge3R1",
                                                        childrenAge3R1
                                                      );
                                                    }}
                                                    value={childrenAge3R1}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                  <select
                                                    className="form-control mx-1 border-1"
                                                    onChange={(e) =>
                                                      setChildrenAge3R2(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={childrenAge3R2}
                                                  >
                                                    <option defaultValue="2">
                                                      2
                                                    </option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">
                                                      10
                                                    </option>
                                                    <option value="11">
                                                      11
                                                    </option>
                                                    <option value="12">
                                                      12
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            ) : childrenHotels3R >= 1 ? (
                                              <>
                                                <div className="ages children-option-group">
                                                  <label className="form-label">
                                                    {t("Ages")}
                                                  </label>
                                                  <div className="d-flex w-100 justify-content-between align-items-center ">
                                                    {" "}
                                                    <select
                                                      className="form-control mx-1 border-1"
                                                      onChange={(e) => {
                                                        setChildrenAge3R1(
                                                          e.target.value
                                                        );
                                                        localStorage.setItem(
                                                          "childrenAge3R1",
                                                          childrenAge3R1
                                                        );
                                                      }}
                                                      value={childrenAge3R1}
                                                    >
                                                      <option defaultValue="2">
                                                        2
                                                      </option>
                                                      <option value="3">
                                                        3
                                                      </option>
                                                      <option value="4">
                                                        4
                                                      </option>
                                                      <option value="5">
                                                        5
                                                      </option>
                                                      <option value="6">
                                                        6
                                                      </option>
                                                      <option value="7">
                                                        7
                                                      </option>
                                                      <option value="8">
                                                        8
                                                      </option>
                                                      <option value="9">
                                                        9
                                                      </option>
                                                      <option value="10">
                                                        10
                                                      </option>
                                                      <option value="11">
                                                        11
                                                      </option>
                                                      <option value="12">
                                                        12
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <hr />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-xs-12 col-sm-2 col-md-12 col-xl-2 mt-3 mt-xl-0"
                              style={{ marginTop: "-5px" }}
                            >
                              <span
                                className="d-block text-gray-1 text-left font-weight-normal  "
                                style={{
                                  paddingBottom: "5px",
                                  marginTop: "-5px",
                                }}
                              >
                                {t("Nationality")}
                              </span>
                              {/* Add your nationality select component here */}
                              <select
                                required
                                id="hotel-searcher-nationality"
                                style={{ width: "100% !important" }}
                                className="form-control js-auto-form-param js-autotemplate js-enhanced-select"
                                name="nationality"
                                value={nationality}
                                onChange={(e) => setNationality(e.target.value)}
                              >
                                <option value="" hidden>
                                  {t("Select")}{" "}
                                </option>
                                <option value="AF">{t("Afghanistan")}</option>
                                <option value="AL">{t("Albania")}</option>
                                <option value="DZ">{t("Algeria")}</option>
                                <option value="AD">{t("Andorra")}</option>
                                <option value="AO">{t("Angola")}</option>
                                <option value="AG">
                                  {t("Antigua and Barbuda")}
                                </option>
                                <option value="AR">{t("Argentina")}</option>
                                <option value="AM">{t("Armenia")}</option>
                                <option value="AU">{t("Australia")}</option>
                                <option value="AT">{t("Austria")}</option>
                                <option value="AZ">{t("Azerbaijan")}</option>
                                <option value="BS">{t("Bahamas")}</option>
                                <option value="BH">{t("Bahrain")}</option>
                                <option value="BD">{t("Bangladesh")}</option>
                                <option value="BB">{t("Barbados")}</option>
                                <option value="BY">{t("Belarus")}</option>
                                <option value="BE">{t("Belgium")}</option>
                                <option value="BZ">{t("Belize")}</option>
                                <option value="BJ">{t("Benin")}</option>
                                <option value="BT">{t("Bhutan")}</option>
                                <option value="BO">{t("Bolivia")}</option>
                                <option value="BA">
                                  {t("Bosnia and Herzegovina")}
                                </option>
                                <option value="BW">{t("Botswana")}</option>
                                <option value="BR">{t("Brazil")}</option>
                                <option value="BN">{t("Brunei")}</option>
                                <option value="BG">{t("Bulgaria")}</option>
                                <option value="BF">{t("Burkina Faso")}</option>
                                <option value="BI">{t("Burundi")}</option>
                                <option value="CI">{t("Côte d'Ivoire")}</option>
                                <option value="CV">{t("Cabo Verde")}</option>
                                <option value="KH">{t("Cambodia")}</option>
                                <option value="CM">{t("Cameroon")}</option>
                                <option value="CA">{t("Canada")}</option>
                                <option value="CF">
                                  {t("Central African Republic")}
                                </option>
                                <option value="TD">{t("Chad")}</option>
                                <option value="CL">{t("Chile")}</option>
                                <option value="CN">{t("China")}</option>
                                <option value="CO">{t("Colombia")}</option>
                                <option value="KM">{t("Comoros")}</option>
                                <option value="CG">
                                  {t("Congo (Congo-Brazzaville)")}
                                </option>
                                <option value="CR">{t("Costa Rica")}</option>
                                <option value="HR">{t("Croatia")}</option>
                                <option value="CU">{t("Cuba")}</option>
                                <option value="CY">{t("Cyprus")}</option>
                                <option value="CZ">
                                  {t("Czechia (Czech Republic)")}
                                </option>
                                <option value="CD">
                                  {t("Democratic Republic of the Congo")}
                                </option>
                                <option value="DK">{t("Denmark")}</option>
                                <option value="DJ">{t("Djibouti")}</option>
                                <option value="DM">{t("Dominica")}</option>
                                <option value="DO">
                                  {t("Dominican Republic")}
                                </option>
                                <option value="EC">{t("Ecuador")}</option>
                                <option value="EG">{t("Egypt")}</option>
                                <option value="SV">{t("El Salvador")}</option>
                                <option value="GQ">
                                  {t("Equatorial Guinea")}
                                </option>
                                <option value="ER">{t("Eritrea")}</option>
                                <option value="EE">{t("Estonia")}</option>
                                <option value="SZ">
                                  {t(`Eswatini (fmr. "Swaziland")`)}
                                </option>
                                <option value="ET">{t("Ethiopia")}</option>
                                <option value="FJ">{t("Fiji")}</option>
                                <option value="FI">{t("Finland")}</option>
                                <option value="FR">{t("France")}</option>
                                <option value="GA">{t("Gabon")}</option>
                                <option value="GM">{t("Gambia")}</option>
                                <option value="GE">{t("Georgia")}</option>
                                <option value="DE">{t("Germany")}</option>
                                <option value="GH">{t("Ghana")}</option>
                                <option value="GR">{t("Greece")}</option>
                                <option value="GD">{t("Grenada")}</option>
                                <option value="GT">{t("Guatemala")}</option>
                                <option value="GN">{t("Guinea")}</option>
                                <option value="GW">{t("Guinea-Bissau")}</option>
                                <option value="GY">{t("Guyana")}</option>
                                <option value="HT">{t("Haiti")}</option>
                                <option value="VA">{t("Holy See")}</option>
                                <option value="HN">{t("Honduras")}</option>
                                <option value="HU">{t("Hungary")}</option>
                                <option value="IS">{t("Iceland")}</option>
                                <option value="IN">{t("India")}</option>
                                <option value="ID">{t("Indonesia")}</option>
                                <option value="IR">{t("Iran")}</option>
                                <option value="IQ">{t("Iraq")}</option>
                                <option value="IE">{t("Ireland")}</option>
                                <option value="IL">{t("Israel")}</option>
                                <option value="IT">{t("Italy")}</option>
                                <option value="JM">{t("Jamaica")}</option>
                                <option value="JP">{t("Japan")}</option>
                                <option value="JO">{t("Jordan")}</option>
                                <option value="KZ">{t("Kazakhstan")}</option>
                                <option value="KE">{t("Kenya")}</option>
                                <option value="KI">{t("Kiribati")}</option>
                                <option value="KW">{t("Kuwait")}</option>
                                <option value="KG">{t("Kyrgyzstan")}</option>
                                <option value="LA">{t("Laos")}</option>
                                <option value="LV">{t("Latvia")}</option>
                                <option value="LB">{t("Lebanon")}</option>
                                <option value="LS">{t("Lesotho")}</option>
                                <option value="LR">{t("Liberia")}</option>
                                <option value="LY">{t("Libya")}</option>
                                <option value="LI">{t("Liechtenstein")}</option>
                                <option value="LT">{t("Lithuania")}</option>
                                <option value="LU">{t("Luxembourg")}</option>
                                <option value="MG">{t("Madagascar")}</option>
                                <option value="MW">{t("Malawi")}</option>
                                <option value="MY">{t("Malaysia")}</option>
                                <option value="MV">{t("Maldives")}</option>
                                <option value="ML">{t("Mali")}</option>
                                <option value="MT">{t("Malta")}</option>
                                <option value="MH">
                                  {t("Marshall Islands")}
                                </option>
                                <option value="MR">{t("Mauritania")}</option>
                                <option value="MU">{t("Mauritius")}</option>
                                <option value="MX">{t("Mexico")}</option>
                                <option value="FM">{t("Micronesia")}</option>
                                <option value="MD">{t("Moldova")}</option>
                                <option value="MC">{t("Monaco")}</option>
                                <option value="MN">{t("Mongolia")}</option>
                                <option value="ME">{t("Montenegro")}</option>
                                <option value="MA">{t("Morocco")}</option>
                                <option value="MZ">{t("Mozambique")}</option>
                                <option value="MM">
                                  {t("Myanmar (formerly Burma)")}
                                </option>
                                <option value="NA">{t("Namibia")}</option>
                                <option value="NR">{t("Nauru")}</option>
                                <option value="NP">{t("Nepal")}</option>
                                <option value="NL">{t("Netherlands")}</option>
                                <option value="NZ">{t("New Zealand")}</option>
                                <option value="NI">{t("Nicaragua")}</option>
                                <option value="NE">{t("Niger")}</option>
                                <option value="NG">{t("Nigeria")}</option>
                                <option value="KP">{t("North Korea")}</option>
                                <option value="MK">
                                  {t("North Macedonia")}
                                </option>
                                <option value="NO">{t("Norway")}</option>
                                <option value="OM">{t("Oman")}</option>
                                <option value="PK">{t("Pakistan")}</option>
                                <option value="PW">{t("Palau")}</option>
                                <option value="PS">
                                  {t("Palestine State")}
                                </option>
                                <option value="PA">{t("Panama")}</option>
                                <option value="PG">
                                  {t("Papua New Guinea")}
                                </option>
                                <option value="PY">{t("Paraguay")}</option>
                                <option value="PE">{t("Peru")}</option>
                                <option value="PH">{t("Philippines")}</option>
                                <option value="PL">{t("Poland")}</option>
                                <option value="PT">{t("Portugal")}</option>
                                <option value="QA">{t("Qatar")}</option>
                                <option value="RO">{t("Romania")}</option>
                                <option value="RU">{t("Russia")}</option>
                                <option value="RW">{t("Rwanda")}</option>
                                <option value="KN">
                                  {t("Saint Kitts and Nevis")}
                                </option>
                                <option value="LC">{t("Saint Lucia")}</option>
                                <option value="VC">
                                  {t("Saint Vincent and the Grenadines")}
                                </option>
                                <option value="WS">{t("Samoa")}</option>
                                <option value="SM">{t("San Marino")}</option>
                                <option value="ST">
                                  {t("Sao Tome and Principe")}
                                </option>
                                <option value="SA">{t("Saudi Arabia")}</option>
                                <option value="SN">{t("Senegal")}</option>
                                <option value="RS">{t("Serbia")}</option>
                                <option value="SC">{t("Seychelles")}</option>
                                <option value="SL">{t("Sierra Leone")}</option>
                                <option value="SG">{t("Singapore")}</option>
                                <option value="SK">{t("Slovakia")}</option>
                                <option value="SI">{t("Slovenia")}</option>
                                <option value="SB">
                                  {t("Solomon Islands")}
                                </option>
                                <option value="SO">{t("Somalia")}</option>
                                <option value="ZA">{t("South Africa")}</option>
                                <option value="KR">{t("South Korea")}</option>
                                <option value="SS">{t("South Sudan")}</option>
                                <option value="ES">{t("Spain")}</option>
                                <option value="LK">{t("Sri Lanka")}</option>
                                <option value="SD">{t("Sudan")}</option>
                                <option value="SR">{t("Suriname")}</option>
                                <option value="SE">{t("Sweden")}</option>
                                <option value="CH">{t("Switzerland")}</option>
                                <option value="SY">{t("Syria")}</option>
                                <option value="TJ">{t("Tajikistan")}</option>
                                <option value="TZ">{t("Tanzania")}</option>
                                <option value="TH">{t("Thailand")}</option>
                                <option value="TL">{t("Timor-Leste")}</option>
                                <option value="TG">{t("Togo")}</option>
                                <option value="TO">{t("Tonga")}</option>
                                <option value="TT">
                                  {t("Trinidad and Tobago")}
                                </option>
                                <option value="TN">{t("Tunisia")}</option>
                                <option value="TR">{t("Turkey")}</option>
                                <option value="TM">{t("Turkmenistan")}</option>
                                <option value="TV">{t("Tuvalu")}</option>
                                <option value="UG">{t("Uganda")}</option>
                                <option value="UA">{t("Ukraine")}</option>
                                <option value="AE">
                                  {t("United Arab Emirates")}
                                </option>
                                <option value="GB">
                                  {t("United Kingdom")}
                                </option>
                                <option value="US">
                                  {t("United States of America")}
                                </option>
                                <option value="UY">{t("Uruguay")}</option>
                                <option value="UZ">{t("Uzbekistan")}</option>
                                <option value="UV">{t("Vanuatu")}</option>
                                <option value="VE">{t("Venezuela")}</option>
                                <option value="VN">{t("Vietnam")}</option>
                                <option value="YE">{t("Yemen")}</option>
                                <option value="ZM">{t("Zambia")}</option>
                                <option value="ZW">{t("Zimbabwe")}</option>
                                <option value="UN">{t("Unkown")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-sm-12 col-xl-2 align-self-lg-end text-md-right d-inline-block"
                          style={{ marginTop: "10px" }}
                        >
                          <button
                            type="submit"
                            className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className="mr-2"
                            />
                            {t("Search")}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              {/* Cruises  */}
              <div
                className="tab-pane fade px-lg-5 mx-lg-5"
                id="pills-five-example5"
                role="tabpanel"
                aria-labelledby="pills-five-example5-tab"
              >
                {/* Search Jobs Form */}
                <div className="card border-0 tab-shadow">
                  <div className="card-body">
                    <form onSubmit={handleSubmitCruises}>
                      <div className="row d-block nav-select d-lg-flex mb-lg-3 px-2 px-lg-3 align-items-end">
                        <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 durations">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Itinerary")}
                          </span>
                          <div className="d-flex align-items-center">
                            <i
                              className="icon flaticon-map-marker"
                              style={{ color: "#e60000" }}
                            >
                              {" "}
                            </i>
                            <select
                              required
                              id="TripTypeID"
                              name="TripTypeID"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              title="Itinerary"
                              onChange={handleItineraryChange}
                            >
                              <option hidden value="">
                                {t("Select Itinerary")}
                              </option>
                              <optgroup label="Nile Cruises">
                                <option value="5">
                                  7 {t("Nights Luxor-Aswan-Luxor")}
                                </option>
                                <option value="7">
                                  4 {t("Nights Luxor-Aswan")}
                                </option>
                                <option value="8">
                                  3 {t("Nights Aswan-Luxor")}
                                </option>
                              </optgroup>
                            </select>
                          </div>
                        </div>

                        {/* Date Picker */}
                        <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 durations">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Date")}
                          </span>
                          <div className="border-bottom border-width-2 border-color-1">
                            <div
                              id="datepickerWrapperFromThree"
                              className="u-datepicker input-group d-flex align-center"
                            >
                              <div className="input-group-prepend">
                                <span className="d-flex align-items-center mr-2">
                                  <i className="flaticon-calendar text-primary font-weight-semi-bold"></i>
                                </span>
                              </div>
                              <DatePicker
                                selected={startDateCruises}
                                onChange={(date) => setStartDateCruises(date)}
                                className=" border-0 form_control   "
                                dateFormat="dd/MM/yyyy"
                                minDate={startDateCruisesMenu}
                                maxDate={endDateCruisesMenu}
                                filterDate={isDateAvailable}
                                title={
                                  destinationCruises
                                    ? ""
                                    : t(
                                        "Select Your Itinerary Type And View Available Dates"
                                      )
                                }
                                required
                                locale={getLocale()} // This will set the locale based on the selected language
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-3 dot6 col-xl-3 gdot5 mb-4 mb-lg-0 adults">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Adults")}
                          </span>
                          {/* Select */}
                          <div className="d-flex align-items-center">
                            <i className="rlr-icon-font flaticon-add-user text-primary "></i>
                            <select
                              id="AdultNo"
                              name="AdultNo"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              title={t("Adult")}
                              data-style=""
                              data-live-search="true"
                              data-searchbox-classes="input-group-sm"
                              value={adultsCruises}
                              onChange={(e) => setAdultsCruises(e.target.value)}
                              required
                            >
                              <option value="1"> 1 {t("Adult")}</option>
                              <option value="2" selected>
                                {" "}
                                2 {t("Adults")}
                              </option>
                            </select>
                          </div>
                          {/* End Select */}
                        </div>
                        <div className="col-sm-12 col-lg-3dot6 col-xl-3gdot5 mb-4 mb-lg-0 child">
                          <span className="d-block text-gray-1 font-weight-normal text-left mb-0">
                            {t("Child")}
                          </span>
                          {/* Select */}
                          <div className="d-flex align-items-center">
                            <i className="rlr-icon-font flaticon-add-user text-primary "></i>
                            <select
                              id="ChildNo"
                              name="ChildNo"
                              className="form_control"
                              style={{
                                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                                borderTop: "0px",
                                borderRight: "0px",
                                borderLeft: "0px",
                              }}
                              title={t("Child")}
                              data-style=""
                              data-live-search="true"
                              data-searchbox-classes="input-group-sm"
                              value={childrenCruises}
                              onChange={(e) =>
                                setChildrenCruises(e.target.value)
                              }
                              required
                            >
                              <option value="0" selected>
                                {" "}
                                0
                              </option>
                              <option value="1"> 1 {t("Child")}</option>
                              <option value="2"> 2 {t("Child")}</option>
                            </select>
                          </div>
                          {/* End Select */}
                        </div>
                        <div className="col-sm-12 col-xl-2 align-self-lg-end text-md-right">
                          <button
                            type="submit"
                            className="btn btn-primary w-100 border-radius-3 mb-xl-0 mb-lg-1 transition-3d-hover"
                          >
                            <FontAwesomeIcon
                              icon={faMagnifyingGlass}
                              className="mr-2"
                            />
                            {t("Search")}
                          </button>
                        </div>
                      </div>
                      {/* End Checkbox */}
                    </form>
                  </div>
                </div>
                {/* End Search Jobs Form */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingWidget;
