import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { fetchDestinations } from "../../data/destinations/destinationsSlice";
import { fetchTravelIdea } from "../../data/travel-idea/travelIdeaSlice";
import { useTranslation } from "react-i18next";

const TravelIdea = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);

  const { travelIdea, loading, error } = useSelector(
    (state) => state.travelIdea
  );
  useEffect(() => {
    dispatch(fetchTravelIdea(language));
  }, [dispatch, language]);
  return (
    <>
      <Helmet>
        <title>
          {travelIdea.pageDetails?.pageMetatagTitle || "travel idea"}
        </title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://travco.com/${language}/travel-idea`}
        />
        <meta
          name="description"
          content={travelIdea.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={travelIdea.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={travelIdea.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={travelIdea.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={travelIdea.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>{" "}
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? travelIdea.pageDetails?.pageBannerMobile
                          : isTablet
                          ? travelIdea.pageDetails?.pageBannerTablet
                          : travelIdea.pageDetails?.pageBannerPc
                      }
                      alt={travelIdea.pageDetails?.pageTitle}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {travelIdea.pageDetails?.pageTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")}</Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {travelIdea.pageDetails?.pageTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              to="tel:(+202) 3854 1010"
              className="rlr-search-results-page__phone"
            >
              {t("Questions")}{" "}
              {language == "ar" ? (
                <>
                  ؟ <span style={{ direction: "ltr" }}> (+202) 3854 1010</span>
                </>
              ) : (
                "? (+202) 3854 1010"
              )}{" "}
            </Link>
          </div>
        </div>
      </div>
      <section
        className="page stories-list-page full-width pt-100"
        id="stories-full-width"
      >
        <div className="container">
          <div className="row">
            <div className="page_body  ">
              <div className="stories_list col-12 d-flex flex-wrap justify-content-center">
                {travelIdea.travelIdeaLists?.map((product) => (
                  <Link
                    key={product.productStyleUrl}
                    to={`/travel-idea/${product.productStyleUrl}`}
                    className="story_item"
                    style={{
                      backgroundImage: `url(${product.productStylePhoto})`,
                    }}
                  >
                    <div className="item_wrap">
                      <div className="_content">
                        <h3 className="country">
                          {product.productStyleNameSys}{" "}
                        </h3>
                        <p className="text">
                          {product.productCount}{" "}
                          {product.productCount > 1 ? t("Tours") : t("Tour")}{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="shadow js-shadow"
                      style={{
                        backgroundImage: `url(${product.productStylePhoto})`,
                      }}
                    ></div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TravelIdea;
