import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import { fetchAbout } from "./../../data/about-us/aboutSlice";
import { useDispatch, useSelector } from "react-redux";
import Counter from "../../components/Counter";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);
const AboutUs = () => {
  const { t } = useTranslation();
  const [viewMore, setViewMore] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const { about, loading, error } = useSelector((state) => state.about);
  const language = useSelector((state) => state.language.language);
  useEffect(() => {
    dispatch(fetchAbout(language));
  }, [dispatch, language]);
  const serviceSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    // <>
    //    {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <section
    //       style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
    //     >
    //       <h1 className="text-center text-light">{error}</h1>
    //     </section>
    //   ) : (
    <>
      <Helmet>
        <title>
          {about.pageDetails?.pageMetatagTitle ?? "About Tavco Travel"}
        </title>

        <link
          rel="canonical"
          href={`https://travco.com/${language}/about-travco-travel`}
          data-react-helmet="true"
        />
        <meta
          name="description"
          content={about.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={about.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={about.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={about.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={about.pageDetails?.metatagDescriptionTwitter}
        />
      </Helmet>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner"></div>
                  <Suspense fallback={<div>{t("Loading")}...</div>}>
                    <img
                      src={
                        isMobile
                          ? about.pageDetails?.pageBannerMobile
                          : isTablet
                          ? about.pageDetails?.pageBannerTablet
                          : about.pageDetails?.pageBannerPc
                      }
                      alt={about.pageDetails?.altBanner}
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className="rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          {about.aboutTravcoPageDetails?.aboutBannerTitle}
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="rlr-search-results-page__breadcrumb-section">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb rlr-breadcrumb__items">
              <li className="breadcrumb-item rlr-breadcrumb__item">
                <Link to="/">{t("Home")} </Link>
              </li>
              <li
                className="breadcrumb-item rlr-breadcrumb__item active"
                aria-current="page"
              >
                {about.pageDetails?.pageTitle
                  ? about.pageDetails?.pageTitle
                  : about.aboutTravcoPageDetails?.aboutBannerTitle}
              </li>
            </ol>
          </nav>
          <div className="rlr-icon-text">
            <i className="rlr-icon-font flaticon-phone-receiver-silhouette">
              {" "}
            </i>{" "}
            <Link
              className="rlr-search-results-page__phone"
              to={`tel:${about.aboutTravcoPageDetails?.aboutQuestionTitle}`}
            >
              {about.aboutTravcoPageDetails?.aboutQuestionTitle}
            </Link>
          </div>
        </div>
      </div>
      <section>
        <div className="container-xxl pb-110">
          <section className="rlr-section rlr-section__mt row align-items-center">
            <div className="col-lg-6">
              <div className="rlr-gallery--newsletter">
                <div className="rlr-gallery--newsletter__thumbs">
                  <div className="rlr-gallery--newsletter__thumbs__col-1">
                    <img
                      src={about.aboutTravcoPageDetails?.photoSectionPhoto1}
                      alt={about.aboutTravcoPageDetails?.aboutBannerTitle}
                    />
                  </div>
                  <div className="rlr-gallery--newsletter__thumbs__col-2">
                    <img
                      src={about.aboutTravcoPageDetails?.photSectionPhoto2}
                      alt={about.aboutTravcoPageDetails?.aboutBannerTitle}
                    />
                    <img
                      src={about.aboutTravcoPageDetails?.photoSectionPhoto3}
                      alt={about.aboutTravcoPageDetails?.photoSectionPhoto1}
                    />
                  </div>
                </div>
              </div>

              <div className="rlr-gallery--newsletter">
                <div className="rlr-gallery--newsletter__thumbs">
                  <div className="rlr-gallery--newsletter__thumbs__col-1">
                    <img
                      src={about.aboutTravcoPageDetails?.photoSectionPhoto4}
                      alt={about.aboutTravcoPageDetails?.aboutBannerTitle}
                    />
                  </div>
                  <div className="rlr-gallery--newsletter__thumbs__col-2">
                    <img
                      src={about.aboutTravcoPageDetails?.photoSectionPhoto5}
                      alt={about.aboutTravcoPageDetails?.aboutBannerTitle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rlr-newsletter">
                <div
                  id="textContainer"
                  className="rlr-newsletter__content content"
                  data-aos="fade-down"
                  data-aos-offset="250"
                  data-aos-duration="700"
                >
                  <div className="rlr-newsletter__subtitle">
                    {about.aboutTravcoPageDetails?.aboutSubTitle}
                  </div>
                  <h2 className="rlr-newsletter__title rlr-section__title--main">
                    {about.aboutTravcoPageDetails?.aboutBigTitle}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: about.aboutTravcoPageDetails?.aboutTravcoFullText,
                    }}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: !viewMore && "13",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  />
                </div>
                <button
                  className="btn btn-primary mt-2 d-block mx-auto"
                  style={{ cursor: "pointer" }}
                  onClick={() => setViewMore(!viewMore)}
                >
                  {viewMore ? t("View Less") : t("View More")}{" "}
                  <i
                    className="rlr-icon-font flaticon-up-arrow"
                    style={{
                      transform: viewMore
                        ? "rotateX(0deg)"
                        : "rotateX(180deg) ",
                    }}
                  ></i>
                </button>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section
        className="video-bg-section pt-140 pb-110 p-r z-1 bg_cover "
        style={{
          backgroundImage: `url(${about.aboutTravcoPageDetails?.aboutVideoBanner})`,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="video-content-box text-white  mb-40 wow fadeInLeft">
                <h2
                  className="mb-30 h1 rlr-section__title--main"
                  style={{ fontWeight: "900", fontSize: "60px" }}
                >
                  {about.aboutTravcoPageDetails?.aboutJourneyTitle}
                </h2>
                <Link
                  to={about.aboutTravcoPageDetails?.aboutVideoUrl}
                  data-fancybox="myVideo1"
                  className="main-btn "
                  style={{ backgrounColor: "#e60000" }}
                >
                  {t("Watch video")}
                </Link>
              </div>
            </div>

            <div className="col-lg-4">
              <div className=" text-lg-end text-md-center mb-40 wow fadeInRight">
                <Link
                  data-fancybox="myVideo2"
                  to={about.aboutTravcoPageDetails?.aboutVideoUrl}
                  className="video-icon"
                >
                  <i className="rlr-icon-font flaticon-map-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="fun-fact mt-minus-100 plr-5p p-r z-2 container">
        <div className="container-fluid">
          <div
            className="fun-wrapper mt-minus-100 p-r z-2 bg_cover pt-60 pb-5 light-gray-bg"
            style={{ backgroundImage: `url(assets/images/about/fun-bg-1.png)` }}
          >
            <div className="container">
              <div className="row">
                {about.travcoFacts?.map((fact, index) => (
                  <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                    <div className="counter-item mb-30 d-flex justify-content-center wow fadeInUp">
                      <div
                        className="counter-inner-item"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div className="icon">
                          <img
                            src={fact.factIcon}
                            width="80"
                            className="img-fluid"
                            alt={fact.fact}
                          />
                        </div>
                        <div
                          className="text"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h2>
                            <Counter
                              className="count"
                              start={0}
                              end={fact.factNumber}
                              duration={0.2}
                            />
                            {fact.factNumberType}
                          </h2>
                          <p>{fact.fact}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Services  */}
      <section className="gallery-section pt-90">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-10">
              <div className="section-title text-center mb-50 wow fadeInDown">
                <h2 className="rlr-section__title--main">
                  {about.aboutTravcoPageDetails?.sectionServicesTitle}
                </h2>
              </div>
            </div>
          </div>
          <Slider
            {...serviceSettings}
            className="gallery-slider-one wow fadeInUp mx-0 px-0"
          >
            {about.travcoServices?.map((service) => (
              <div
                className="gallery-item px-3 mx-auto"
                key={service.sevicesUrl}
              >
                <div className="gallery-img">
                  <img src={service.sevicesPhoto} alt={service.servicesName} />
                  <div className="hover-overlay">
                    <div className="hover-content text-center text-white">
                      <Link
                        to={`/services/${service.sevicesUrl}`}
                        className="icon-btn"
                      >
                        <i className="rlr-icon-font flaticon-chevron"></i>
                      </Link>
                      <h4 className="title">
                        <Link to={`/services/${service.sevicesUrl}`}>
                          {service.servicesName}
                        </Link>
                      </h4>
                      <Link
                        to={`/services/${service.sevicesUrl}`}
                        className="cat-link"
                      >
                        {service.servicesNameIntro}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="rlr-section rlr-section__mb rlr-section__mt">
        <div className="container">
          <div className="rlr-section-header">
            <div className="rlr-section__title">
              <h2 className="rlr-section__title--main">
                {about.aboutTravcoPageDetails?.workWithUsFirstTitle}
              </h2>
              <span className="rlr-section__title--sub">
                {about.aboutTravcoPageDetails?.workWithUsSecondTitle}
              </span>
            </div>
            <div className="button-row">
              <button
                type="button"
                className="btn rlr-button button button--previous rlr-button--carousel"
                aria-label="Previous"
              >
                <i className="rlr-icon-font flaticon-left-chevron"></i>
              </button>
              <div className="button-group button-group--cells">
                <button className="button is-selected">1 </button>
                <button className="button">2 </button>
              </div>
              <button
                type="button"
                className="btn rlr-button button button--next rlr-button--carousel"
                aria-label="Next"
              >
                <i className="rlr-icon-font flaticon-chevron"></i>
              </button>
            </div>
          </div>
          <div className="rlr-carousel__items">
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              navigation={{
                nextEl: ".button--next",
                prevEl: ".button--previous",
              }}
              speed={500}
              pagination={false}
              autoplay={false}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
              className="rlr-js-team-card-swiper"
            >
              {about.workingWithUs?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="rlr-team-card"
                    itemScope
                    itemType="https://schema.org/member"
                  >
                    <div
                      className="rlr-team-card__thumb"
                      data-aos="fade-up"
                      data-aos-duration="800"
                      data-aos-once="true"
                    >
                      <img
                        itemProp="image"
                        src={item.titlePhoto}
                        className="img-fluid"
                        alt={item.titleName}
                      />
                      <span className="rlr-slide-count">{index + 1}</span>
                    </div>
                    <div
                      className="rlr-team-card__summary"
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-once="true"
                    >
                      <div className="rlr-team-card__designation"></div>
                      <h3 className="rlr-team-card__name rlr-section__title--main">
                        {item.titleName}
                      </h3>
                      <div className="rlr-team-card__about-row">
                        <div
                          className="rlr-team-card__aboutbox"
                          style={{ width: "100%" }}
                        >
                          <p className="SiteFontP">{item.titleDetails}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
    //   )}
    // </>
  );
};

export default AboutUs;
